import { Dropdown, Select, Space } from "antd";
import { NavLink } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { roles } from "../utils/constants";
import {
  ArrowTriangleDown,
  BinocularsLight,
  CrossRedIcon,
  EditIcon,
  LockIcon,
  MoreIcon,
  PurpleTriangleIcon,
  RecycleBin,
  ShareIcon,
  SharePurpleIcon,
} from "../constants/svg";
import { Assets } from "../constants/images";
import { roleRights } from "../utils/helper";
import { FaStar } from "react-icons/fa";
import AvatarImage from "../components/Common/AvatarImage";

const roleOption = [
  {
    key: uuidv4(),
    value: roles.admin,
    label: (
      <div className="dropdownBox">
        <div className="head">
          <h6>Admin</h6>
          <div className="icon"></div>
        </div>
        <p className="gray">
          Can access the admin panel to manage billing, users and more.
        </p>
      </div>
    ),
  },
  {
    key: uuidv4(),
    value: roles.member,
    label: (
      <div className="dropdownBox">
        <div className="head">
          <h6>Member</h6>
          <div className="icon"></div>
        </div>
        <p className="gray">
          Can interact and engage with content from the mobile app.
        </p>
      </div>
    ),
  },
];

export const getMemeberTableColumns = (
  setShowModal,
  handleEditOpenMemberForm,
  setMemberForDelete,
  handleConfirmMemberUpdateRole,
  owner,
  setMemberForShare,
  resendIvite,
  cancelIvite,
  avatarsPool = [],
) => {
  // const items = [
  //   {
  //     key: "1",
  //     label: (
  //       <>
  //         <div className="adminRoleBox lockBox">
  //           <h6>
  //             <span>
  //               <NewSparkleSmallIcon />
  //             </span>
  //             Owner
  //           </h6>
  //         </div>
  //       </>
  //     ),
  //   },
  // ];

  return [
    {
      title: "Employee",
      dataIndex: "employeeName",
      key: "employeeName",
      width: "360px",
      sorter: (a, b) => a.employeeName.localeCompare(b.employeeName),
      sortDirections: ["descend", "ascend", "descend"],
      defaultSortOrder: "ascend",
      render: (text, record) => (
        <>
          <NavLink
            to={`/team/members/${record.slug ?? record.id}`}
            onClick={(e) => {
              if (record?.isInvited) {
                e.preventDefault();
              }
            }}
            className={`black ${record?.isInvited && "disabled-true"}`}
          >
            <Space>
              <AvatarImage
                name={record.employeeName}
                src={avatarsPool[record.avatar]}
                placeholder={Assets.ProfilePlaceholder}
                size={42}
              />
              <span style={{ marginLeft: 8 }}>{record.employeeName}</span>
            </Space>
          </NavLink>
        </>
      ),
    },
    {
      title: "Position",
      dataIndex: "position",
      key: "position",
      width: "150px",
      sorter: (a, b) => a.position.localeCompare(b.position),
      sortDirections: ["descend", "ascend", "descend"],
      render: (text, record) => <p>{record.position}</p>,
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
      width: "150px",
      sorter: (a, b) => a.location.localeCompare(b.location),
      sortDirections: ["descend", "ascend", "descend"],
      render: (text, record) => <p>{record.location}</p>,
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      width: "100px",
      sorter: false,
      render: function (text, record) {
        if (record?.isInvited) {
          const items = [
            {
              key: "1",
              label: (
                <div
                  className="inviteItem"
                  onClick={() => resendIvite(record.id)}
                >
                  <SharePurpleIcon /> Resend Invitation
                </div>
              ),
            },
            {
              key: "2",
              label: (
                <div
                  className="inviteItem cancelInvite grey-color"
                  onClick={() => cancelIvite(record.id)}
                >
                  <CrossRedIcon /> Cancel Invite
                </div>
              ),
            },
          ];

          return (
            <>
              <Dropdown
                overlayClassName="invitedDropdown"
                className="singLock"
                placement="bottomLeft"
                menu={{ items }}
                trigger={["click"]}
              >
                <div className="purple-color fw-medium cursor-pointer inviteTitle">
                  Invited <PurpleTriangleIcon />{" "}
                </div>
              </Dropdown>
            </>
          );
        }

        return !record.isOwner ? (
          <>
            <Select
              value={record.role}
              onChange={(e) => {
                handleConfirmMemberUpdateRole(record.id, e);
              }}
              className="withoutDropdown"
              suffixIcon={<ArrowTriangleDown />}
              popupClassName={"customDropdownMenu admindropdown"}
              options={roleOption}
            />
          </>
        ) : (
          <>
            <div className="owenerLabel">
              <h6>Owner</h6>
              <LockIcon />
            </div>
          </>
        );
      },
    },

    {
      title: "More",
      key: "action",
      align: "center",
      width: "60px",
      render: (text, record) => (
        <>
          {record?.isInvited ? (
            <Space size="middle">
              <Dropdown
                className={record?.isInvited ? "disabled-true" : ""}
                disabled={
                  !!(
                    record?.isInvited ||
                    (record.isOwner && !record.isUserOwner)
                  )
                }
                menu={{
                  items: [
                    ...(roleRights(
                      record.isOwner ? roles.owner : record.role,
                      owner,
                    )
                      ? [
                          {
                            key: uuidv4(),
                            label: (
                              <>
                                {/* API when the user is invited */}
                                <div
                                  className={"deleteLabel disabled"}
                                  onClick={() => {
                                    if (!record?.isInvited) {
                                      setMemberForDelete(record);
                                      setShowModal((prevState) => ({
                                        ...prevState,
                                        deleteModal: true,
                                      }));
                                    }
                                  }}
                                >
                                  <RecycleBin />
                                  Remove
                                </div>
                              </>
                            ),
                          },
                        ]
                      : []),
                  ],
                }}
                placement="bottomRight"
                trigger={["click"]}
                overlayClassName={"defaultDropdown"}
              >
                <div className="icon cursor-pointer">
                  <MoreIcon />
                </div>
              </Dropdown>
            </Space>
          ) : (
            <Space size="middle">
              <Dropdown
                disabled={!!(record.isOwner && !record.isUserOwner)}
                menu={{
                  items: [
                    ...(roleRights(
                      record.isOwner ? roles.owner : record.role,
                      owner,
                    )
                      ? [
                          {
                            key: uuidv4(),
                            label: (
                              <>
                                <NavLink
                                  to={`/team/members/${record.slug ?? record.id}`}
                                  className={"black"}
                                >
                                  <BinocularsLight />
                                  Manage
                                </NavLink>
                              </>
                            ),
                          },
                        ]
                      : []),
                    ...((
                      record.isOwner
                        ? record.isOwner
                        : roleRights(
                            record.isOwner ? roles.owner : record.role,
                            owner,
                          )
                    )
                      ? [
                          {
                            key: uuidv4(),
                            label: (
                              <>
                                <div
                                  onClick={() =>
                                    handleEditOpenMemberForm(record)
                                  }
                                >
                                  <EditIcon />
                                  Edit
                                </div>
                              </>
                            ),
                          },
                        ]
                      : []),
                    {
                      key: uuidv4(),
                      label: (
                        <>
                          <div
                            onClick={() => {
                              setMemberForShare(record);
                              setShowModal((prevState) => ({
                                ...prevState,
                                shareModal: true,
                              }));
                            }}
                          >
                            <ShareIcon />
                            Share
                          </div>
                        </>
                      ),
                    },
                    ...(roleRights(
                      record.isOwner ? roles.owner : record.role,
                      owner,
                    )
                      ? [
                          {
                            key: uuidv4(),
                            label: (
                              <>
                                <div
                                  className={"deleteLabel"}
                                  onClick={() => {
                                    setMemberForDelete(record);
                                    setShowModal((prevState) => ({
                                      ...prevState,
                                      deleteModal: true,
                                    }));
                                  }}
                                >
                                  <RecycleBin />
                                  Remove
                                </div>
                              </>
                            ),
                          },
                        ]
                      : []),
                  ],
                }}
                placement="bottomRight"
                trigger={["click"]}
                overlayClassName={"defaultDropdown"}
              >
                <div className="icon cursor-pointer">
                  <MoreIcon />
                </div>
              </Dropdown>
            </Space>
          )}
        </>
      ),
    },
  ];
};
