import { Avatar, notification, Popover, Space } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { salon_resources_type, SHARE_URL } from "../../utils/constants";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import DeleteModal from "../../components/Modals/DeleteModal";
import GenericModal from "../../components/Modals/GenericModal";
import ShareModal from "../../components/Modals/ShareModal";
import ResourcesModalSteps from "../../components/pages/Resource/ResourceModal/ResourcesModalSteps";
import { Assets } from "../../constants/images";
import {
  EditIcon,
  PurpleBackIcon,
  RecycleBin,
  ShareIcon,
  ToastCrossIcon,
} from "../../constants/svg";
import {
  DeleteSalonResource,
  GetSalonResourceById,
} from "../../services/resources/resources";
import {
  setPageLoading,
  setResourceAddEditForm,
  setSelectedData,
} from "../../store/slices/resourcesSlice";
import { setStatUserHover } from "../../store/slices/globalSlice";
import ProfileHoverChat from "../../components/Common/ProfileHoverChat";
import {
  hasAccess,
  renderContentWithLinks,
  toastMessage,
} from "../../utils/helper";
import LoadingScreen from "../../components/LoadingScreen/LoadingScreen";
import ProfileHover from "../../components/Common/ProfileHover";
import AvatarImage from "../../components/Common/AvatarImage";
import { useImageLoader } from "../../utils/imagesLoader";

const ResourceDetailPage = () => {
  const { positions } = useSelector((state) => state.global);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const imageLoader = useImageLoader();

  const { id } = useParams();
  const {
    selectedData,
    resourceAddEditForm,
    pageLoading,
    reRenderResourceDetailPage,
  } = useSelector((state) => state?.resources);
  const [showModal, setShowModal] = useState({
    shareModal: null,
    editModal: null,
    deleteModal: null,
  });
  const { statUserHover } = useSelector((state) => state.global);

  const [disable, setDisable] = useState(false);
  const [hoveredItem, setHoveredItem] = useState(null);
  const [show, setShow] = useState(null);
  const [avatarImages, setAvatarImages] = useState({});

  useEffect(() => {
    const loadImages = async () => {
      for (const item of selectedData?.views || []) {
        if (item.user?.image) {
          const image = await imageLoader(item.user.image, 38);
          setAvatarImages((prev) => ({
            ...prev,
            [item.user.image]: image,
          }));
        }
      }
    };

    loadImages();
  }, [selectedData, imageLoader]);

  const handleMouseEnter = (item) => {
    setHoveredItem(item.user?.id);
  };

  const handleMouseLeave = () => {
    setHoveredItem(null);
    setShow(false);
  };

  useEffect(() => {
    getResourceById();
  }, [reRenderResourceDetailPage]);

  const getResourceById = async () => {
    try {
      dispatch(setPageLoading(true));
      const res = await GetSalonResourceById(id);
      dispatch(setSelectedData(res?.data?.data));
    } catch (e) {
      notification.error({
        description: e.message,
        placement: "bottomRight",
        className: "createdNotification",
        closeIcon: <ToastCrossIcon />,
      });
    } finally {
      dispatch(setPageLoading(false));
    }
  };

  const handleDelete = async () => {
    try {
      setDisable(true);
      let res = await DeleteSalonResource(id);
      toastMessage("delete", res?.data?.message);
      setShowModal(false);
      setDisable(false);
      navigate(`/resources`);
    } catch (e) {
      toastMessage("error", e.message);
      setShowModal(false);
      setDisable(false);
    }
  };

  const getResourceType = (type) => {
    let t = "";
    switch (type) {
      case salon_resources_type.pdf:
        t = "PDF";
        break;

      case salon_resources_type.audio:
        t = "AUDIO";
        break;

      case salon_resources_type.video:
        t = "VIDEO";
        break;

      case salon_resources_type.link:
        t = "LINK";
        break;

      default:
        t = "-";
        break;
    }
    return t;
  };

  const returnPosition = (position_id) => {
    for (let i = 0; i < positions.length; i++) {
      if (positions[i].value == position_id) {
        return positions[i]?.label || "-";
      }
    }
  };

  if (pageLoading) {
    return <LoadingScreen child={true} />;
  }

  return (
    <>
      <Row>
        <Col lg={8} className={"pe-0"}>
          <div className="leftEventDetailWrapper">
            <div className="eventDetailHeader innerContainer d-flex justify-content-between">
              <div className="backToEvent">
                <NavLink to="/resources">
                  <PurpleBackIcon />
                  <span className="uLineHover">Back to Resources</span>
                </NavLink>
              </div>
              <div className="shareButton">
                <Space size={"large"}>
                  <div
                    className="icon-text-wrapper cursor-pointer blackLine"
                    onClick={() =>
                      setShowModal((prevState) => ({
                        ...prevState,
                        shareModal: true,
                      }))
                    }
                  >
                    <ShareIcon />
                    <p className="uLine ">Share</p>
                  </div>
                  {hasAccess(selectedData?.user) && (
                    <div
                      className="icon-text-wrapper cursor-pointer blackLine"
                      onClick={() => {
                        dispatch(
                          setResourceAddEditForm({
                            show: true,
                            formType: "update",
                          }),
                        );
                      }}
                    >
                      <EditIcon />
                      <p className="uLine ">Edit</p>
                    </div>
                  )}
                  {hasAccess(selectedData?.user) && (
                    <div
                      className="icon-text-wrapper deleteLabel cursor-pointer"
                      onClick={() =>
                        setShowModal((prevState) => ({
                          ...prevState,
                          deleteModal: true,
                        }))
                      }
                    >
                      <RecycleBin />
                      <p className="uLine redLine">Delete</p>
                    </div>
                  )}
                </Space>
              </div>
            </div>
            <div className="eventDetailBody">
              <div className="innerContainer">
                <h3 className="sec-heading">{selectedData?.name}</h3>
                <div className="eventDetailInformation">
                  <div className="eventDetailItem">
                    <h5 className="head">Category</h5>
                    <p>{selectedData?.category?.name}</p>
                  </div>
                  <div className="eventDetailItem">
                    <h5 className="head">Tags</h5>
                    <p>
                      <i>
                        {selectedData &&
                          selectedData.tags.map((elem) => elem.name).join(", ")}
                      </i>
                    </p>
                  </div>
                  <div className="eventDetailItem">
                    <h5 className="head">Description</h5>
                    <p>
                      {selectedData?.description
                        ? renderContentWithLinks(selectedData?.description)
                        : "-"}
                    </p>
                  </div>
                  <div className="eventDetailItem">
                    <h5 className="head">Type</h5>
                    <p>{getResourceType(selectedData?.type)}</p>
                  </div>
                  <div className="eventDetailItem">
                    <h5 className="head">File</h5>
                    <a
                      href={selectedData?.link}
                      target="_blank"
                      className={"uLine truncate"}
                      rel="noreferrer"
                    >
                      {selectedData?.link}
                    </a>
                  </div>

                  <div className="eventDetailItem">
                    <h5 className="head">Size</h5>
                    <p>{selectedData?.size ?? "-"}</p>
                  </div>
                  <div className="eventDetailItem">
                    <h5 className="head">Created By</h5>
                    <p>{selectedData?.user?.name || "-"}</p>
                  </div>
                  <div className="eventDetailItem">
                    <h5 className="head">Created On</h5>
                    <p>
                      {moment(selectedData?.created_at).format("MMM DD, YYYY")}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
        <Col lg={4} className={"p-0"} onMouseLeave={handleMouseLeave}>
          <div className="rightEventDetailWrapper">
            <div className="eventDetailHeader d-flex justify-content-between">
              <h4 className="sec-heading m-0 p-0">Resource Stats</h4>
            </div>
            <div className="eventDetailBody">
              <div className="response-card">
                <div className="response-header">
                  <h6>Total Views ({selectedData?.meta?.user_views})</h6>
                </div>
                <div className="response-body">
                  {selectedData && !selectedData?.views.length ? (
                    <span>Check back soon!</span>
                  ) : (
                    selectedData?.views?.map((el, ind) => {
                      if (el?.user?.name) {
                        return (
                          <div
                            style={{ position: "relative" }}
                            className="avatar-item"
                            key={ind + "view"}
                          >
                            <Popover
                              placement="rightTop"
                              content={
                                <ProfileHover
                                  user={{
                                    id: el?.user?.id,
                                    name: el?.user?.name,
                                    image: avatarImages[el?.user?.image],
                                    position: returnPosition(
                                      el?.user?.position_id,
                                    ),
                                  }}
                                />
                              }
                              arrow={true}
                            >
                              <div className="statUserHoverContainer customHover">
                                <AvatarImage
                                  name={el?.user?.name}
                                  src={avatarImages[el?.user?.image]}
                                  placeholder={Assets.ProfilePlaceholder}
                                  size={38}
                                />
                              </div>
                            </Popover>
                            <NavLink
                              to={`/team/members/${el?.slug ?? el?.user_id}`}
                            >
                              <p>{el?.user?.name || "-"}</p>
                            </NavLink>
                          </div>
                        );
                      }
                    })
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* Share Modal */}
          <ShareModal
            shareLink={SHARE_URL.RESOURCES + selectedData?.id}
            data={{
              image: selectedData?.image,
              name: selectedData?.name,
              description: selectedData?.description,
              start_date: selectedData?.created_at,
              start_time: moment(selectedData?.created_at).format("hh:mm a"),
            }}
            show={showModal.shareModal}
            onHide={() =>
              setShowModal((prevState) => ({ ...prevState, shareModal: false }))
            }
            setModalShow={() =>
              setShowModal((prevState) => ({ ...prevState, shareModal: true }))
            }
            modalTitle={"Share Resource"}
            modalSubtitle={
              <>
                Click “Copy Link” below, then{" "}
                <NavLink
                  to="/inbox"
                  className={"uLine blue-accent-color"}
                  target="_blank"
                >
                  {" "}
                  Open Chat{" "}
                </NavLink>{" "}
                to easily share this event with members.
              </>
            }
            size={"lg"}
            bodyComponent={{
              img: Assets.eventShareModal,
              title: "How to Use Big Blowout Product Guide",
              para1:
                "Big Blowout is a unique jelly serum that melts into hair to provide instant volume with a soft, silky finish. This lightweight formula leaves hair naturally bouncy with no crunch while protecting from heat up to 450°F/230°C.",
              para2: "PDF • Mar 03, 2023",
            }}
          />

          {/* Delete Modal */}
          <DeleteModal
            show={showModal.deleteModal}
            onHide={() => setShowModal({ ...showModal, deleteModal: false })}
            heading="Delete Resource?"
            handleDelete={handleDelete}
            disable={disable}
            para={
              <>
                Are you sure you want to delete{" "}
                <b> {selectedData?.name || "this resource"} ?</b>
                <br />
                Important: This resource cannot be recovered.
              </>
            }
            notificationMessage="PDF resource"
          />

          <GenericModal
            show={resourceAddEditForm.show}
            onHide={() => {
              dispatch(
                setResourceAddEditForm({
                  show: false,
                  formType: null,
                }),
              );
            }}
            setModalShow={(e) => {
              dispatch(
                setResourceAddEditForm({
                  show: e,
                  formType: null,
                }),
              );
            }}
            modalTitle={
              resourceAddEditForm.formType == "create"
                ? "Create New Resource"
                : resourceAddEditForm.formType == "update"
                  ? "Edit Resource"
                  : ""
            }
            resource-modal="resource-modal"
            size={"lg"}
            bodyComponent={<ResourcesModalSteps />}
          />
        </Col>
      </Row>
    </>
  );
};

export default ResourceDetailPage;
