import { DatePicker } from "antd";
import React from "react";
import { RoundedCalenderIcon } from "../../constants/svg";

const CustomDatePicker = (props) => {
  return (
    <div
      className={`customLabelGroup datePickerWrapper form-floating hasValue`}
    >
      <div className="form-control">
        <div className={`label ${props?.value ? "d-block" : "d-none"}`}>
          {props?.label}
        </div>
        <DatePicker
          {...props}
          suffixIcon={false}
          closeButton={false}
          className={`customDatePicker ${props?.className}`}
          format="MMM DD, YYYY"
          label={props?.label}
          placeholder={props?.placeholder}
          onChange={props?.onChange}
          value={props?.value}
          disabledDate={props?.disabledDate}
          showNow={false}
        />
      </div>
      <div className="icon">
        <RoundedCalenderIcon />
      </div>
    </div>
  );
};

export default CustomDatePicker;
