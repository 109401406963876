import { Checkbox, Radio } from "antd";
import React, { useState, useRef, useEffect } from "react";
import { Assets } from "../../constants/images";
import { ArrowTriangleDown, RoundedStaffIcon } from "../../constants/svg";
import { recipentsData } from "../../constants/treeSelectData";

const InputField = ({
  image = <RoundedStaffIcon />,
  label = "",
  labelName,
  isOpen,
}) => {
  return (
    <div
      className={`labelHeadingBox d-flex justify-content-space align-items-center ${label ? "added" : ""}`}
    >
      <div className="labelTitle">
        <p className={`title ${isOpen ? "coloured" : ""}`}>{labelName}</p>
        {label && <p className="label">{label}</p>}
      </div>
      <div className="labelImage">
        {/* <img src={image} alt="" /> */}
        {image}
      </div>
    </div>
  );
};

const TreeSelectComponent = ({
  labelName,
  defaultValue = false,
  getTreeSelect = () => {},
}) => {
  const listopenRef = useRef(null);
  const listcloseRef = useRef(null);
  const [activeItem, setActiveItem] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [dropdownData, setDropdownData] = useState(recipentsData);
  const [accordionOpen, setAccordionOpen] = useState({ open: false, id: 0 });

  useEffect(() => {
    if (defaultValue) {
      handleParentClick(dropdownData[0], 0);
    }
  }, []);

  useEffect(() => {
    getTreeSelect(dropdownData);
    let dataSelected = dropdownData.map((el) => {
      let available = el.childern.find((child) => child.isSelected == true);
      return available;
    });
    setSelectedImage(dataSelected.find((el) => typeof el != "undefined"));
  }, [dropdownData]);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        listcloseRef.current &&
        !listcloseRef.current.contains(event.target)
      ) {
        handleCloseMenu();
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const handleParentClick = (el, parentId) => {
    setAccordionOpen({ open: !accordionOpen.open, id: parentId });
    setActiveItem(parentId);
    setDropdownData((current) =>
      current.map((item, id) => {
        if (id == parentId) {
          if (item.childern.length == 0 || item.childern.length == 1) {
            setIsOpen(false);
            handleCloseMenu();
            return {
              ...item,
              isSelected: true,
              childern: [
                {
                  value: item.value,
                  parent: item.value,
                  image: item.image,
                  isSelected: true,
                  visible: false,
                },
              ],
            };
          } else {
            return { ...item, isSelected: false };
          }
        }
        let updatedChildern = item.childern.map((child) => {
          return { ...child, isSelected: false };
        });
        return { ...item, childern: updatedChildern };
      }),
    );
  };

  const handleChecked = (e, parentId, childId) => {
    setDropdownData((current) =>
      current.map((item, id) => {
        if (id == parentId) {
          let { childern } = item;
          let updatedChildern = childern.map((child, childIndex) => {
            if (childIndex == childId) {
              return { ...child, isSelected: !child.isSelected };
            }
            return { ...child };
          });

          return { ...item, isSelected: true, childern: updatedChildern };
        } else {
          let { childern } = item;
          let updatedChildern = childern.map((child) => {
            return { ...child, isSelected: false };
          });
          return { ...item, isSelected: false, childern: updatedChildern };
        }
      }),
    );
  };

  const handleOpenMenu = () => {
    setIsOpen((prev) => !prev);
  };

  const handleCloseMenu = () => {
    setIsOpen(false);
  };
  const [selectedCheckboxCount, setSelectedCheckboxCount] = useState(0);

  useEffect(() => {
    // Calculate the number of selected checkboxes excluding the first value
    const countSelectedCheckboxes = () => {
      let count = 0;
      dropdownData.forEach((item, index) => {
        item.childern.forEach((child) => {
          if (child.isSelected && !(index == 0 && child.value == "Everyone")) {
            count++;
          }
        });
      });

      // Exclude counter if "Everyone" is selected
      if (dropdownData[0].isSelected) {
        count = 0;
      }

      return count;
    };

    // Update the selectedCheckboxCount state
    setSelectedCheckboxCount(countSelectedCheckboxes());
  }, [dropdownData]);

  return (
    <div className="subChildSelectWrapper" ref={listcloseRef}>
      <div className="subChildLabelHolder" onClick={handleOpenMenu}>
        {selectedImage ? (
          <InputField
            image={selectedImage.image}
            label={selectedImage.parent}
            labelName={labelName}
            isOpen={isOpen}
          />
        ) : (
          <InputField labelName={labelName} isOpen={isOpen} />
        )}
      </div>
      <div
        className={`subChildItemsWrapper ${isOpen ? "open" : ""}`}
        ref={listopenRef}
      >
        <div className="acordionChild">
          {dropdownData.map((item, index) => (
            <div className="subChildBox" key={index}>
              <div
                className="accordianTitle"
                onClick={() => handleParentClick(item, index)}
              >
                {item.visible ? (
                  item.value
                ) : (
                  <Radio checked={item.isSelected}>{item.value}</Radio>
                )}
                {item.visible && <ArrowTriangleDown />}
              </div>
              <ul
                className={`accordianChildList${index == accordionOpen.id ? "Open" : ""}${item.value == "Everyone" ? "testclass" : ""}`}
              >
                {item.childern.length > 0 &&
                  item.childern
                    .filter((child) => child.visible == true)
                    .map((childData, childKey) => (
                      <li key={childKey}>
                        <Checkbox
                          checked={childData.isSelected}
                          onChange={(e) => handleChecked(e, index, childKey)}
                        >
                          <span className="valuee">{childData.value}</span>
                        </Checkbox>
                      </li>
                    ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
      <p
        className={`customCounter ${selectedCheckboxCount > 0 ? "showCounter" : ""}`}
      >
        {selectedCheckboxCount > 0 && (
          <span className="counterNumber">{selectedCheckboxCount}</span>
        )}
      </p>
    </div>
  );
};

export default TreeSelectComponent;
