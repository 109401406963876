import React, { useEffect, useState } from "react";
import { Image, message, Tabs, Upload } from "antd";
import { Button, Col, Row } from "react-bootstrap";
import { Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  AcrobatIcon,
  DropPDF,
  ModalCloseIcon,
} from "../../../../../constants/svg";
import { setUploadFileTabNo } from "../../../../../store/slices/flashcardSlice";
import { setSelectPreUploadedFile } from "../../../../../store/slices/globalSlice";
import GalleryTab from "../../../../Common/GalleryTab";
import FolderTab from "../../../../Common/FolderTab";
import CustomUploader from "../../../../Common/CustomUploader";

const SecondStep = ({
  register,
  errors,
  control,
  setValue,
  getValues,
  submitted = false,
}) => {
  const dispatch = useDispatch();
  const [image, setImage] = useState(null);
  const { uploadFileTabNo, flashCardAddEditForm } = useSelector(
    (state) => state.flashCard,
  );

  useEffect(() => {
    setImage(getValues("previewImage"));
  }, [getValues("previewImage"), image]);

  // Dragger image options
  const props = {
    name: "file",
    multiple: true,
    // action: 'https://www.mocky.io/v2/5185415ba171ea3a00704eed',
    beforeUpload: (file) => {
      const isImage = file.type.startsWith("image/");
      if (!isImage) {
        message.error("You can only upload image files!");
      }
      return isImage;
    },
    defaultFileList: [
      {
        uid: "2",
        name: "yyy.png",
        status: "done",
        url: "http://www.baidu.com/yyy.png",
      },
    ],
    showUploadList: {
      showDownloadIcon: false,
      showRemoveIcon: true,
      removeIcon: <ModalCloseIcon />,
    },
  };

  // Table Component data
  const TabsContent = [
    {
      key: 1,
      label: `Gallery`,
      children: (
        <>
          <Controller
            control={control}
            name="uploadFileFromGallery"
            // rules={{
            //     required: uploadFileTabNo == 2 && (getValues('previewImage') == '' || getValues('previewImage') == undefined) ? true : false
            // }}
            render={({ field: { onChange, onBlur, value } }) => (
              <GalleryTab
                incSearch={true}
                handleChange={onChange}
                submitted={submitted}
              />
            )}
          />
          {/* {errors.uploadFileFromGallery && (
                        <Form.Text>{errors.uploadFileFromGallery.message}</Form.Text>
                    )} */}
        </>
      ),
    },
    {
      key: 2,
      label: `Folders`,
      children: (
        <>
          <Controller
            control={control}
            name="uploadFileFromFolder"
            // rules={{
            //     required: uploadFileTabNo == 3 && (getValues('previewImage') == '' || getValues('previewImage') == undefined) ? true : false
            // }}
            render={({ field: { onChange, onBlur, value } }) => (
              <FolderTab handleChange={onChange} submitted={submitted} />
            )}
          />
          {/* {errors.uploadFileFromFolder && (
                        <Form.Text>{errors.uploadFileFromFolder.message}</Form.Text>
                    )} */}
        </>
      ),
    },
    {
      key: 3,
      label: `Upload`,
      children: (
        <div style={{ marginTop: "16px" }}>
          <Controller
            control={control}
            name="uploadFileFromLocal"
            defaultValue={false}
            render={({ field: { onChange, onBlur, value } }) => (
              <CustomUploader
                {...props}
                showUploadList={{
                  showDownloadIcon: false,
                  showPreviewIcon: true,
                }}
                fileList={value == "" ? [] : value}
                onChange={(e) => {
                  const fileList = e.fileList;
                  setValue("previewImage", "");
                  onChange(fileList.length ? fileList : "");
                }}
                iconRender={(file, listType) => {
                  if (file.type === "application/pdf")
                    return (
                      <span
                        role="img"
                        aria-label="pdf-icon"
                        className="anticon"
                      >
                        <AcrobatIcon />
                      </span>
                    );
                }}
                className="border-0 bg-0 three-type-upload"
                listType="picture"
                beforeUpload={() => {
                  /* update state here */
                  return false;
                }}
              >
                <DropPDF />
              </CustomUploader>
            )}
          />
        </div>
      ),
    },
  ];

  const handleTabsChange = (tabNo) => {
    setValue("uploadFileFromLocal", "");
    setValue("uploadFileFromGallery", "");
    setValue("uploadFileFromFolder", "");
    dispatch(setSelectPreUploadedFile(""));
    dispatch(setUploadFileTabNo(tabNo));
  };

  const handleDelete = () => {
    setImage(null);
    setValue("uploadFileFromLocal", "");
    setValue("previewImage", "");
  };
  return (
    <>
      <p className="proTipText mb-20">
        <span>Pro Tip:</span> Upload a cover photo or choose from the gallery.
        For best results, use an image with little or no text.
      </p>

      <Tabs
        defaultActiveKey="1"
        items={TabsContent}
        onChange={handleTabsChange}
      />

      {image && (
        <Row>
          <Col md={12}>
            <div className="img-upload-preview">
              {/* <strong>Preview</strong> */}
              {/* <br /> */}
              <Image
                className="ant-img-custom-css upload-img"
                preview={false}
                src={image}
              />
              {!submitted && (
                <Button
                  type="button"
                  className={"delete-img"}
                  onClick={() => handleDelete()}
                >
                  <ModalCloseIcon />
                </Button>
              )}
            </div>
          </Col>
        </Row>
      )}
    </>
  );
};

export default SecondStep;
