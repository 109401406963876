import { Avatar, Popover, Space } from "antd";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import { Assets } from "../../constants/images";

import {
  EditIcon,
  NewEveryOneIcon,
  NewGroupIcon,
  PurpleBackIcon,
  RecycleBin,
} from "../../constants/svg";

import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import DeleteModal from "../../components/Modals/DeleteModal";
import {
  deleteAlert,
  getSingleAlert,
} from "../../services/alerts/frontend-alerts";
import {
  alert_type,
  link_category_type,
  link_types,
  recipient_types,
  repeat_alert_type,
  roles as salonRoles,
  weekly_days,
} from "../../utils/constants";
import {
  convertToLocal,
  getNumberTitle,
  hasAccess,
  getStringTime,
  convertTimezone,
  getTimefromString,
} from "../../utils/helper";
import { useImageLoader } from "../../utils/imagesLoader";
import LoadingScreen from "../../components/LoadingScreen/LoadingScreen";
import { FormProvider, useForm } from "react-hook-form";
import CreatePushAlertModal from "../../components/pages/PushAlertPage/CreatePushAlertModal";
import ProfileHover from "../../components/Common/ProfileHover";
import AvatarImage from "../../components/Common/AvatarImage";
import { differenceBy, map } from "lodash";

const PushAlertDetailPage = () => {
  const loc = useLocation();
  const imageLoader = useImageLoader();

  const { singleAlert } = useSelector((state) => state?.alerts);
  const { statUserHover } = useSelector((state) => state.global);
  const { positions } = useSelector((state) => state.global);
  const ss = loc.search;

  const [showModal, setShowModal] = useState({
    shareModal: null,
    editModal: null,
    deleteModal: null,
  });
  const methods = useForm();
  const [show, setShow] = useState(null);
  const [showEdit, setShowEdit] = useState(false);
  const [userAvatars, setUserAvatars] = useState(false);

  useEffect(() => {
    const loadImages = async () => {
      for (const user of singleAlert?.data?.userRecipients || []) {
        if (user?.image) {
          const image = await imageLoader(user.image, 38);
          setUserAvatars((prev) => ({ ...prev, [user.image]: image }));
        }
      }
    };

    loadImages();
  }, [singleAlert, imageLoader]);

  const [selectedData, setSelectedData] = useState(null);
  const [hoveredItem, setHoveredItem] = useState(null);
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (showEdit === false) {
      fetchSingleALert();
    }
  }, [showEdit, id]);

  const [disable, setDisable] = useState(false);

  const { userAlertsView, userRecipients } = singleAlert?.data || {};
  const openedUsers = map(userAlertsView, "user");
  const unOpenedUsers = differenceBy(userRecipients, openedUsers, "id");
  const unOpenedUserItems = map(unOpenedUsers, (item) => ({ user: item }));

  const handleDelete = async () => {
    const selectedData = {
      key: id,
      type: singleAlert.data.type,
    };
    await deleteAlert(selectedData, setDisable, showModal, setShowModal);
    setTimeout(() => {
      navigate("/alerts");
    }, 1200);
  };

  const handleMouseEnter = (item) => {
    setHoveredItem(item.user?.id);
  };

  const handleMouseLeave = () => {
    setHoveredItem(null);
    setShow(false);
  };

  const returnAlertType = (type) => {
    return type == alert_type?.immediate
      ? "Immediate"
      : type == alert_type?.recurring
        ? "Recurring"
        : "Scheduled";
  };

  const returnLink = () => {
    const urlObject = new URL(window.location.href);
    const baseUrl = `${urlObject.protocol}//${urlObject.hostname}`;

    if (singleAlert?.data?.link_type) {
      if (singleAlert?.data?.link_type == link_types?.external) {
        return (
          <a
            href={singleAlert?.data?.link}
            target="_blank"
            className={"uLine"}
            rel="noreferrer"
          >
            {singleAlert?.data?.link}
          </a>
        );
      } else if (singleAlert?.data?.link_type == link_types?.internal) {
        let category =
          singleAlert?.data?.link_category == link_category_type?.documents
            ? "team/docs"
            : singleAlert?.data?.link_category == link_category_type?.flashcard
              ? "flashcards"
              : singleAlert?.data?.link_category == link_category_type?.quizzes
                ? "quizzes"
                : singleAlert?.data?.link_category ==
                    link_category_type?.resources
                  ? "resources"
                  : singleAlert?.data?.link_category ==
                      link_category_type?.surveys
                    ? "surveys"
                    : singleAlert?.data?.link_category ==
                        link_category_type?.events
                      ? "events"
                      : " ";
        let link_id = singleAlert?.data?.link_id;
        let url = `${baseUrl}/${category}/details/${link_id}`;

        return (
          <NavLink to={url} className={"uLine"}>
            {url}
          </NavLink>
        );
      } else {
        return "-";
      }
    } else {
      return "-";
    }
  };

  const fetchSingleALert = async () => {
    if (typeof id != "undefined") {
      await getSingleAlert(id, dispatch);
    }
  };
  useEffect(() => {
    let ls = singleAlert?.data?.last_sent;
    let timezone = "EST";
    if (singleAlert?.data?.timezone) timezone = singleAlert?.data?.timezone;
    if (
      singleAlert?.data?.type == alert_type.immediate &&
      singleAlert?.data?.time
    ) {
      ls = convertTimezone(
        singleAlert?.data?.last_sent.split("T")[0] +
          "T" +
          singleAlert?.data?.time,
        timezone || "EST",
      );
    } else {
      ls = getTimefromString(singleAlert?.data?.time);
    }
    const data = {
      group: false,
      key: singleAlert?.data?.id,
      alertTitle: {
        title: singleAlert?.data?.name || "-",
        lock: singleAlert?.data?.user?.roles[0]?.id == salonRoles.super_admin,
      },
      ls,
      time: singleAlert?.data?.time,
      date: singleAlert?.data?.date,
      sendType: {
        main: singleAlert?.data?.typeName,
        sub:
          singleAlert?.data?.type == alert_type.scheduled
            ? moment(singleAlert?.data?.date).format("MMM DD, yyyy")
            : singleAlert?.data?.repeatTypeName,
      },
      lastSent: {
        main: singleAlert?.data?.last_sent
          ? moment(singleAlert?.data?.last_sent).format("MMM DD, yyyy")
          : "-",
        sub: singleAlert?.data?.last_sent
          ? moment(singleAlert?.data?.last_sent).format("hh:mm a")
          : "-",
      },
      seenBy: singleAlert?.data?.userAlertsView?.length,
      attendeeNames: singleAlert?.data?.userAlertsView?.map(
        (item) => item?.user?.name,
      ),
      salon: singleAlert?.data?.salon?.name,
      type: singleAlert?.data?.type,
    };
    setSelectedData(data);
  }, [showEdit]);

  let datetime, sendDateTime, lastSent;
  if (singleAlert.isSingleLoading || !singleAlert.data) {
    return <LoadingScreen child={true} />;
  } else {
    switch (singleAlert?.data?.type) {
      case alert_type.recurring:
        datetime = singleAlert?.data?.last_sent
          ? convertToLocal(
              singleAlert?.data?.last_sent,
              singleAlert?.data?.time,
            )
          : null;
        sendDateTime = convertToLocal(
          singleAlert?.data?.date,
          singleAlert?.data?.time,
        );
        lastSent = singleAlert?.data?.time
          ? getTimefromString(singleAlert?.data?.time)
          : getStringTime(singleAlert?.data?.last_sent);
        switch (singleAlert?.data?.repeat_type) {
          case repeat_alert_type.weekly:
            sendDateTime.localDate = `Every ${weekly_days[sendDateTime?.day]}`;
            break;
          case repeat_alert_type.bi_weekly:
            sendDateTime.localDate = `Every Other ${weekly_days[sendDateTime?.day]}`;
            break;
          case repeat_alert_type.monthly: {
            let day = singleAlert?.data?.date;
            let [_year, _month, _date] = day.split("-");
            sendDateTime.localDate = singleAlert?.data?.is_last_month
              ? "Last of each month"
              : getNumberTitle(parseInt(_date)) + " of each month";
            break;
          }
          case repeat_alert_type.annually:
            sendDateTime.localDate = `${sendDateTime?.localDate.split(",")[0]} Every Year`;
            break;
        }
        break;
      case alert_type.scheduled:
        datetime = singleAlert?.data?.last_sent
          ? convertToLocal(
              singleAlert?.data?.last_sent,
              singleAlert?.data?.time,
            )
          : null;
        sendDateTime = convertToLocal(
          singleAlert?.data?.date,
          singleAlert?.data?.time,
        );
        lastSent = getTimefromString(singleAlert?.data?.time);
        break;
      case alert_type.immediate:
        datetime = convertToLocal(null, null, singleAlert?.data?.last_sent);
        if (singleAlert?.data?.time) {
          //          lastSent = getTimefromString(singleAlert?.data?.time);
          console.log(singleAlert);
          lastSent = convertTimezone(
            singleAlert?.data?.last_sent.split("T")[0] +
              "T" +
              singleAlert?.data?.time,
            singleAlert?.data?.timezone ||
              singleAlert?.data.userRecipients[0].timezone,
          );
        } else if (singleAlert?.data.userRecipients.length > 0)
          lastSent = convertTimezone(
            singleAlert?.data?.last_sent,
            singleAlert.data.timezone ||
              singleAlert?.data.userRecipients[0].timezone ||
              "EST",
          );
        sendDateTime = datetime;
        break;
    }
  }

  const returnPosition = (position_id) => {
    for (let i = 0; i < positions.length; i++) {
      if (positions[i].value == position_id) {
        return positions[i]?.label || "-";
      }
    }
  };
  return (
    <>
      <Row>
        <Col lg={8} className={"pe-0"}>
          <div className="leftEventDetailWrapper">
            <div className="eventDetailHeader innerContainer d-flex justify-content-between">
              <div className="backToEvent">
                <NavLink to="/alerts">
                  {/* <PurpleBackIcon /> */}
                  <PurpleBackIcon />
                  <span className="uLineHover">Back to Alerts</span>
                </NavLink>
              </div>
              <div className="shareButton">
                <Space size={"large"}>
                  {singleAlert?.data?.type != alert_type.immediate &&
                    hasAccess(singleAlert?.data?.user) && (
                      <div
                        className="icon-text-wrapper cursor-pointer"
                        onClick={() => setShowEdit(true)}
                      >
                        <EditIcon />
                        <p className="uLine redLine">Edit</p>
                      </div>
                    )}
                  {hasAccess(singleAlert?.data?.user) && (
                    <div
                      className="icon-text-wrapper deleteLabel cursor-pointer"
                      onClick={() =>
                        setShowModal((prevState) => ({
                          ...prevState,
                          deleteModal: true,
                        }))
                      }
                    >
                      <RecycleBin />
                      <p className="uLine redLine">Delete</p>
                    </div>
                  )}
                </Space>
              </div>
            </div>
            <div className="eventDetailBody">
              <div className="innerContainer">
                <h3 className="sec-heading">{singleAlert?.data?.name}</h3>
                <div className="eventDetailInformation">
                  <div className="eventDetailItem">
                    <h5 className="head">Recipients</h5>
                    <div className="recipt-div">
                      {singleAlert?.data?.recipient_type ==
                      recipient_types?.everyone ? (
                        <div>
                          <NewEveryOneIcon />
                          <p className="d-inline-block">
                            &nbsp;&nbsp; Everyone
                          </p>
                        </div>
                      ) : singleAlert?.data?.recipient_type ==
                        recipient_types?.group ? (
                        singleAlert?.data?.groupRecipients?.length > 0 ? (
                          <div
                            style={{
                              fontSize: "13px",
                              color: "#000000",
                              lineHeight: "20px",
                            }}
                          >
                            <NewGroupIcon />
                            &nbsp;&nbsp;
                            {singleAlert?.data?.groupRecipients
                              ?.map((item) => item?.name)
                              .join(", ")}
                          </div>
                        ) : (
                          "-"
                        )
                      ) : singleAlert?.data?.userRecipients?.length > 0 ? (
                        <Avatar.Group>
                          {singleAlert?.data?.userRecipients
                            ?.slice(0, 5)
                            ?.map((item, index) => (
                              <AvatarImage
                                src={item?.image}
                                placeholder={Assets.ProfilePlaceholder}
                                size={26}
                                key={index}
                              />
                            ))}
                          {singleAlert?.data?.userRecipients?.length > 5 && (
                            <Avatar
                              size={25}
                              style={{
                                backgroundColor: "#f0f0f0",
                                color: "#000",
                                // fontWeight: 'bold',
                                cursor: "pointer",
                                fontSize: "12px",
                              }}
                            >
                              +{singleAlert?.data?.userRecipients?.length - 5}
                            </Avatar>
                          )}
                        </Avatar.Group>
                      ) : (
                        "-"
                      )}
                    </div>
                  </div>
                  <div className="eventDetailItem">
                    <h5 className="head">Message</h5>
                    <p>{singleAlert?.data?.message || "-"}</p>
                  </div>
                  <div className="eventDetailItem">
                    <h5 className="head">Alert Link</h5>
                    {returnLink()}
                  </div>

                  <div className="eventDetailItem">
                    <h5 className="head">Alert Type</h5>
                    <p>{returnAlertType(singleAlert?.data?.type)}</p>
                  </div>
                  <div className="eventDetailItem">
                    <h5 className="head">Send Date</h5>
                    <p>
                      {sendDateTime?.localDate ? sendDateTime?.localDate : "-"}
                    </p>
                  </div>
                  <div className="eventDetailItem">
                    <h5 className="head">Send Time</h5>
                    <p>
                      {lastSent ||
                        (sendDateTime?.localTime
                          ? sendDateTime?.localTime
                          : "-")}
                    </p>
                  </div>
                  <div className="eventDetailItem">
                    <h5 className="head">Last Sent</h5>
                    <p>
                      {datetime
                        ? datetime?.localDate
                          ? datetime?.localDate + " " + lastSent ||
                            datetime?.localTime
                          : ""
                        : "-"}
                    </p>
                  </div>
                  <div className="eventDetailItem">
                    <h5 className="head">Created By</h5>
                    <p>{singleAlert?.data?.user?.name}</p>
                  </div>
                  <div className="eventDetailItem">
                    <h5 className="head">Created On</h5>
                    <p>
                      {singleAlert?.data?.created_at
                        ? moment(singleAlert?.data?.created_at)
                            .local()
                            .format("MMM DD, yyyy")
                        : "-"}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
        <Col onMouseLeave={handleMouseLeave} lg={4} className={"p-0"}>
          <div className="rightEventDetailWrapper">
            <div className="eventDetailHeader d-flex justify-content-between">
              <h4 className="sec-heading m-0 p-0">Total Views</h4>
            </div>
            <div className="eventDetailBody">
              <div className="response-card">
                <div className="response-header">
                  <h6>Opened ({singleAlert?.data?.userAlertsView?.length})</h6>
                </div>
                <div className="response-body">
                  {singleAlert?.data?.userAlertsView?.length > 0 &&
                    singleAlert?.data?.userAlertsView?.map((item, index) => {
                      if (item?.user?.name) {
                        return (
                          <div
                            style={{ position: "relative" }}
                            className="avatar-item"
                            key={index}
                          >
                            <Popover
                              placement="rightTop"
                              content={
                                <ProfileHover
                                  user={{
                                    id: item?.user?.id,
                                    name: item?.user?.name,
                                    image: userAvatars[item?.user?.image],
                                    position: returnPosition(
                                      item?.user?.position_id,
                                    ),
                                  }}
                                />
                              }
                              arrow={true}
                              key={index}
                            >
                              <div>
                                <AvatarImage
                                  name={item?.user?.name}
                                  src={userAvatars[item?.user?.image]}
                                  placeholder={Assets.ProfilePlaceholder}
                                  size={38}
                                />

                                {/* <ProfileHoverChat */}
                                {/*  user={{ */}
                                {/*    id: item?.user?.id, */}
                                {/*    name: item?.user?.name, */}
                                {/*    image: item?.user?.image, */}
                                {/*    position: returnPosition(item?.user?.position_id) */}
                                {/*  }} */}
                                {/*  show={(statUserHover && (statUserHover.id == item?.user?.id)) ? true : false} */}
                                {/* /> */}
                              </div>
                            </Popover>
                            <NavLink
                              key={index}
                              to={`/team/members/${item?.user?.slug ?? item?.user?.id}`}
                            >
                              <p>{item?.user?.name || "-"}</p>
                            </NavLink>
                          </div>
                        );
                      }
                    })}
                </div>
              </div>
              <div className="response-card">
                <div className="response-header">
                  <h6>Not Opened ({unOpenedUserItems?.length})</h6>
                </div>
                <div className="response-body">
                  {unOpenedUserItems?.length > 0 &&
                    unOpenedUserItems?.map((item, index) => {
                      if (item?.user?.name) {
                        return (
                          <div
                            style={{ position: "relative" }}
                            className="avatar-item"
                            key={index}
                          >
                            <Popover
                              placement="rightTop"
                              content={
                                <ProfileHover
                                  user={{
                                    id: item?.user?.id,
                                    name: item?.user?.name,
                                    image: userAvatars[item?.user?.image],
                                    position: returnPosition(
                                      item?.user?.position_id,
                                    ),
                                  }}
                                />
                              }
                              arrow={true}
                              key={index}
                            >
                              <div>
                                <AvatarImage
                                  name={item?.user?.name}
                                  src={userAvatars[item?.user?.image]}
                                  placeholder={Assets.ProfilePlaceholder}
                                  size={38}
                                />

                                {/* <ProfileHoverChat */}
                                {/*  user={{ */}
                                {/*    id: item?.user?.id, */}
                                {/*    name: item?.user?.name, */}
                                {/*    image: item?.user?.image, */}
                                {/*    position: returnPosition(item?.user?.position_id) */}
                                {/*  }} */}
                                {/*  show={(statUserHover && (statUserHover.id == item?.user?.id)) ? true : false} */}
                                {/* /> */}
                              </div>
                            </Popover>
                            <NavLink
                              key={index}
                              to={`/team/members/${item?.user?.slug ?? item?.user?.id}`}
                            >
                              <p>{item?.user?.name || "-"}</p>
                            </NavLink>
                          </div>
                        );
                      }
                    })}
                </div>
              </div>
            </div>
          </div>
        </Col>

        {/* Delete Modal */}
        <DeleteModal
          show={showModal.deleteModal}
          onHide={() => setShowModal({ ...showModal, deleteModal: false })}
          heading="Delete Alert?"
          handleDelete={handleDelete}
          disabled={disable}
          para={
            <>
              Are you sure you want to delete this
              <b>
                {singleAlert?.data?.type == alert_type?.immediate
                  ? " immediate "
                  : singleAlert?.data?.type == alert_type?.recurring
                    ? " recurring "
                    : " scheduled "}
                alert?
              </b>
              <br />
              Important: This alert cannot be recovered.
            </>
          }
          notificationMessage="Alert"
        />
      </Row>
      <FormProvider {...methods}>
        <CreatePushAlertModal
          show={showEdit}
          onHide={() => {
            setShowEdit(false);
          }}
          setModalShow={setShowEdit}
          methods={methods}
          selectedData={selectedData}
          setSelectedData={setSelectedData}
          isedit={true}
        />
      </FormProvider>
    </>
  );
};

export default PushAlertDetailPage;
