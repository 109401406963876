import { Space, Table } from "antd";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { tableHeight } from "../../../utils/constants";
import { loader } from "../../../utils/helper";
import AvatarImage from "../../Common/AvatarImage";
import { Assets } from "../../../constants/images";
import { useImageLoader } from "../../../utils/imagesLoader";
import { RoundCheckMark, RoundCheckMarkFilled } from "../../../constants/svg";
let throttle = {};

const ActivityTable = ({ activeTab, readType, getData, store, ...props }) => {
  const _states = useSelector((state) => state[store?.store]);
  const imageLoader = useImageLoader();
  throttle[store?.param] = throttle?.[store?.param] || 0;
  const [page, setPage] = useState(1);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [avatarImages, setAvatarImages] = useState({});

  useEffect(() => {
    const loadImages = async () => {
      for (const item of data || []) {
        if (item.alertTitle?.image) {
          const image = await imageLoader(item.alertTitle.image, 38);
          setAvatarImages((prev) => ({
            ...prev,
            [item.alertTitle.image]: image,
          }));
        }
      }
    };

    loadImages();
  }, [data, imageLoader]);

  useEffect(() => {
    if (_states[store?.param]?.data.length > 0) {
      setLoading(false);
      const _data =
        _states[store?.param]?.data ||
        _states[store?.param].results ||
        _states[store?.param];
      const mergeData = [...data, ..._data];
      const key = _data[0] && _data[0].key ? "key" : "id";
      let uniqueObjects = mergeData.filter(
        (obj, index, self) =>
          index == self.findIndex((o) => o[key] == obj[key]),
      );
      uniqueObjects.sort(function (a, b) {
        let keyA = a[key];
        let keyB = b[key];
        // Compare the 2 dates
        if (keyA > keyB) return -1;
        if (keyA < keyB) return 1;
        return 0;
      });
      uniqueObjects = uniqueObjects.filter(
        (item) => ![...props.filterKeys].includes(item.key),
      );
      setPage(_states[store?.param]?.meta?.current_page || 1 || 1);
      setData((prevData) => [...uniqueObjects]);
      setHasMore(_states[store?.param]?.meta?.last_page > page);
    }
  }, [_states[store?.param]]);

  const columns = [
    {
      width: "23%",
      title: "Member",
      dataIndex: "alertTitle",
      key: "alertTitle",
      sorter: (a, b) => a.alertTitle.title.localeCompare(b.alertTitle.title),
      sortDirections: ["descend", "ascend", "descend"],
      render: (text, record) => (
        // <NavLink to="/event-detail" className={"black"}>
        <Space size={19}>
          <AvatarImage
            name={record.alertTitle.title}
            src={avatarImages[record.alertTitle.image]}
            placeholder={Assets.ProfilePlaceholder}
            size={36}
          />
          <span>{record.alertTitle.title}</span>
        </Space>
        // </NavLink>
      ),
    },
    {
      width: "47%",
      title: "Action",
      dataIndex: "sendType",
      key: "sendType",
      sorter: (a, b) => a.sendType.main.localeCompare(b.sendType.main),
      sortDirections: ["descend", "ascend", "descend"],
      render: (text, record) => (
        <div className="truncateActivityAction">{record.sendType.main}</div>
      ),
      align: "left",
    },
    {
      width: "15%",
      title: "Date/Time",
      dataIndex: "lastSent",
      key: "lastSent",
      sorter: (a, b) => a.lastSent.time.localeCompare(b.lastSent.time),
      sortDirections: ["descend", "ascend", "descend"],
      defaultSortOrder: "descend",
      render: (text, record) => <p>{record.lastSent.main}</p>,
      align: "left",
    },
    {
      width: "15%",
      title: "Clear",
      key: "action",
      align: "center",
      render: (_, record) => (
        <Space size="middle">
          <div
            className={`checkmarkdiv`}
            onClick={() => props?.handleActivityClick(record, props?.readType)}
          >
            {record.checkMark ? (
              <RoundCheckMarkFilled className="checkmark-svg" />
            ) : (
              <RoundCheckMark className="checkmark-svg" />
            )}
          </div>
        </Space>
      ),
    },
  ];

  const fetchData = async (_page) => {
    if (!hasMore) return;
    if (_page <= throttle[store?.param]) return;
    throttle[store?.param] = _page;
    if (page != 1) setLoading(true);
    if (page >= _states[store?.param]?.meta?.last_page) setLoading(false);
    await getData({ page: _page, "per-page": 10 });
  };

  const handleScroll = (e) => {
    // Check if the user has scrolled to the bottom
    const { scrollTop, clientHeight, scrollHeight } = e.currentTarget;
    const difference = parseInt(scrollHeight - scrollTop) - clientHeight;
    if (Math.abs(difference) <= 5) {
      fetchData(page + 1);
    }
  };
  useEffect(() => {
    fetchData(page);
  }, []);

  return (
    <>
      <div
        onScroll={handleScroll}
        style={{ overflow: "auto", height: tableHeight.height }}
      >
        <Table
          className="activityTable"
          columns={columns}
          dataSource={data}
          pagination={false}
          bordered={false}
          responsive={true}
          size={"large"}
          sticky={true}
          showSorterTooltip={false}
        />
      </div>
      <div className="table-loader">{loader(loading)}</div>
    </>
  );
};
export default ActivityTable;
