/* eslint-disable */
import { useState, useCallback, useRef } from "react";

const useImageLoader = () => {
  const [imageCache, setImageCache] = useState({});
  const loadingImages = useRef({});

  const loadImage = useCallback(
    async (url, width) => {
      return url;
      // if (imageCache[url]) {
      //   return imageCache[url];
      // }
      //
      // if (loadingImages.current[url]) {
      //   return loadingImages.current[url];
      // }
      //
      // try {
      //   const imagePromise = (async () => {
      //     try {
      //       const response = await fetch(url);
      //       if (!response.ok) {
      //         throw new Error(`Failed to fetch image: ${response.statusText}`);
      //       }
      //
      //       const blob = await response.blob();
      //       let resizedBlob = blob;
      //
      //       if (width) {
      //         resizedBlob = await resizeImage(blob, width);
      //       }
      //
      //       const objectURL = URL.createObjectURL(resizedBlob);
      //       setImageCache((prevCache) => ({ ...prevCache, [url]: objectURL }));
      //       return objectURL;
      //     } catch (error) {
      //       console.error("Error loading image:", error, url);
      //       return null;
      //     }
      //   })();
      //
      //   loadingImages.current[url] = imagePromise;
      //   const image = await imagePromise;
      //   delete loadingImages.current[url];
      //
      //   return image;
      // } catch (error) {
      //   console.error("Error loading image:", error, url);
      //   return null;
      // }
    },
    [imageCache],
  );

  const resizeImage = (blob, width) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = URL.createObjectURL(blob);

      img.onload = () => {
        const canvas = document.createElement("canvas");
        const aspectRatio = img.height / img.width;
        canvas.width = width;
        canvas.height = width * aspectRatio;

        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

        canvas.toBlob(resolve, "image/jpeg");
      };

      img.onerror = (error) => reject(error);
    });
  };

  return loadImage;
};

export { useImageLoader };
