import React from "react";

export const constant = {
  DEVICE_TYPE: "web",
  DEVICE_TOKEN: "ABC123",
  LOCAL_STORAGE_TOKEN: "SALON_SYMPHONY_TOKEN",
  LOCAL_STORAGE_USER: "SALON_SYMPHONY_USER",
  LOCAL_STORAGE_ROLE: "SALON_SYMPHONY_USER_ROLE",
  BASE_URL: process.env.REACT_APP_BASE_URL,
  DIR_NAME: "media",
  SUPPORT_URL: process.env.REACT_APP_SUPPORT_URL,
  GOOGLE_MAP_KEY: process.env.REACT_APP_GOOGLE_MAP_KEY,
  ZENDESK_TICKET_URL: process.env.REACT_APP_ZENDESK_TICKET_URL,
  FALLBACK_IMAGE_URL: process.env.REACT_APP_FALLBACK_IMAGE_URL,
};

export const s3Credential = {
  bucketName: process.env.REACT_APP_S3_bucketName,
  region: process.env.REACT_APP_S3_region,
  accessKeyId: process.env.REACT_APP_S3_accessKeyId,
  secretAccessKey: process.env.REACT_APP_S3_secretAccessKey,
  s3Url: process.env.REACT_APP_S3_URL,
};

export const tableHeight = {
  height: `calc(100vh - 205px)`,
  LazyLoadTableHeight: `calc(100vh - 500px)`,
  scrollHeight: `calc(100vh - 270px)`,
  teamHeightTable: `calc(100vh - 275px)`,
  teamHeightTableMinus: 275,
};

export const roles = {
  super_admin: 1,
  admin: 2,
  member: 3,
  owner: 4,
};

export const user_requests = {
  education: 10,
  timeoff: 20,
  schedule: 30,
  reimbursement: 40,
  hours: 10,
};

export const user_request_sub_types = {
  HOURS: 10,
  REIMBURSEMENT: 20,
  TOOLS: 30,
  TRAVEL: 40,
  MEAL: 50,
  COST: 60,
  SICK: 70,
  VACATION: 80,
  PERSONAL: 90,
  EDUCATION: 100,
  FMLA: 110,
  MEDICAL: 120,
  PETERNITY_LEAVE: 130,
  BEREAVEMNET: 140,
  CHANGE_SHIFT: 150,
  CHANGE_DAY: 160,
};

export const user_requests_status = {
  pending: 10,
  accepted: 20,
  declined: 30,
};

export const status = {
  inactive: 20,
  active: 10,
};
export const memberScheduleStatus = {
  inactive: 10,
  active: 20,
};

export const event_status = {
  going: 10,
  interested: 20,
  not_going: 30,
};

export const event_type = {
  past: 10,
  upcoming: 20,
};

export const salooon_event_type = {
  onsite: 10,
  online: 20,
};

export const goal_type = {
  weekly: 10,
  monthly: 20,
  annual: 30,
  custom: 40,
};

export const user_goal_status = {
  active: 10,
  achieved: 20,
  missed: 30,
};

export const flashcard_status = {
  started: 10,
  completed: 20,
  not_started: 30,
};

export const task_type = {
  one_time: 10,
  weekly: 20,
  bi_weekly: 30,
  monthly: 40,
};

export const salon_resources_type = {
  video: 10,
  audio: 20,
  pdf: 30,
  link: 40,
};

export const alert_type = {
  immediate: 10,
  scheduled: 20,
  recurring: 30,
};

export const target_types = {
  dollar: 10,
  percentage: 20,
  number: 30,
};

export const activity_type = {
  unread: 20,
  read: 10,
};

export const alert_category_type = {
  resources: "/resources",
  documents: "/documents",
  flashcards: "/flashcards",
  surveys: "/surveys",
  quizzes: "/quizzes",
};

export const search_module_type = {
  resources: 10,
  events: 20,
  tasks: 30,
  goals: 40,
  documents: 50,
  surveys: 60,
  flashcard: 70,
  quizzes: 80,
  members: 90,
  alerts: 100,
  requests: 110,
  onboarding: 120,
  user_referral: 130,
  salons: 140,
  user_rewards: 150,
};

export const link_types = {
  internal: 30,
  external: 20,
  no_link: 10,
};

export const link_category_type = {
  resources: 10,
  documents: 50,
  flashcard: 70,
  surveys: 60,
  quizzes: 80,
  events: 20,
};

export const message_type = {
  text: 10,
  image: 20,
  video: 30,
  default: 40,
  audio: 50,
  pdf: 60,
  media: 70,
};
export const segment_type = {
  ALL: 10,
  CUSTOM: 20,
};
export const default_resource_access = {
  NO: 10,
  YES: 20,
};
export const organization_status = {
  active: 10,
  inactive: 20,
};

export const collection_ref = {
  chats: "chats",
  users: "users",
  threads: "threads",
};

export const online_status = {
  online: true,
  offline: false,
};

export const gallery_type = {
  image: 10,
  video: 20,
  audio: 30,
  folder: 40,
  pdf: 50,
};

export const gallery_type_string = {
  image: "Image",
  video: "Video",
  audio: "Audio",
  folder: "Folder",
  pdf: "PDF",
};

export const week_status = {
  monday: 1,
  tuesday: 2,
  wednessday: 3,
  thursday: 4,
  friday: 5,
  saturday: 6,
  sunday: 7,
};

export const repeat_alert_type = {
  weekly: 1,
  monthly: 2,
  quarterly: 3,
  annually: 4,
  bi_weekly: 5,
};

export const all_category_type = {
  RFQ: 10,
  survey: 20,
  event: 30,
};

export const recipient_types = {
  everyone: 1001,
  group: 1002,
  member: 1003,
};

export const subscriptionStatus = {
  none: 0,
  pending: 10,
  paid: 20,
  canceled: 30,
};

export const salonJobType = {
  admin: 20,
  mobile: 10,
};
export const groupTypes = {
  CUSTOM: 10,
  LOCATION: 20,
  POSITION: 30,
};

export const salonQuestionType = {
  MULTIPLE: 10,
  PICTURE: 20,
  CHECKBOXES: 30,
  STAR: 40,
  EMOJIS: 50,
  DESCRIPTION: 60,
  BOOL: 30,
};

export const weekly_due_every_array = [
  {
    value: 1,
    label: "Monday",
  },
  {
    value: 2,
    label: "Tuesday",
  },
  {
    value: 3,
    label: "Wednesday",
  },
  {
    value: 4,
    label: "Thursday",
  },
  {
    value: 5,
    label: "Friday",
  },
  {
    value: 6,
    label: "Saturday",
  },
  {
    value: 7,
    label: "Sunday",
  },
];

export const weekly_days = {
  1: "Monday",
  2: "Tuesday",
  3: "Wednesday",
  4: "Thursday",
  5: "Friday",
  6: "Saturday",
  7: "Sunday",
};

export const bi_weekly_due_every_array = [
  {
    value: 1,
    label: "Other Monday",
  },
  {
    value: 2,
    label: "Other Tuesday",
  },
  {
    value: 3,
    label: "Other Wednesday",
  },
  {
    value: 4,
    label: "Other Thursday",
  },
  {
    value: 5,
    label: "Other Friday",
  },
  {
    value: 6,
    label: "Other Saturday",
  },
  {
    value: 7,
    label: "Other Sunday",
  },
];

export const monthly_due_every_array = [
  { value: 1, label: "1st of the Month" },
  { value: 2, label: "2nd of the Month" },
  { value: 3, label: "3rd of the Month" },
  { value: 4, label: "4th of the Month" },
  { value: 5, label: "5th of the Month" },
  { value: 6, label: "6th of the Month" },
  { value: 7, label: "7th of the Month" },
  { value: 8, label: "8th of the Month" },
  { value: 9, label: "9th of the Month" },
  { value: 10, label: "10th of the Month" },
  { value: 11, label: "11th of the Month" },
  { value: 12, label: "12th of the Month" },
  { value: 13, label: "13th of the Month" },
  { value: 14, label: "14th of the Month" },
  { value: 15, label: "15th of the Month" },
  { value: 16, label: "16th of the Month" },
  { value: 17, label: "17th of the Month" },
  { value: 18, label: "18th of the Month" },
  { value: 19, label: "19th of the Month" },
  { value: 20, label: "20th of the Month" },
  { value: 21, label: "21st of the Month" },
  { value: 22, label: "22nd of the Month" },
  { value: 23, label: "23rd of the Month" },
  { value: 24, label: "24th of the Month" },
  { value: 25, label: "25th of the Month" },
  { value: 26, label: "26th of the Month" },
  { value: 27, label: "27th of the Month" },
  { value: 28, label: "28th of the Month" },
  // { value: 29, label: "29th of the Month" },
  // { value: 30, label: "30th of the Month" },
  { value: 31, label: "Last of the Month" },
];
export const monthly_and_bi_weekly_repeat_task_array = [
  {
    value: 20,
    label: "For 3 Months",
  },
  {
    value: 30,
    label: "For 6 Months",
  },
  {
    value: 40,
    label: "For 9 Months",
  },
  {
    value: 50,
    label: "Forever",
  },
];

export const weekly_repeat_task_array = [
  {
    value: 10,
    label: "For 1 Month",
  },
  {
    value: 20,
    label: "For 3 Months",
  },
  {
    value: 30,
    label: "For 6 Months",
  },
  {
    value: 50,
    label: "Forever",
  },
];

export const task_repeat_forever = "Forever";

export const user_requests_status_array = [
  {
    label: "All Pending",
    value: user_requests_status.pending,
  },
  {
    label: "All Accepted",
    value: user_requests_status.accepted,
  },
  {
    label: "All Declined",
    value: user_requests_status.declined,
  },
];

export const task_type_array = [
  {
    value: 10,
    label: "One-Time",
  },
  {
    value: 20,
    label: "Weekly",
  },
  {
    value: 30,
    label: "Bi-Weekly",
  },
  {
    value: 40,
    label: "Monthly",
  },
];

export const current_booking_array = [
  {
    value: "BookedBy",
    label: "BookedBy",
  },
  {
    value: "Booker",
    label: "Booker",
  },
  {
    value: "Boulevard",
    label: "Boulevard",
  },
  {
    value: "DaySmart",
    label: "DaySmart",
  },
  {
    value: "Mango Mint ",
    label: "Mango Mint ",
  },
];

export const primary_product_array = [
  {
    value: "Aveda ",
    label: "Aveda ",
  },
  {
    value: "Bumble & Bumble ",
    label: "Bumble & Bumble ",
  },
  {
    value: "Davines",
    label: "Davines",
  },
  {
    value: "Kerastase",
    label: "Kerastase",
  },
  {
    value: "Living Proof",
    label: "Living Proof",
  },
  {
    value: "L’Oreal Professional",
    label: "L’Oreal Professional",
  },
];

export const goal_type_array = [
  {
    label: "Weekly",
    value: 10,
  },
  {
    label: "Monthly",
    value: 20,
  },
  {
    label: "Annual",
    value: 30,
  },
  {
    label: "Custom",
    value: 40,
  },
];

export const event_times = [
  {
    value: "9:00 AM",
    label: "9:00 AM",
  },
  {
    value: "9:15 AM",
    label: "9:15 AM",
  },
  {
    value: "9:30 AM",
    label: "9:30 AM",
  },
  {
    value: "9:45 AM",
    label: "9:45 AM",
  },
  {
    value: "10:00 AM",
    label: "10:00 AM",
  },
  {
    value: "10:15 AM",
    label: "10:15 AM",
  },
  {
    value: "10:30 AM",
    label: "10:30 AM",
  },
  {
    value: "10:45 AM",
    label: "10:45 AM",
  },
  {
    value: "11:00 AM",
    label: "11:00 AM",
  },
  {
    value: "11:15 AM",
    label: "11:15 AM",
  },
  {
    value: "11:30 AM",
    label: "11:30 AM",
  },
  {
    value: "11:45 AM",
    label: "11:45 AM",
  },
  {
    value: "12:00 PM",
    label: "12:00 PM",
  },
  {
    value: "12:15 PM",
    label: "12:15 PM",
  },
  {
    value: "12:30 PM",
    label: "12:30 PM",
  },
  {
    value: "12:45 PM",
    label: "12:45 PM",
  },
  {
    value: "1:00 PM",
    label: "1:00 PM",
  },
  {
    value: "1:15 PM",
    label: "1:15 PM",
  },
  {
    value: "1:30 PM",
    label: "1:30 PM",
  },
  {
    value: "1:45 PM",
    label: "1:45 PM",
  },
  {
    value: "2:00 PM",
    label: "2:00 PM",
  },
  {
    value: "2:15 PM",
    label: "2:15 PM",
  },
  {
    value: "2:30 PM",
    label: "2:30 PM",
  },
  {
    value: "2:45 PM",
    label: "2:45 PM",
  },
  {
    value: "3:00 PM",
    label: "3:00 PM",
  },
  {
    value: "3:15 PM",
    label: "3:15 PM",
  },
  {
    value: "3:30 PM",
    label: "3:30 PM",
  },
  {
    value: "3:45 PM",
    label: "3:45 PM",
  },
  {
    value: "4:00 PM",
    label: "4:00 PM",
  },
  {
    value: "4:15 PM",
    label: "4:15 PM",
  },
  {
    value: "4:30 PM",
    label: "4:30 PM",
  },
  {
    value: "4:45 PM",
    label: "4:45 PM",
  },
  {
    value: "5:00 PM",
    label: "5:00 PM",
  },
  {
    value: "5:15 PM",
    label: "5:15 PM",
  },
  {
    value: "5:30 PM",
    label: "5:30 PM",
  },
  {
    value: "5:45 PM",
    label: "5:45 PM",
  },
  {
    value: "6:00 PM",
    label: "6:00 PM",
  },
  {
    value: "6:15 PM",
    label: "6:15 PM",
  },
  {
    value: "6:30 PM",
    label: "6:30 PM",
  },
  {
    value: "6:45 PM",
    label: "6:45 PM",
  },
  {
    value: "7:00 PM",
    label: "7:00 PM",
  },
  {
    value: "7:15 PM",
    label: "7:15 PM",
  },
  {
    value: "7:30 PM",
    label: "7:30 PM",
  },
  {
    value: "7:45 PM",
    label: "7:45 PM",
  },
  {
    value: "8:00 PM",
    label: "8:00 PM",
  },
  {
    value: "8:15 PM",
    label: "8:15 PM",
  },
  {
    value: "8:30 PM",
    label: "8:30 PM",
  },
  {
    value: "8:45 PM",
    label: "8:45 PM",
  },
  {
    value: "9:00 PM",
    label: "9:00 PM",
  },
  {
    value: "9:15 PM",
    label: "9:15 PM",
  },
  {
    value: "9:30 PM",
    label: "9:30 PM",
  },
  {
    value: "9:45 PM",
    label: "9:45 PM",
  },
  {
    value: "10:00 PM",
    label: "10:00 PM",
  },
  {
    value: "10:15 PM",
    label: "10:15 PM",
  },
  {
    value: "10:30 PM",
    label: "10:30 PM",
  },
  {
    value: "10:45 PM",
    label: "10:45 PM",
  },
  {
    value: "11:00 PM",
    label: "11:00 PM",
  },
  {
    value: "11:15 PM",
    label: "11:15 PM",
  },
  {
    value: "11:30 PM",
    label: "11:30 PM",
  },
  {
    value: "11:45 PM",
    label: "11:45 PM",
  },
  {
    value: "12:00 AM",
    label: "12:00 AM",
  },
  {
    value: "12:15 AM",
    label: "12:15 AM",
  },
  {
    value: "12:30 AM",
    label: "12:30 AM",
  },
  {
    value: "12:45 AM",
    label: "12:45 AM",
  },
  {
    value: "1:00 AM",
    label: "1:00 AM",
  },
  {
    value: "1:15 AM",
    label: "1:15 AM",
  },
  {
    value: "1:30 AM",
    label: "1:30 AM",
  },
  {
    value: "1:45 AM",
    label: "1:45 AM",
  },
  {
    value: "2:00 AM",
    label: "2:00 AM",
  },
  {
    value: "2:15 AM",
    label: "2:15 AM",
  },
  {
    value: "2:30 AM",
    label: "2:30 AM",
  },
  {
    value: "2:45 AM",
    label: "2:45 AM",
  },
  {
    value: "3:00 AM",
    label: "3:00 AM",
  },
  {
    value: "3:15 AM",
    label: "3:15 AM",
  },
  {
    value: "3:30 AM",
    label: "3:30 AM",
  },
  {
    value: "3:45 AM",
    label: "3:45 AM",
  },
  {
    value: "4:00 AM",
    label: "4:00 AM",
  },
  {
    value: "4:15 AM",
    label: "4:15 AM",
  },
  {
    value: "4:30 AM",
    label: "4:30 AM",
  },
  {
    value: "4:45 AM",
    label: "4:45 AM",
  },
  {
    value: "5:00 AM",
    label: "5:00 AM",
  },
  {
    value: "5:15 AM",
    label: "5:15 AM",
  },
  {
    value: "5:30 AM",
    label: "5:30 AM",
  },
  {
    value: "5:45 AM",
    label: "5:45 AM",
  },
  {
    value: "6:00 AM",
    label: "6:00 AM",
  },
  {
    value: "6:15 AM",
    label: "6:15 AM",
  },
  {
    value: "6:30 AM",
    label: "6:30 AM",
  },
  {
    value: "6:45 AM",
    label: "6:45 AM",
  },
  {
    value: "7:00 AM",
    label: "7:00 AM",
  },
  {
    value: "7:15 AM",
    label: "7:15 AM",
  },
  {
    value: "7:30 AM",
    label: "7:30 AM",
  },
  {
    value: "7:45 AM",
    label: "7:45 AM",
  },
  {
    value: "8:00 AM",
    label: "8:00 AM",
  },
  {
    value: "8:15 AM",
    label: "8:15 AM",
  },
  {
    value: "8:30 AM",
    label: "8:30 AM",
  },
  {
    value: "8:45 AM",
    label: "8:45 AM",
  },
];

export const schedule_time_array = [
  {
    value: "9:00 AM",
    label: "9:00 AM",
  },
  {
    value: "9:15 AM",
    label: "9:15 AM",
  },
  {
    value: "9:30 AM",
    label: "9:30 AM",
  },
  {
    value: "9:45 AM",
    label: "9:45 AM",
  },
  {
    value: "10:00 AM",
    label: "10:00 AM",
  },
  {
    value: "10:15 AM",
    label: "10:15 AM",
  },
  {
    value: "10:30 AM",
    label: "10:30 AM",
  },
  {
    value: "10:45 AM",
    label: "10:45 AM",
  },
  {
    value: "11:00 AM",
    label: "11:00 AM",
  },
  {
    value: "11:15 AM",
    label: "11:15 AM",
  },
  {
    value: "11:30 AM",
    label: "11:30 AM",
  },
  {
    value: "11:45 AM",
    label: "11:45 AM",
  },
  {
    value: "12:00 PM",
    label: "12:00 PM",
  },
  {
    value: "12:15 PM",
    label: "12:15 PM",
  },
  {
    value: "12:30 PM",
    label: "12:30 PM",
  },
  {
    value: "12:45 PM",
    label: "12:45 PM",
  },
  {
    value: "1:00 PM",
    label: "1:00 PM",
  },
  {
    value: "1:15 PM",
    label: "1:15 PM",
  },
  {
    value: "1:30 PM",
    label: "1:30 PM",
  },
  {
    value: "1:45 PM",
    label: "1:45 PM",
  },
  {
    value: "2:00 PM",
    label: "2:00 PM",
  },
  {
    value: "2:15 PM",
    label: "2:15 PM",
  },
  {
    value: "2:30 PM",
    label: "2:30 PM",
  },
  {
    value: "2:45 PM",
    label: "2:45 PM",
  },
  {
    value: "3:00 PM",
    label: "3:00 PM",
  },
  {
    value: "3:15 PM",
    label: "3:15 PM",
  },
  {
    value: "3:30 PM",
    label: "3:30 PM",
  },
  {
    value: "3:45 PM",
    label: "3:45 PM",
  },
  {
    value: "4:00 PM",
    label: "4:00 PM",
  },
  {
    value: "4:15 PM",
    label: "4:15 PM",
  },
  {
    value: "4:30 PM",
    label: "4:30 PM",
  },
  {
    value: "4:45 PM",
    label: "4:45 PM",
  },
  {
    value: "5:00 PM",
    label: "5:00 PM",
  },
  {
    value: "5:15 PM",
    label: "5:15 PM",
  },
  {
    value: "5:30 PM",
    label: "5:30 PM",
  },
  {
    value: "5:45 PM",
    label: "5:45 PM",
  },
  {
    value: "6:00 PM",
    label: "6:00 PM",
  },
  {
    value: "6:15 PM",
    label: "6:15 PM",
  },
  {
    value: "6:30 PM",
    label: "6:30 PM",
  },
  {
    value: "6:45 PM",
    label: "6:45 PM",
  },
  {
    value: "7:00 PM",
    label: "7:00 PM",
  },
  {
    value: "7:15 PM",
    label: "7:15 PM",
  },
  {
    value: "7:30 PM",
    label: "7:30 PM",
  },
  {
    value: "7:45 PM",
    label: "7:45 PM",
  },
  {
    value: "8:00 PM",
    label: "8:00 PM",
  },
  {
    value: "8:15 PM",
    label: "8:15 PM",
  },
  {
    value: "8:30 PM",
    label: "8:30 PM",
  },
  {
    value: "8:45 PM",
    label: "8:45 PM",
  },
  {
    value: "9:00 PM",
    label: "9:00 PM",
  },
  {
    value: "9:15 PM",
    label: "9:15 PM",
  },
  {
    value: "9:30 PM",
    label: "9:30 PM",
  },
  {
    value: "9:45 PM",
    label: "9:45 PM",
  },
  {
    value: "10:00 PM",
    label: "10:00 PM",
  },
  {
    value: "10:15 PM",
    label: "10:15 PM",
  },
  {
    value: "10:30 PM",
    label: "10:30 PM",
  },
  {
    value: "10:45 PM",
    label: "10:45 PM",
  },
  {
    value: "11:00 PM",
    label: "11:00 PM",
  },
  {
    value: "11:15 PM",
    label: "11:15 PM",
  },
  {
    value: "11:30 PM",
    label: "11:30 PM",
  },
  {
    value: "11:45 PM",
    label: "11:45 PM",
  },
  {
    value: "12:00 AM",
    label: "12:00 AM",
  },
  {
    value: "12:15 AM",
    label: "12:15 AM",
  },
  {
    value: "12:30 AM",
    label: "12:30 AM",
  },
  {
    value: "12:45 AM",
    label: "12:45 AM",
  },
  {
    value: "1:00 AM",
    label: "1:00 AM",
  },
  {
    value: "1:15 AM",
    label: "1:15 AM",
  },
  {
    value: "1:30 AM",
    label: "1:30 AM",
  },
  {
    value: "1:45 AM",
    label: "1:45 AM",
  },
  {
    value: "2:00 AM",
    label: "2:00 AM",
  },
  {
    value: "2:15 AM",
    label: "2:15 AM",
  },
  {
    value: "2:30 AM",
    label: "2:30 AM",
  },
  {
    value: "2:45 AM",
    label: "2:45 AM",
  },
  {
    value: "3:00 AM",
    label: "3:00 AM",
  },
  {
    value: "3:15 AM",
    label: "3:15 AM",
  },
  {
    value: "3:30 AM",
    label: "3:30 AM",
  },
  {
    value: "3:45 AM",
    label: "3:45 AM",
  },
  {
    value: "4:00 AM",
    label: "4:00 AM",
  },
  {
    value: "4:15 AM",
    label: "4:15 AM",
  },
  {
    value: "4:30 AM",
    label: "4:30 AM",
  },
  {
    value: "4:45 AM",
    label: "4:45 AM",
  },
  {
    value: "5:00 AM",
    label: "5:00 AM",
  },
  {
    value: "5:15 AM",
    label: "5:15 AM",
  },
  {
    value: "5:30 AM",
    label: "5:30 AM",
  },
  {
    value: "5:45 AM",
    label: "5:45 AM",
  },
  {
    value: "6:00 AM",
    label: "6:00 AM",
  },
  {
    value: "6:15 AM",
    label: "6:15 AM",
  },
  {
    value: "6:30 AM",
    label: "6:30 AM",
  },
  {
    value: "6:45 AM",
    label: "6:45 AM",
  },
  {
    value: "7:00 AM",
    label: "7:00 AM",
  },
  {
    value: "7:15 AM",
    label: "7:15 AM",
  },
  {
    value: "7:30 AM",
    label: "7:30 AM",
  },
  {
    value: "7:45 AM",
    label: "7:45 AM",
  },
  {
    value: "8:00 AM",
    label: "8:00 AM",
  },
  {
    value: "8:15 AM",
    label: "8:15 AM",
  },
  {
    value: "8:30 AM",
    label: "8:30 AM",
  },
  {
    value: "8:45 AM",
    label: "8:45 AM",
  },
];
export const recurring_repeat_types = {
  weekly: 1,
  bi_weekly: 5,
  monthly: 2,
  annually: 4,
};
export const recurring_repeat_alert_array = [
  {
    value: 1,
    label: "Weekly",
  },
  {
    value: 5,
    label: "Bi-Weekly",
  },
  {
    value: 2,
    label: "Monthly",
  },
  // {
  //   value: 3,
  //   label: "Quarterly",
  // },
  {
    value: 4,
    label: "Annually",
  },
];

export const recurring_every_other_array = [
  {
    value: 1,
    label: "Monday",
  },
  {
    value: 2,
    label: "Tuesday",
  },
  {
    value: 3,
    label: "Wednesday",
  },
  {
    value: 4,
    label: "Thursday",
  },
  {
    value: 5,
    label: "Friday",
  },
  {
    value: 6,
    label: "Saturday",
  },
  {
    value: 7,
    label: "Sunday",
  },
];

export const recurring_every_other_Bi_Weekly_array = [
  {
    value: 1,
    label: "Other Monday",
  },
  {
    value: 2,
    label: "Other Tuesday",
  },
  {
    value: 3,
    label: "Other Wednesday",
  },
  {
    value: 4,
    label: "Other Thursday",
  },
  {
    value: 5,
    label: "Other Friday",
  },
  {
    value: 6,
    label: "Other Saturday",
  },
  {
    value: 7,
    label: "Other Sunday",
  },
];

export const recurring_at_this_time_array = [
  {
    value: "9:00 AM",
    label: "9:00 AM",
  },
  {
    value: "9:15 AM",
    label: "9:15 AM",
  },
  {
    value: "9:30 AM",
    label: "9:30 AM",
  },
  {
    value: "9:45 AM",
    label: "9:45 AM",
  },
  {
    value: "10:00 AM",
    label: "10:00 AM",
  },
  {
    value: "10:15 AM",
    label: "10:15 AM",
  },
  {
    value: "10:30 AM",
    label: "10:30 AM",
  },
  {
    value: "10:45 AM",
    label: "10:45 AM",
  },
  {
    value: "11:00 AM",
    label: "11:00 AM",
  },
  {
    value: "11:15 AM",
    label: "11:15 AM",
  },
  {
    value: "11:30 AM",
    label: "11:30 AM",
  },
  {
    value: "11:45 AM",
    label: "11:45 AM",
  },
  {
    value: "12:00 PM",
    label: "12:00 PM",
  },
  {
    value: "12:15 PM",
    label: "12:15 PM",
  },
  {
    value: "12:30 PM",
    label: "12:30 PM",
  },
  {
    value: "12:45 PM",
    label: "12:45 PM",
  },
  {
    value: "1:00 PM",
    label: "1:00 PM",
  },
  {
    value: "1:15 PM",
    label: "1:15 PM",
  },
  {
    value: "1:30 PM",
    label: "1:30 PM",
  },
  {
    value: "1:45 PM",
    label: "1:45 PM",
  },
  {
    value: "2:00 PM",
    label: "2:00 PM",
  },
  {
    value: "2:15 PM",
    label: "2:15 PM",
  },
  {
    value: "2:30 PM",
    label: "2:30 PM",
  },
  {
    value: "2:45 PM",
    label: "2:45 PM",
  },
  {
    value: "3:00 PM",
    label: "3:00 PM",
  },
  {
    value: "3:15 PM",
    label: "3:15 PM",
  },
  {
    value: "3:30 PM",
    label: "3:30 PM",
  },
  {
    value: "3:45 PM",
    label: "3:45 PM",
  },
  {
    value: "4:00 PM",
    label: "4:00 PM",
  },
  {
    value: "4:15 PM",
    label: "4:15 PM",
  },
  {
    value: "4:30 PM",
    label: "4:30 PM",
  },
  {
    value: "4:45 PM",
    label: "4:45 PM",
  },
  {
    value: "5:00 PM",
    label: "5:00 PM",
  },
  {
    value: "5:15 PM",
    label: "5:15 PM",
  },
  {
    value: "5:30 PM",
    label: "5:30 PM",
  },
  {
    value: "5:45 PM",
    label: "5:45 PM",
  },
  {
    value: "6:00 PM",
    label: "6:00 PM",
  },
  {
    value: "6:15 PM",
    label: "6:15 PM",
  },
  {
    value: "6:30 PM",
    label: "6:30 PM",
  },
  {
    value: "6:45 PM",
    label: "6:45 PM",
  },
  {
    value: "7:00 PM",
    label: "7:00 PM",
  },
  {
    value: "7:15 PM",
    label: "7:15 PM",
  },
  {
    value: "7:30 PM",
    label: "7:30 PM",
  },
  {
    value: "7:45 PM",
    label: "7:45 PM",
  },
  {
    value: "8:00 PM",
    label: "8:00 PM",
  },
  {
    value: "8:15 PM",
    label: "8:15 PM",
  },
  {
    value: "8:30 PM",
    label: "8:30 PM",
  },
  {
    value: "8:45 PM",
    label: "8:45 PM",
  },
  {
    value: "9:00 PM",
    label: "9:00 PM",
  },
  {
    value: "9:15 PM",
    label: "9:15 PM",
  },
  {
    value: "9:30 PM",
    label: "9:30 PM",
  },
  {
    value: "9:45 PM",
    label: "9:45 PM",
  },
  {
    value: "10:00 PM",
    label: "10:00 PM",
  },
  {
    value: "10:15 PM",
    label: "10:15 PM",
  },
  {
    value: "10:30 PM",
    label: "10:30 PM",
  },
  {
    value: "10:45 PM",
    label: "10:45 PM",
  },
  {
    value: "11:00 PM",
    label: "11:00 PM",
  },
  {
    value: "11:15 PM",
    label: "11:15 PM",
  },
  {
    value: "11:30 PM",
    label: "11:30 PM",
  },
  {
    value: "11:45 PM",
    label: "11:45 PM",
  },
  {
    value: "12:00 AM",
    label: "12:00 AM",
  },
  {
    value: "12:15 AM",
    label: "12:15 AM",
  },
  {
    value: "12:30 AM",
    label: "12:30 AM",
  },
  {
    value: "12:45 AM",
    label: "12:45 AM",
  },
  {
    value: "1:00 AM",
    label: "1:00 AM",
  },
  {
    value: "1:15 AM",
    label: "1:15 AM",
  },
  {
    value: "1:30 AM",
    label: "1:30 AM",
  },
  {
    value: "1:45 AM",
    label: "1:45 AM",
  },
  {
    value: "2:00 AM",
    label: "2:00 AM",
  },
  {
    value: "2:15 AM",
    label: "2:15 AM",
  },
  {
    value: "2:30 AM",
    label: "2:30 AM",
  },
  {
    value: "2:45 AM",
    label: "2:45 AM",
  },
  {
    value: "3:00 AM",
    label: "3:00 AM",
  },
  {
    value: "3:15 AM",
    label: "3:15 AM",
  },
  {
    value: "3:30 AM",
    label: "3:30 AM",
  },
  {
    value: "3:45 AM",
    label: "3:45 AM",
  },
  {
    value: "4:00 AM",
    label: "4:00 AM",
  },
  {
    value: "4:15 AM",
    label: "4:15 AM",
  },
  {
    value: "4:30 AM",
    label: "4:30 AM",
  },
  {
    value: "4:45 AM",
    label: "4:45 AM",
  },
  {
    value: "5:00 AM",
    label: "5:00 AM",
  },
  {
    value: "5:15 AM",
    label: "5:15 AM",
  },
  {
    value: "5:30 AM",
    label: "5:30 AM",
  },
  {
    value: "5:45 AM",
    label: "5:45 AM",
  },
  {
    value: "6:00 AM",
    label: "6:00 AM",
  },
  {
    value: "6:15 AM",
    label: "6:15 AM",
  },
  {
    value: "6:30 AM",
    label: "6:30 AM",
  },
  {
    value: "6:45 AM",
    label: "6:45 AM",
  },
  {
    value: "7:00 AM",
    label: "7:00 AM",
  },
  {
    value: "7:15 AM",
    label: "7:15 AM",
  },
  {
    value: "7:30 AM",
    label: "7:30 AM",
  },
  {
    value: "7:45 AM",
    label: "7:45 AM",
  },
  {
    value: "8:00 AM",
    label: "8:00 AM",
  },
  {
    value: "8:15 AM",
    label: "8:15 AM",
  },
  {
    value: "8:30 AM",
    label: "8:30 AM",
  },
  {
    value: "8:45 AM",
    label: "8:45 AM",
  },
];

export const alert_link_type_array = [
  {
    value: 30,
    label: "Internal Link",
  },
  {
    value: 20,
    label: "External Link",
  },
  {
    value: 10,
    label: "No Link",
  },
];

export const alert_category_array = [
  {
    value: 20,
    label: "Events",
  },
  {
    value: 10,
    label: "Resources",
  },
  {
    value: 50,
    label: "Documents",
  },
  {
    value: 70,
    label: "Flashcards",
  },
  {
    value: 60,
    label: "Surveys",
  },
  {
    value: 80,
    label: "Quizzes",
  },
];

export const alert_content_array = [
  {
    value: "Employee Contract",
    label: "Employee Contract",
  },
  {
    value: "COVID-19 Policy",
    label: "COVID-19 Policy",
  },
  {
    value: "Dress Code Policy",
    label: "Dress Code Policy",
  },
  {
    value: "Employee Handbook",
    label: "Employee Handbook",
  },
];

export const currentBookingList = [
  "1-10 People",
  "11-30 People",
  "31-60 People",
  "61-100 People",
];

export const primaryProductList = [
  "Aveda",
  "Amika",
  "Biolage",
  "Brazilian Blowout",
  "Bumble & Bumble",
  "Davines",
  "Deva Curl",
  "Drybar",
  "Goldwell",
  "Joico",
  "K18",
  "Kerastase",
  "Kevin Murphy",
  "L’Oreal Professional",
  "Living Proof",
  "Matrix",
  "Moroccanoil",
  "Olaplex",
  "Oribe",
  "Paul Mitchell",
  "Pulp Riot",
  "Pureology",
  "R&Co",
  "Redken",
  "Reuzel",
  "Schwarzkopf",
  "Ultraceuticals",
  "Wella Professional",
  "Other",
];

export const event_timezone = [
  {
    value: "EST",
    label: "Eastern Time (EST)",
  },
  {
    value: "CST",
    label: "Central Time (CST)",
  },
  {
    value: "MST",
    label: "Mountain Time (MST)",
  },
  {
    value: "PST",
    label: "Pacific Time (PST)",
  },
];

export const target_type_array = (val) => [
  {
    key: "1",
    value: target_types?.dollar,
    label: (
      <>
        <div className="resourceSelctLabel d-flex align-items-center justify-content-between w-100">
          <div className="textWrapper d-flex flex-column">
            <h4 className="black dollar">Dollar Amount</h4>
            <p className="grayLight dollar-p">
              Any monetary value, such as $13.50.
            </p>
          </div>
          <span
            className={`roundedIcon ${
              val == target_types?.dollar ? "check" : ""
            }`}
          ></span>
        </div>
      </>
    ),
  },
  {
    key: "2",
    value: target_types?.percentage,
    label: (
      <>
        <div className="resourceSelctLabel d-flex align-items-center justify-content-between w-100">
          <div className="textWrapper d-flex flex-column">
            <h4 className="black dollar">Percentage</h4>
            <p className="grayLight dollar-p">
              Any percentage value, such as 80%.
            </p>
          </div>
          <span
            className={`roundedIcon ${
              val == target_types?.percentage ? "check" : ""
            }`}
          ></span>
        </div>
      </>
    ),
  },
  {
    key: "3",
    value: target_types?.number,
    label: (
      <>
        <div className="resourceSelctLabel d-flex align-items-center justify-content-between w-100">
          <div className="textWrapper d-flex flex-column">
            <h4 className="black dollar">Numerical</h4>
            <p className="grayLight dollar-p">Any number value, such as 100.</p>
          </div>
          <span
            className={`roundedIcon ${
              val == target_types?.number ? "check" : ""
            }`}
          ></span>
        </div>
      </>
    ),
  },
];

export const target_date_weekly_array = (val) => [
  {
    key: "1",
    value: 1,
    label: (
      <>
        <div className="resourceSelctLabel d-flex align-items-center justify-content-between w-100">
          <div className="textWrapper d-flex flex-column">
            <h4 className="black ">Every Monday</h4>
          </div>
          <span className={`roundedIcon ${val == 1 ? "check" : ""}`}></span>
        </div>
      </>
    ),
  },
  {
    key: "2",
    value: 2,
    label: (
      <>
        <div className="resourceSelctLabel d-flex align-items-center justify-content-between w-100">
          <div className="textWrapper d-flex flex-column">
            <h4 className="black">Every Tuesday</h4>
          </div>
          <span className={`roundedIcon ${val == 2 ? "check" : ""}`}></span>
        </div>
      </>
    ),
  },
  {
    key: "3",
    value: 3,
    label: (
      <>
        <div className="resourceSelctLabel d-flex align-items-center justify-content-between w-100">
          <div className="textWrapper d-flex flex-column">
            <h4 className="black">Every Wednesday</h4>
          </div>
          <span className={`roundedIcon ${val == 3 ? "check" : ""}`}></span>
        </div>
      </>
    ),
  },
  {
    key: "4",
    value: 4,
    label: (
      <>
        <div className="resourceSelctLabel d-flex align-items-center justify-content-between w-100">
          <div className="textWrapper d-flex flex-column">
            <h4 className="black">Every Thursday</h4>
          </div>
          <span className={`roundedIcon ${val == 4 ? "check" : ""}`}></span>
        </div>
      </>
    ),
  },
  {
    key: "5",
    value: 5,
    label: (
      <>
        <div className="resourceSelctLabel d-flex align-items-center justify-content-between w-100">
          <div className="textWrapper d-flex flex-column">
            <h4 className="black">Every Friday</h4>
          </div>
          <span className={`roundedIcon ${val == 5 ? "check" : ""}`}></span>
        </div>
      </>
    ),
  },
  {
    key: "6",
    value: 6,
    label: (
      <>
        <div className="resourceSelctLabel d-flex align-items-center justify-content-between w-100">
          <div className="textWrapper d-flex flex-column">
            <h4 className="black">Every Saturday</h4>
          </div>
          <span className={`roundedIcon ${val == 6 ? "check" : ""}`}></span>
        </div>
      </>
    ),
  },
  {
    key: "7",
    value: 7,
    label: (
      <>
        <div className="resourceSelctLabel d-flex align-items-center justify-content-between w-100">
          <div className="textWrapper d-flex flex-column">
            <h4 className="black">Every Sunday</h4>
          </div>
          <span className={`roundedIcon ${val == 7 ? "check" : ""}`}></span>
        </div>
      </>
    ),
  },
];

export const target_date_monthly_array = (val) => {
  const res = [];
  for (let day = 1; day <= 31; day++) {
    if (day <= 28) {
      res.push({
        key: day,
        value: day,
        label: (
          <div className="resourceSelctLabel d-flex align-items-center justify-content-between w-100">
            <div className="textWrapper d-flex flex-column">
              <h4 className="black">{`${day}${getOrdinalSuffix(
                day,
              )} of every month`}</h4>
            </div>
            <span className={`roundedIcon ${val == day ? "check" : ""}`}></span>
          </div>
        ),
      });
    } else if (day == 31) {
      res.push({
        key: day,
        value: day,
        label: (
          <div className="resourceSelctLabel d-flex align-items-center justify-content-between w-100">
            <div className="textWrapper d-flex flex-column">
              <h4 className="black">Last of the Month</h4>
            </div>
            <span className={`roundedIcon ${val == day ? "check" : ""}`}></span>
          </div>
        ),
      });
    }
  }

  return res;
};

function getOrdinalSuffix(day) {
  if (day >= 11 && day <= 13) {
    return "th";
  }

  switch (day % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
}

// export const target_date_monthly_array = (val) => ([
//     {
//         key: "1",
//         value: 1,
//         label: (
//             <>
//                 <div className="resourceSelctLabel d-flex align-items-center justify-content-between w-100">
//                     <div className="textWrapper d-flex flex-column">
//                         <h4 className="black">1st of every month</h4>
//                     </div>
//                     <span className={`roundedIcon ${val == 1 ? "check" : ""}`}></span>
//                 </div>
//             </>
//         ),
//     },
//     {
//         key: "2",
//         value: 2,
//         label: (
//             <>
//                 <div className="resourceSelctLabel d-flex align-items-center justify-content-between w-100">
//                     <div className="textWrapper d-flex flex-column">
//                         <h4 className="black">2nd of every month</h4>

//                     </div>
//                     <span className={`roundedIcon ${val == 2 ? "check" : ""}`}></span>
//                 </div>
//             </>
//         ),
//     },
//     {
//         key: "3",
//         value: 3,
//         label: (
//             <>
//                 <div className="resourceSelctLabel d-flex align-items-center justify-content-between w-100">
//                     <div className="textWrapper d-flex flex-column">
//                         <h4 className="black">3rd of every month</h4>

//                     </div>
//                     <span className={`roundedIcon ${val == 3 ? "check" : ""}`}></span>
//                 </div>
//             </>
//         ),
//     },
//     {
//         key: "4",
//         value: 4,
//         label: (
//             <>
//                 <div className="resourceSelctLabel d-flex align-items-center justify-content-between w-100">
//                     <div className="textWrapper d-flex flex-column">
//                         <h4 className="black">4th of every month</h4>

//                     </div>
//                     <span className={`roundedIcon ${val == 4 ? "check" : ""}`}></span>
//                 </div>
//             </>
//         ),
//     },
//     {
//         key: "5",
//         value: 5,
//         label: (
//             <>
//                 <div className="resourceSelctLabel d-flex align-items-center justify-content-between w-100">
//                     <div className="textWrapper d-flex flex-column">
//                         <h4 className="black">5th of every month</h4>

//                     </div>
//                     <span className={`roundedIcon ${val == 5 ? "check" : ""}`}></span>
//                 </div>
//             </>
//         ),
//     },
//     {
//         key: "6",
//         value: 6,
//         label: (
//             <>
//                 <div className="resourceSelctLabel d-flex align-items-center justify-content-between w-100">
//                     <div className="textWrapper d-flex flex-column">
//                         <h4 className="black">6th of every month</h4>

//                     </div>
//                     <span className={`roundedIcon ${val == 6 ? "check" : ""}`}></span>
//                 </div>
//             </>
//         ),
//     },
//     {
//         key: "7",
//         value: 7,
//         label: (
//             <>
//                 <div className="resourceSelctLabel d-flex align-items-center justify-content-between w-100">
//                     <div className="textWrapper d-flex flex-column">
//                         <h4 className="black">7th of every month</h4>

//                     </div>
//                     <span className={`roundedIcon ${val == 7 ? "check" : ""}`}></span>
//                 </div>
//             </>
//         ),
//     },
// ])

export const repeat_goal_weekly_array = (val) => [
  {
    key: "1",
    value: 20,
    label: (
      <>
        <div className="resourceSelctLabel for1month d-flex align-items-center justify-content-between w-100">
          <div className="textWrapper d-flex flex-column">
            <h4 className="black">For 1 Month</h4>
            <p className="grayLight">Goal repeats every week for 1 month.</p>
          </div>
          <span className={`roundedIcon ${val == 20 ? "check" : ""}`}></span>
        </div>
      </>
    ),
  },
  {
    key: "2",
    value: 30,
    label: (
      <>
        <div className="resourceSelctLabel d-flex align-items-center justify-content-between w-100">
          <div className="textWrapper d-flex flex-column">
            <h4 className="black">For 3 Months</h4>
            <p className="grayLight">Goal repeats every week for 3 months.</p>
          </div>
          <span className={`roundedIcon ${val == 30 ? "check" : ""}`}></span>
        </div>
      </>
    ),
  },
  {
    key: "3",
    value: 40,
    label: (
      <>
        <div className="resourceSelctLabel d-flex align-items-center justify-content-between w-100">
          <div className="textWrapper d-flex flex-column">
            <h4 className="black">For 6 Months</h4>
            <p className="grayLight">Goal repeats every week for 6 months.</p>
          </div>
          <span className={`roundedIcon ${val == 40 ? "check" : ""}`}></span>
        </div>
      </>
    ),
  },
  {
    key: "4",
    value: 10,
    label: (
      <>
        <div className="resourceSelctLabel d-flex align-items-center justify-content-between w-100">
          <div className="textWrapper d-flex flex-column">
            <h4 className="black">Forever</h4>
            <p className="grayLight">Repeats goal indefinitely.</p>
          </div>
          <span className={`roundedIcon ${val == 10 ? "check" : ""}`}></span>
        </div>
      </>
    ),
  },
];

export const repeat_goal_monthly_array = (val) => [
  {
    key: "2",
    value: 30,
    label: (
      <>
        <div className="resourceSelctLabel d-flex align-items-center justify-content-between w-100">
          <div className="textWrapper d-flex flex-column">
            <h4 className="black">For 3 Months</h4>
            <p className="grayLight">Goal repeats every month for 3 months.</p>
          </div>
          <span className={`roundedIcon ${val == 30 ? "check" : ""}`}></span>
        </div>
      </>
    ),
  },
  {
    key: "3",
    value: 40,
    label: (
      <>
        <div className="resourceSelctLabel d-flex align-items-center justify-content-between w-100">
          <div className="textWrapper d-flex flex-column">
            <h4 className="black">For 6 Months</h4>
            <p className="grayLight">Goal repeats every month for 6 months.</p>
          </div>
          <span className={`roundedIcon ${val == 40 ? "check" : ""}`}></span>
        </div>
      </>
    ),
  },
  {
    key: "3",
    value: 50,
    label: (
      <>
        <div className="resourceSelctLabel d-flex align-items-center justify-content-between w-100">
          <div className="textWrapper d-flex flex-column">
            <h4 className="black">For 9 Months</h4>
            <p className="grayLight">Goal repeats every month for 9 months.</p>
          </div>
          <span className={`roundedIcon ${val == 50 ? "check" : ""}`}></span>
        </div>
      </>
    ),
  },
  {
    key: "4",
    value: 10,
    label: (
      <>
        <div className="resourceSelctLabel d-flex align-items-center justify-content-between w-100">
          <div className="textWrapper d-flex flex-column">
            <h4 className="black">Forever</h4>
            <p className="grayLight">Repeats goal indefinitely.</p>
          </div>
          <span className={`roundedIcon ${val == 10 ? "check" : ""}`}></span>
        </div>
      </>
    ),
  },
];

export const gallery_type_array = [
  {
    label: "All Media",
    value: null,
  },
  {
    label: "Images",
    value: 10,
  },
  {
    label: "Videos",
    value: 20,
  },
  {
    label: "Audios",
    value: 30,
  },
  {
    label: "Folders",
    value: 40,
  },
];

export const recurring_every_other_monthly_array = [
  {
    value: 1,
    label: "1st of the Month",
  },
  {
    value: 2,
    label: "2nd of the Month",
  },
  {
    value: 3,
    label: "3rd of the Month",
  },
  {
    value: 4,
    label: "4th of the Month",
  },
  {
    value: 5,
    label: "5th of the Month",
  },
  {
    value: 6,
    label: "6th of the Month",
  },
  {
    value: 7,
    label: "7th of the Month",
  },
  {
    value: 8,
    label: "8th of the Month",
  },
  {
    value: 9,
    label: "9th of the Month",
  },
  {
    value: 10,
    label: "10th of the Month",
  },
  {
    value: 11,
    label: "11th of the Month",
  },
  {
    value: 12,
    label: "12th of the Month",
  },
  {
    value: 13,
    label: "13th of the Month",
  },
  {
    value: 14,
    label: "14th of the Month",
  },
  {
    value: 15,
    label: "15th of the Month",
  },
  {
    value: 16,
    label: "16th of the Month",
  },
  {
    value: 17,
    label: "17th of the Month",
  },
  {
    value: 18,
    label: "18th of the Month",
  },
  {
    value: 19,
    label: "19th of the Month",
  },
  {
    value: 20,
    label: "20th of the Month",
  },
  {
    value: 21,
    label: "21st of the Month",
  },
  {
    value: 22,
    label: "22nd of the Month",
  },
  {
    value: 23,
    label: "23rd of the Month",
  },
  {
    value: 24,
    label: "24th of the Month",
  },
  {
    value: 25,
    label: "25th of the Month",
  },
  {
    value: 26,
    label: "26th of the Month",
  },
  {
    value: 27,
    label: "27th of the Month",
  },
  {
    value: 28,
    label: "28th of the Month",
  },
  {
    value: 31,
    label: "Last of the Month",
  },
  // {
  //   value: 29,
  //   label: "29th of the Month",
  // },
  // {
  //   value: 30,
  //   label: "30th of the Month",
  // },
  // {
  //   value: 31,
  //   label: "31st of the Month",
  // }
];

export const apiUrl = {
  register: "/register",
  login: "/login",
  forgot_password: "/forgot-password",
  resend_otp: "/resend-otp",
  verify_otp: "/verify-otp",
  reset_password: "/reset-password",
  stats: "/stats",
  user_requests: "/user-requests",
  activities: "/activities",
  positions: "/positions/list",
  alerts: "/alerts",
  events: "/events",
  categories: "/categories",
  survey: "/surveys",
  surveyQuestion: "/survey-questions-answers",
  quiz: "/quizzes",
  quizQuestion: "/quiz-questions-answers",
  salon: "/salons",
  salonSegments: "/salon-segments",
  salons: {
    get_team_by_id: "/salon-team/",
  },
  newHireCheckList: "/onboarding-tasks",
  document: "/salon-documents",
  candidateReferral: "/user-referrals",
  goals: "/goals",
  userGoals: "/user-goals",
  flashcards: "/flashcards",
  flashcardBuilder: "/flashcard-details",
  tasks: "/tasks",
  salon_locations: "/salon-locations",
  change_password: "/change-password",
  me: "/me",
  firebase_token: "create-token",
  users: "/users",
  galleries: "/galleries",
  logout_user: "logout-user",
  salon_teams: "/salon-teams",
  salon_resources: "/salon-resources",
  location_add_users: "/location-add-users",
  location_remove_user: "/location-remove-user",
  salon_check_storage_available: "/salon-storage-size",
  categoryTags: "category-tags",
  search: "/search",
  user_search_histories: "/user-search-histories",
  clear_search_histories: "/clear-search-histories",
  groups: "/groups",
  delete_activities: "/delete-activities",
  cleared_activities: "/cleared-activities",
  user_activity_badges: "/user-activity-badges",
  shoutouts: "/shoutouts",
  salon_shoutouts: "/salon-shoutouts",
  constants: "/constants",
  user_activity_points: "/user-activity-points",
  payment_link: "/payment-link",
  billing_plans: "/billing-plans",
  current_subscription: "/get-current-subscription",
  cancel_subscription: "/cancel-subscription",
  group_add_users: "/group-add-users",
  group_remove_user: "/group-remove-user",
  userSchedule: "/user-schedule",
  userRole: "/user-role",
  candidateReferralApprove: "/user-referral-approve",
  userSchedules: "/user-schedules",
  salonAchievements: "/salon-achievements",
  storage_plans: "/storage-plans",
  storage_payment_link: "/storage-payment-link",
  inviteUser: "/user-invitations",
  resendInvitation: "/user-invitations-resend",
  ticket: "/tickets",
  checkTitle: "/check-title",
  checkUserValidation: "/check-user-validation",
};

export const errorMessages = {
  fileSize: "The file size is too large",
  fileSuccess: "file uploaded successfully",
  fileError: "Error in uploading file",
};

export const PAYMENT_METHOD_VALUE = {
  CASH: "10",
  CHECK: "20",
  CREDIT_CARD: "30",
  MOBILE_PAYMENT: "40",
};
export const PAYMENT_METHOD = {
  [PAYMENT_METHOD_VALUE.CASH]: "Cash",
  [PAYMENT_METHOD_VALUE.CHECK]: "Check",
  [PAYMENT_METHOD_VALUE.CREDIT_CARD]: "Credit Card",
  [PAYMENT_METHOD_VALUE.MOBILE_PAYMENT]: "Mobile Payment",
};

export const ACTIVITY_TYPE = {
  RESOURCES: "10",
  EVENTS: "20",
  TASKS: "30",
  GOALS: "40",
  DOCUMENTS: "50",
  SURVEYS: "60",
  FLASHCARDS: "70",
  QUIZZES: "80",
  MEMBERS: "90",
  ALERTS: "100",
  REQUESTS: "110",
  ONBOARDING: "120",
  USER_REFERRAL: "130",
  SALONS: "140",
  USER_REWARD: "150",
  SHOUTOUT: "160",
};
export const SHARE_URL = {
  RESOURCES: window.location.origin + "/resources/details/",
  EVENTS: window.location.origin + "/events/details/",
  GOALS: window.location.origin + "/goals/details/",
  FLASHCARDS: window.location.origin + "/flashcards/details/",
  TASKS: window.location.origin + "/tasks/details/",
  SURVEYS: window.location.origin + "/surveys/details/",
  QUIZZES: window.location.origin + "/quizzes/details/",
  MEMBERS: window.location.origin + "/team/members/",
  ONBOARDING: window.location.origin + "/team/onboarding/details/",
  CANDIDATE: window.location.origin + "/team/onboarding/details/",
  DOCS: window.location.origin + "/team/docs/details/",
  GALLERY: window.location.origin + "/settings/uploads/details/",
  REQUESTS: window.location.origin + "/requests/details/",
};

export const SOFTWARE = [
  "Acuity",
  "Aura",
  "BookedBy",
  "Booksy",
  "Boulevard",
  "DaySmart",
  "EnvisionNow",
  "Fresha",
  "GlossGenius",
  "Mangomint",
  "Meevo",
  "MindBody",
  "Phorest",
  "Rosy",
  "SalonBiz",
  "Salonist",
  "SalonUltimate",
  "Square",
  "Vagaro",
  "Zenoti",
  "Other/None",
];

export const TECHNOLOGY = [
  "Canvas ME",
  "Clics",
  "Jon David Tech",
  "Inovshop",
  "MYA",
  "PoseCam/AfterPose",
  "SalonCloud",
  "SalonInteractive",
  "SalonScale",
  "Textel",
  "Tippy",
  "Vish",
  "Other/None",
];
