import React, { useState, useEffect } from "react";
import { setCategoryListByModule } from "../../store/slices/globalSlice";
import FlashCardHeader from "../../components/pages/FlashCardsPage/FlashCardHeader";
import FlashCardBody from "../../components/pages/FlashCardsPage/FlashCardBody";
import { useDispatch } from "react-redux";
import { all_category_type } from "../../utils/constants";

const FlashCardsPage = () => {
  const dispatch = useDispatch();
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [page, setPage] = useState({ currentPage: 1, perPage: 10 });

  const handleResetPage = () => {
    setPage({ currentPage: 1, perPage: 10 });
  };

  useEffect(() => {
    dispatch(setCategoryListByModule(all_category_type?.RFQ));
  }, []);

  return (
    <>
      <div className="innerContainer">
        <FlashCardHeader
          setSelectedCategory={setSelectedCategory}
          handleResetPage={handleResetPage}
        />

        <FlashCardBody
          selectedCategory={selectedCategory}
          page={page}
          setPage={setPage}
        />
      </div>
    </>
  );
};

export default FlashCardsPage;
