import { Select, notification } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ArrowTriangleDown,
  CheckMarkIcon,
  CreateIcon,
  ToastCrossIcon,
  ToastRedTrashIcon,
} from "../../../constants/svg";
import { GetCategories } from "../../../services/categories/categories";
import { GetAuthUserLocalStorage } from "../../../services/localStorage/localStorage";
import {
  setCategories,
  setIsCategoriesLoading,
} from "../../../store/slices/categoriesSlice";
import {
  setFlashCardAddEditForm,
  setIsFlashcardsLoading,
} from "../../../store/slices/flashcardSlice";
import { setCurrentSalonID } from "../../../store/slices/globalSlice";
import {
  all_category_type,
  roles as salonRoles,
  status,
} from "../../../utils/constants";
import GenericModal from "../../Modals/GenericModal";
import FlashcardModalForm from "./FlashcardModal/FlashcardModalForm";

const FlashCardHeader = (props) => {
  const [showGenericModal, setShowGenericModal] = useState(false);
  const { categories } = useSelector((state) => state?.categories);
  const { allSalons, currentSalonId } = useSelector((state) => state.global);
  const dispatch = useDispatch();
  const { flashCardAddEditForm } = useSelector((state) => state.flashCard);

  const handleChange = (value) => {
    props?.handleResetPage();
    props?.setSelectedCategory(value);
    dispatch(setIsFlashcardsLoading(true));
  };
  const getCategories = async () => {
    try {
      const params = {
        is_paginated: 0,
        status: status?.active,
        type: all_category_type?.RFQ,
      };
      const res = await GetCategories(params);
      const data = res?.data?.data?.map((item) => ({
        label: item?.name,
        value: item?.id,
      }));

      dispatch(setCategories({ data, meta: null }));
      dispatch(setIsCategoriesLoading(false));
    } catch (e) {
      notification.error({
        message: <span>{e.message}</span>,
        placement: "bottomRight",
        className: "createdNotification",
        icon: <ToastRedTrashIcon />,
        closeIcon: <ToastCrossIcon />,
      });
      dispatch(setIsCategoriesLoading(false));
    }
  };

  useEffect(() => {
    getCategories();
  }, []);

  return (
    <>
      <div className="globalHeader flashcardheader">
        <div className="globalHeader--left">
          <h2 className="sec-heading">Flashcards</h2>
        </div>
        <div className="globalHeader--right">
          {GetAuthUserLocalStorage().roles[0].id == salonRoles.super_admin && (
            <Select
              value={currentSalonId || undefined}
              defaultValue="All Organizations"
              suffixIcon={<ArrowTriangleDown />}
              menuItemSelectedIcon={<CheckMarkIcon />}
              onChange={(e) => {
                dispatch(setCurrentSalonID(e));
              }}
              popupClassName="main-select pageDropdown header-dropdown me-3"
              className="custom-select-box border"
              options={allSalons.map((elem) => {
                return { value: elem.id, label: elem.name };
              })}
              getPopupContainer={() => document.getElementById("area")}
            />
          )}
          <Select
            defaultValue="All Categories"
            suffixIcon={<ArrowTriangleDown />}
            menuItemSelectedIcon={<CheckMarkIcon />}
            // open={true}
            popupClassName="main-select pageDropdown header-dropdown me-3"
            className="custom-select-box border"
            options={categories?.data}
            onChange={(e) => handleChange(e)}
          ></Select>
          <button
            className="btn-gradient"
            onClick={() =>
              dispatch(
                setFlashCardAddEditForm({
                  show: true,
                  formType: "create",
                }),
              )
            }
          >
            <span>
              <CreateIcon />
            </span>
            Create Set
          </button>
        </div>
      </div>
      {flashCardAddEditForm.show && (
        <GenericModal
          show={flashCardAddEditForm.show}
          onHide={() => {
            dispatch(
              setFlashCardAddEditForm({
                show: false,
                formType: null,
              }),
            );
          }}
          setModalShow={(e) => {
            dispatch(
              setFlashCardAddEditForm({
                show: e,
                formType: null,
              }),
            );
          }}
          customClassName="new-flashcard-set"
          modalTitle={
            flashCardAddEditForm.formType == "create"
              ? "New Flashcard Set"
              : "Edit Flashcard Set"
          }
          customModalHeader={"customFlashcardSetModal"}
          size={"lg"}
          bodyComponent={
            <FlashcardModalForm customModalBody={"customFlashcardSetBody"} />
          }
        />
      )}
    </>
  );
};

export default FlashCardHeader;
