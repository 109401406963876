import { notification } from "antd";
import {
  setAllUsers,
  setChannelMessages,
  setChatUsers,
  setIsAllUsersLoading,
  setIsChannelMessagesLoading,
  setIsChatUsersLoading,
  setSelectedUser,
} from "../../store/slices/chatsSlice";
import {
  apiUrl,
  collection_ref,
  constant,
  message_type,
} from "../../utils/constants";
import { signInWithCustomToken } from "firebase/auth";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  serverTimestamp,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { ToastCrossIcon } from "../../constants/svg";
import { auth, db } from "../../firebase";
import {
  GetAuthUserLocalStorage,
  GetTokenLocalStorage,
} from "../localStorage/localStorage";

let authUser = GetAuthUserLocalStorage();
let subscribed = null;

export const getAllUsers = async (dispatch, keyword = null, salon_id) => {
  salon_id =
    typeof authUser?.salon_id != "undefined" ? authUser?.salon_id : salon_id;

  if (typeof salon_id != "undefined") {
    try {
      dispatch(setIsAllUsersLoading(true));
      const usersCollectionRef = collection(db, collection_ref?.users);
      let userquerySnapshot;
      if (keyword && keyword.length > 3) {
        let queryData = query(
          usersCollectionRef,
          where("salon_id", "==", salon_id),
          where("name", "==", keyword),
        );
        userquerySnapshot = await getDocs(queryData);
      } else {
        let queryData = query(
          usersCollectionRef,
          where("salon_id", "==", salon_id),
        );
        userquerySnapshot = await getDocs(queryData);
      }

      const users = [];
      for (let j = 0; j < userquerySnapshot?.docs?.length; j++) {
        const doc = userquerySnapshot?.docs[j];
        const userData = doc.data();
        userData.id = doc.id;
        users.push(userData);
      }
      dispatch(setAllUsers(users));
      dispatch(setIsAllUsersLoading(false));
    } catch (e) {
      notification.error({
        description: e.message,
        placement: "bottomRight",
        className: "createdNotification",
        closeIcon: <ToastCrossIcon />,
      });
      dispatch(setIsAllUsersLoading(false));
    }
  }
};

export const getFireBaseUserByID = async (id) => {
  const usersRef = collection(db, collection_ref?.users);
  const q = query(usersRef, where("id", "==", id.toString()));
  const querySnapshot = await getDocs(q);
  if (!querySnapshot.empty) {
    const userDoc = querySnapshot.docs[0];
    return userDoc.data();
  }
  return false;
};
export const updateFirebaseUser = async (user = null) => {
  let auth_id =
    typeof authUser?.id == "undefined"
      ? user?.id.toString()
      : authUser?.id.toString();
  const userDocRef = doc(db, collection_ref?.users, auth_id);
  const userData = (await getDoc(userDocRef)).data();
  if (!userData) {
    const usersCollection = doc(db, collection_ref?.users, auth_id);
    await setDoc(usersCollection, {
      name: authUser?.name,
      image: authUser?.image ? authUser?.image : "",
      online: true,
      id: authUser?.id.toString(),
      email: authUser?.email,
      position: authUser?.position_id,
    });
  } else {
    userData.online = true;
    if (user) {
      userData.name = user?.name;
      userData.image = user?.image ? user?.image : "";
      userData.position = user?.position_id;
    }
    await updateDoc(userDocRef, userData);
  }
};
export const updateOrganizationFirebase = async (user = null) => {
  const userDocRef = doc(db, collection_ref?.users, user.id);
  const userData = (await getDoc(userDocRef)).data();
  if (!userData) {
    const usersCollection = doc(db, collection_ref?.users, user.id);
    await setDoc(usersCollection, {
      name: user?.name,
      image: user?.image ? user?.image : "",
      online: false,
      id: user?.id.toString(),
      email: user?.email,
      position: user?.position_id,
    });
  } else {
    if (user) {
      userData.name = user?.name;
      userData.image = user?.image ? user?.image : "";
      userData.position = user?.position_id;
    }
    await updateDoc(userDocRef, userData);
  }
};
export const getChatUserList = async (dispatch, auth_id) => {
  try {
    dispatch(setIsChatUsersLoading(true));
    const usersCollectionRef = collection(db, collection_ref?.users);
    const usersQuery = query(usersCollectionRef);
    const chatsCollectionRef = collection(db, collection_ref?.chats);
    const chatsQuery = query(
      chatsCollectionRef,
      where("users_ids", "array-contains", auth_id),
    );
    // fetch all users from collection
    // const userquerySnapshot = await getDocs(usersCollectionRef)
    const userquerySnapshot = await onSnapshot(
      usersQuery,
      async (usersQuerySnapshot) => {
        await onSnapshot(chatsQuery, async (querySnapshot) => {
          const chatQuery = query(
            chatsCollectionRef,
            where("users_ids", "array-contains", auth_id),
            orderBy("updated_at", "desc"),
          );
          const chatData = await getDocs(chatQuery);
          const chats = [];
          chatData?.docs?.forEach((doc) => {
            const chatData = doc.data();
            chatData.chat_id = doc.id;
            chatData.is_user_group = chatData?.is_user_group
              ? chatData.is_user_group
              : false;
            let [isDeleted] = chatData
              ? chatData.deleted_statuses.filter((item) => {
                  return item.id == auth_id;
                })
              : [];
            isDeleted = isDeleted?.is_deleted;
            if (!isDeleted) {
              chats.push(chatData);
            }
          });
          const users = [];
          usersQuerySnapshot.forEach((doc) => {
            const userData = doc.data();
            userData.id = doc.id;
            users.push(userData);
          });

          let profiles = [];

          // compare chats users_ids with users data and add relevent user as receiver_profile
          for (let i = 0; i < chats.length; i++) {
            for (let j = 0; j < chats[i].users_ids.length; j++) {
              if (chats[i].users_ids[j] != auth_id) {
                const [receiver_profile] = users
                  ? users.filter(
                      (item) => Number(item?.id) == chats[i].users_ids[j],
                    )
                  : [];
                profiles.push(receiver_profile);
                chats[i].receiver_profile = profiles;
                chats[i].created_at =
                  chats[i].created_at?.seconds || chats[i].created_at;
                chats[i].updated_at =
                  chats[i].updated_at?.seconds || chats[i].updated_at;
              }
            }
            profiles = [];
          }

          dispatch(setChatUsers(chats));
        });
      },
    );
    dispatch(setIsChatUsersLoading(false));
  } catch (e) {
    notification.error({
      description: e.message,
      placement: "bottomRight",
      className: "createdNotification",
      closeIcon: <ToastCrossIcon />,
    });
    dispatch(setIsChatUsersLoading(false));
  }
};
export const getUnreadCount = async (setMessageCount) => {
  authUser = GetAuthUserLocalStorage();
  if (authUser?.id) {
    const chatsCollectionRef = collection(db, collection_ref?.chats);
    const chatsQuery = query(
      chatsCollectionRef,
      where("users_ids", "array-contains", authUser?.id),
    );

    await onSnapshot(chatsQuery, async (querySnapshot) => {
      let unreadCount = 0;
      querySnapshot.forEach((doc) => {
        const chatData = doc.data();
        chatData.chat_id = doc.id;
        const filteredData = chatData?.read_statuses?.filter(
          (item) => item.id == authUser?.id,
        );
        if (filteredData.length > 0 && !filteredData[0].is_read) {
          ++unreadCount;
        }
      });
      setMessageCount(unreadCount);
    });
  }
};
export const getChannelMessages = async (
  selectedUser,
  unsubscribe,
  dispatch,
  auth_id = null,
) => {
  auth_id = typeof authUser?.id == "undefined" ? auth_id : authUser?.id;
  if (subscribed) {
    subscribed();
  }
  try {
    dispatch(setChannelMessages([]));
    // check if current user deleted messages
    const [currUserDeletedStatuses] =
      selectedUser && selectedUser.deleted_statuses
        ? selectedUser.deleted_statuses.filter((item) => item?.id == auth_id)
        : [];
    const isCurrentUserDeletedMsgs = currUserDeletedStatuses?.is_deleted;
    // Find the id of last message deleted by current user
    const [currUserDeletedMsgsIds] =
      selectedUser && selectedUser.deleted_messages_ids
        ? selectedUser.deleted_messages_ids.filter(
            (item) => item?.id == auth_id,
          )
        : "";
    const lastMsgId = currUserDeletedMsgsIds?.deleted_message_id;
    // if current user deleted messages than find the info of the deleted messages
    if (lastMsgId != "") {
      // finding the details of last messages deleted by current user
      dispatch(setIsChannelMessagesLoading(true));
      dispatch(setChannelMessages([]));

      /* const chatsDocRef = doc(db, collection_ref?.chats, selectedUser?.chat_id)
            const threadsCollectionRef = collection(chatsDocRef, collection_ref?.threads)
            const threadDocRef = doc(threadsCollectionRef, lastMsgId)
            const threadSnapshot = await getDoc(threadDocRef)
            const threadData = threadSnapshot.data() */

      // find all docs where created_at is > then created_at of last messages deleted by current user
      const messagesRef = collection(
        doc(db, collection_ref?.chats, selectedUser?.chat_id),
        collection_ref?.threads,
      );
      const sortedMessagesQuery = query(messagesRef, orderBy("created_at"));

      subscribed = onSnapshot(sortedMessagesQuery, (querySnapshot) => {
        const data = querySnapshot.docs.map((doc) => {
          const dt = doc.data();
          dt.thread_id = doc.id;
          return dt;
        });
        let index = 0;
        for (let i = 0; i < data.length; i++) {
          if (data[i]?.thread_id == lastMsgId) {
            index = i;
          }
        }
        //                const index = data?.findIndex((item) => item?.thread_id == lastMsgId)
        const data_after_deleted = data?.slice(index + 1);

        dispatch(setChannelMessages(data_after_deleted));
        dispatch(setIsChannelMessagesLoading(false));
      });
    } else {
      // find all chat of current user
      dispatch(setIsChannelMessagesLoading(true));
      dispatch(setChannelMessages([]));

      const messagesRef = collection(
        doc(db, collection_ref?.chats, selectedUser?.chat_id),
        collection_ref?.threads,
      );
      const sortedMessagesQuery = query(messagesRef, orderBy("created_at"));

      subscribed = onSnapshot(sortedMessagesQuery, (querySnapshot) => {
        const data = querySnapshot.docs.map((doc) => {
          const dt = doc.data();
          dt.thread_id = doc.id;
          return dt;
        });
        dispatch(setChannelMessages(data));
        dispatch(setIsChannelMessagesLoading(false));
      });
    }
    /* update unread to true */
    const chatsDocRef = doc(db, collection_ref?.chats, selectedUser?.chat_id);
    const chatData = (await getDoc(chatsDocRef)).data();
    const updatedArray = chatData?.read_statuses?.map((item) => {
      if (item.id == auth_id) {
        return {
          ...item,
          is_read: true,
        };
      } else {
        return item;
      }
    });
    if (updatedArray?.length > 0) {
      await updateDoc(chatsDocRef, {
        read_statuses: updatedArray,
      });
    }

    return subscribed;
  } catch (e) {
    notification.error({
      description: e.message,
      placement: "bottomRight",
      className: "createdNotification",
      closeIcon: <ToastCrossIcon />,
    });
    dispatch(setIsChannelMessagesLoading(false));
  }
};

export const deleteThread = async (
  selectedUser,
  channelMessages,
  dispatch,
  onDelete,
  setOnDelete,
) => {
  try {
    const chatDocRef = doc(db, collection_ref?.chats, selectedUser?.chat_id);
    const chatDoc = await getDoc(chatDocRef);
    const lastMessage = channelMessages[channelMessages?.length - 1];

    if (chatDoc.exists()) {
      const data = chatDoc.data();
      const deleted_statuses = data?.deleted_statuses?.map((status) => {
        if (status?.id == authUser?.id) {
          return {
            id: authUser?.id,
            is_deleted: true,
          };
        } else {
          return status;
        }
      });

      const deleted_messages_ids = data?.deleted_messages_ids?.map((msg) => {
        if (msg?.id == authUser?.id) {
          return {
            id: authUser?.id,
            deleted_message_id: lastMessage?.thread_id || "",
          };
        } else {
          return msg;
        }
      });

      await updateDoc(chatDocRef, {
        deleted_statuses,
        deleted_messages_ids,
        updated_at: new Date(),
      });
      let tempSelectedUser = { ...selectedUser };
      tempSelectedUser.deleted_statuses = deleted_statuses;
      tempSelectedUser.deleted_messages_ids = deleted_messages_ids;

      dispatch(setSelectedUser(null));
      dispatch(setChannelMessages([]));
      setOnDelete(onDelete + 1);
    }
  } catch (e) {
    notification.error({
      description: e.message,
      placement: "bottomRight",
      className: "createdNotification",
      closeIcon: <ToastCrossIcon />,
    });
  }
};

export const sendMessage = async (selectedUser, newMessage) => {
  try {
    const chatsDocRef = doc(db, collection_ref?.chats, selectedUser?.chat_id);
    const messagesRef = collection(chatsDocRef, collection_ref?.threads);
    let chatData = await getDoc(chatsDocRef);
    chatData = chatData.data();
    if (newMessage?.message_type == message_type.text) {
      if (newMessage?.text && newMessage?.text != "") {
        await addDoc(messagesRef, newMessage);
      }
    } else {
      await addDoc(messagesRef, newMessage);
    }
    let lastMessage = "";
    switch (newMessage?.message_type) {
      case message_type?.media:
        lastMessage = "Media";
        break;
      case message_type?.text:
        lastMessage = newMessage?.text;
        break;
      case message_type?.image:
        lastMessage = "Image";
        break;
      case message_type?.video:
        lastMessage = "Video";
        break;
      case message_type?.audio:
        lastMessage = "Audio";
        break;
    }
    let deleted_statuses = [];
    chatData?.users_ids.map((item) => {
      deleted_statuses.push({ id: item, is_deleted: false });
    });
    await updateDoc(chatsDocRef, {
      updated_at: newMessage?.created_at,
      last_message: lastMessage,
      deleted_statuses,
    });
  } catch (e) {
    notification.error({
      description: e.message,
      placement: "bottomRight",
      className: "createdNotification",
      closeIcon: <ToastCrossIcon />,
    });
  }
};

export const isUserAlreadyChatWithEachOther = async (
  curr_user_id,
  selected_user_id,
  dispatch,
) => {
  try {
    let isAlreadyChat = false;
    let channel = null;
    let shouldBreak = false;
    let thread = null;
    // finds docs in which any one from curr_user_id or selected_user_id exist
    const q = query(
      collection(db, collection_ref?.chats),
      where("users_ids", "array-contains-any", [
        curr_user_id,
        selected_user_id,
      ]),
      where("is_group_chat", "==", false),
    );

    // check if users_ids contain curr_user_id and selected_user_id and users_ids.length == 2 (for 1-1 chat)
    // it means users already chat with each other else users are not already chat with each other
    const querySnapshot = await getDocs(q);
    querySnapshot?.docs?.forEach((doc) => {
      if (shouldBreak) {
      } else {
        const data = doc?.data();
        data.chat_id = doc.id;
        if (
          data?.users_ids?.includes(curr_user_id) &&
          data?.users_ids?.includes(selected_user_id) &&
          data?.users_ids.length == 2
        ) {
          thread = doc.id;
          isAlreadyChat = true;
          channel = data;
          shouldBreak = true; // break the loop if user already chat with each other
        } else {
          isAlreadyChat = false;
        }
      }
    });

    if (isAlreadyChat) {
      const usersRef = collection(db, collection_ref?.users);
      const q = query(usersRef, where("id", "==", selected_user_id.toString()));
      const querySnapshot = await getDocs(q);
      if (!querySnapshot.empty) {
        const userDoc = querySnapshot.docs[0];
        channel.receiver_profile = [userDoc.data()];
        dispatch(setSelectedUser(channel));
      }
    } else {
      const chatRef = collection(db, collection_ref?.chats);
      const addedDocRef = await addDoc(chatRef, {
        blocked_statuses: [
          { id: curr_user_id, is_blocked: false },
          { id: selected_user_id, is_blocked: false },
        ],
        created_at: serverTimestamp(),
        deleted_messages_ids: [
          {
            deleted_message_id: "",
            id: curr_user_id,
            is_group_message_deleted: false,
          },
          {
            deleted_message_id: "",
            id: selected_user_id,
            is_group_message_deleted: false,
          },
        ],
        deleted_statuses: [
          { id: curr_user_id, is_deleted: false },
          { id: selected_user_id, is_deleted: false },
        ],
        is_group_chat: false,
        is_mobile_group: false,
        last_message: "",
        read_statuses: [
          { id: curr_user_id, is_read: false },
          { id: selected_user_id, is_read: false },
        ],
        updated_at: serverTimestamp(),
        users_ids: [curr_user_id, selected_user_id],
        group_message_statuses: [
          { id: curr_user_id, is_group_message_deleted: false },
          { id: selected_user_id, is_group_message_deleted: false },
        ],
      });
      const newChatDocSnapshot = await getDoc(addedDocRef);
      const newChatData = newChatDocSnapshot.data();
      newChatData.chat_id = newChatDocSnapshot.id;
      thread = newChatDocSnapshot.id;
      isAlreadyChat = false;

      // find selected user profile
      const usersRef = collection(db, collection_ref?.users);
      const q = query(usersRef, where("id", "==", selected_user_id.toString()));
      const querySnapshot = await getDocs(q);
      if (!querySnapshot.empty) {
        const userDoc = querySnapshot.docs[0];
        newChatData.receiver_profile = [userDoc.data()];
        dispatch(setSelectedUser(newChatData));
      }
    }
    return thread;
  } catch (e) {
    notification.error({
      description: e.message,
      placement: "bottomRight",
      className: "createdNotification",
      closeIcon: <ToastCrossIcon />,
    });
  }
};

export const addUserToFirebase = async (data) => {
  try {
    await setDoc(doc(db, collection_ref?.users, data.id), data);
  } catch (e) {
    notification.error({
      description: e.message,
      placement: "bottomRight",
      className: "createdNotification",
      closeIcon: <ToastCrossIcon />,
    });
  }
};

export const updateOnlineStatusOfLoginUser = async (user_id, status) => {
  try {
    const userDocRef = doc(db, collection_ref?.users, user_id?.toString());
    const docSnapshot = await getDoc(userDocRef);

    if (docSnapshot.exists()) {
      await updateDoc(userDocRef, { online: status });
    }
  } catch (e) {
    notification.error({
      description: e.message,
      placement: "bottomRight",
      className: "createdNotification",
      closeIcon: <ToastCrossIcon />,
    });
  }
};
export const syncFirebaseGroup = async (group_id, group, newMessage = null) => {
  let groupMembers = [authUser?.id];
  let deletedStatuses = [];
  let blockedStatuses = [];
  let deletedMessagesId = [];
  let groupMessageStatuses = [];
  let readStatuses = [];
  const chatsCollectionRef = collection(db, collection_ref?.chats);
  const q = query(chatsCollectionRef, where("group_id", "==", group_id));
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach(async (dt) => {
    const documentRef = doc(db, collection_ref?.chats, dt.id);
    const docSnapshot = await getDoc(documentRef);
    const groupDoc = docSnapshot.data();
    if (newMessage) {
      await sendMessage({ chat_id: dt.id }, newMessage);
    }
    let groupUsers = groupDoc?.users_ids;
    group?.users.map((item) => {
      groupMembers.push(item.id);
    });
    /* update arrays */
    groupMembers?.map((item) => {
      let checkDeletedStatuses =
        groupDoc?.deleted_statuses?.filter((member) => member?.id == item) ||
        [];
      let checkBlockedStatuses =
        groupDoc?.blocked_statuses?.filter((member) => member?.id == item) ||
        [];
      let checkDeletedMessagesId =
        groupDoc?.deleted_messages_id?.filter((member) => member?.id == item) ||
        [];
      let checkGroupMessageStatuses =
        groupDoc?.group_message_statuses?.filter(
          (member) => member?.id == item,
        ) || [];
      let checkReadStatuses =
        groupDoc?.read_statuses?.filter((member) => member?.id == item) || [];
      if (checkDeletedStatuses.length > 0) {
        deletedStatuses.push(checkDeletedStatuses[0]);
      } else {
        deletedStatuses.push({ id: item, is_deleted: false });
      }
      if (checkBlockedStatuses.length > 0) {
        blockedStatuses.push(checkBlockedStatuses[0]);
      } else {
        blockedStatuses.push({ id: item, is_blocked: false });
      }
      if (checkDeletedMessagesId.length > 0) {
        deletedMessagesId.push(checkDeletedMessagesId[0]);
      } else {
        deletedMessagesId.push({ id: item, deleted_message_id: "" });
      }
      if (checkGroupMessageStatuses.length > 0) {
        groupMessageStatuses.push(checkGroupMessageStatuses[0]);
      } else {
        groupMessageStatuses.push({
          id: item,
          is_group_message_deleted: false,
        });
      }
      if (checkReadStatuses.length > 0) {
        readStatuses.push(checkReadStatuses[0]);
      } else {
        readStatuses.push({ id: item, is_read: false });
      }
    });

    const docRef = doc(db, collection_ref?.chats, dt.id);
    await updateDoc(docRef, {
      users_ids: groupMembers,
      blocked_statuses: blockedStatuses,
      deleted_messages_id: deletedMessagesId,
      deleted_statuses: deletedStatuses,
      group_message_statuses: groupMessageStatuses,
      read_statuses: readStatuses,
    });
  });
};
export const createGroupChat = async (
  data,
  user = null,
  group_id,
  dispatch,
  usersMembers,
  group,
) => {
  authUser = user || authUser;
  if (authUser) {
    try {
      if (!data?.users?.includes(authUser?.id)) {
        data?.users?.push(authUser?.id);
      }
      const chatRef = collection(db, collection_ref?.chats);
      await addDoc(chatRef, {
        group_id: data?.group_id,
        admin_id: authUser?.id,
        blocked_statuses: data?.users?.map((item) => ({
          id: item,
          is_blocked: false,
        })),
        created_at: serverTimestamp(),
        deleted_messages_ids: data?.users?.map((item) => ({
          deleted_message_id: "",
          id: item,
          is_group_message_deleted: false,
        })),
        deleted_statuses: data?.users?.map((item) => ({
          id: item,
          is_deleted: false,
        })),
        group_message_statuses: data?.users?.map((item) => ({
          id: item,
          is_group_message_deleted: false,
        })),
        image: data?.image ? data?.image : "",
        is_group_chat: true,
        name: data?.name,
        last_message: "",
        read_statuses: data?.users?.map((item) => ({
          id: item,
          is_read: item == authUser?.id,
        })),
        un_read_count: 0,
        updated_at: serverTimestamp(),
        users_ids: data?.users?.map((item) => item),
      });
      if (group_id) {
        redirectToGroupChat(group_id, dispatch, usersMembers, group);
      }
    } catch (e) {
      notification.error({
        description: e.message,
        placement: "bottomRight",
        className: "createdNotification",
        closeIcon: <ToastCrossIcon />,
      });
    }
  }
};

export const updateGroupChat = async (data) => {
  try {
    const chatRef = collection(db, collection_ref?.chats);
    const q = query(chatRef, where("group_id", "==", data?.group_id));
    const querySnapshot = await getDocs(q);

    querySnapshot?.forEach(async (docSnapshot) => {
      const docRef = doc(db, collection_ref?.chats, docSnapshot.id);
      await updateDoc(docRef, {
        image: data?.image,
        name: data?.name,
      });
    });
  } catch (e) {
    notification.error({
      description: e.message,
      placement: "bottomRight",
      className: "createdNotification",
      closeIcon: <ToastCrossIcon />,
    });
  }
};

export const deleteGroupChat = async (group_id) => {
  try {
    const chatRef = collection(db, collection_ref?.chats);
    const q = query(chatRef, where("group_id", "==", group_id));
    const querySnapshot = await getDocs(q);

    querySnapshot?.forEach(async (docSnapshot) => {
      const docRef = doc(db, collection_ref?.chats, docSnapshot.id);
      await deleteDoc(docRef);
    });
  } catch (e) {
    notification.error({
      description: e.message,
      placement: "bottomRight",
      className: "createdNotification",
      closeIcon: <ToastCrossIcon />,
    });
  }
};

export const redirectToGroupChat = async (
  group_id,
  dispatch,
  usersMembers = [],
  group = null,
) => {
  usersMembers.push(authUser?.id);
  const usersCollectionRef = collection(db, collection_ref?.users);
  const chatsCollectionRef = collection(db, collection_ref?.chats);
  const q = query(chatsCollectionRef, where("group_id", "==", group_id));

  const querySnapshot = await getDocs(q);
  if (querySnapshot.size == 0) {
    let groupMembers = [];
    group?.users.map((item) => {
      groupMembers.push(item.id);
    });
    let data = {
      group_id,
      users: groupMembers,
      name: group?.name,
      image: group?.image ? group?.image : "",
    };
    await createGroupChat(data, null, group_id, dispatch, usersMembers, group);
  } else {
    querySnapshot.forEach(async (dt) => {
      if (dt.exists()) {
        const documentRef = doc(db, collection_ref?.chats, dt.id);
        const docSnapshot = await getDoc(documentRef);
        if (docSnapshot.exists()) {
          const documentData = docSnapshot.data();
          documentData.chat_id = dt.id;

          // fetch all users from collection
          const userquerySnapshot = await getDocs(usersCollectionRef);
          const users = [];
          userquerySnapshot.forEach((doc) => {
            const userData = doc.data();
            userData.id = doc.id;
            users.push(userData);
          });

          let profiles = [];

          // compare chats users_ids with users data and add relevent user as receiver_profile
          for (let i = 0; i < documentData?.users_ids?.length; i++) {
            const [receiver_profile] = users
              ? users.filter((item) => item?.id == documentData?.users_ids[i])
              : [];
            profiles.push(receiver_profile);
          }

          documentData.receiver_profile = profiles;

          dispatch(setSelectedUser(documentData));
        }
      }
    });
  }
};

export const signInToFirebase = () => {
  let token = GetTokenLocalStorage();
  fetch(`${constant.BASE_URL + apiUrl.firebase_token}`)
    .then((response) => response.json())
    .then((data) => {
      if (data?.data && typeof data?.data === "string") {
        signInWithCustomToken(auth, data.data)
          .then(function (result) {
            let user = result.user;
          })
          .catch(function (error) {
            let errorCode = error.code;
            let errorMessage = error.message;
            console.log(errorMessage, "err msg");
          });
      }
    })
    .catch((error) => {
      console.error("Error:", error);
    });
};
export const deleteFirebaseUser = async (user_id) => {
  user_id = user_id.toString();
  const userDocRef = doc(db, collection_ref?.users, user_id);
  const userData = (await getDoc(userDocRef)).data();

  if (userData) {
    userData.is_deleted = true;
    userData.name = userData?.name;
    userData.image = userData?.image ? userData?.image : "";
    userData.position = userData?.position_id || null;
    await updateDoc(userDocRef, userData);
  }
};
