import { Drawer, notification, Popover, Space } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import DeleteModal from "../../components/Modals/DeleteModal";
import ShareModal from "../../components/Modals/ShareModal";
import FlashcardEditForm from "../../components/pages/FlashCardsPage/FlashcardEditForm";
import { Assets } from "../../constants/images";
import {
  DrawerCloseIcon,
  EditIcon,
  PurpleBackIcon,
  RecycleBin,
  ShareIcon,
  ToastCrossIcon,
} from "../../constants/svg";
import {
  DeleteSingleFlashcard,
  GetSingleFlashcard,
} from "../../services/flashCards/flashCards";
import {
  setFlashCardAddEditForm,
  setFlashCardSelectedData,
  setIsSingleFlashcardLoading,
  setSingleFlashcard,
} from "../../store/slices/flashcardSlice";
import { setStatUserHover } from "../../store/slices/globalSlice";
import FlashcardModalForm from "../../components/pages/FlashCardsPage/FlashcardModal/FlashcardModalForm";
import GenericModal from "../../components/Modals/GenericModal";
import ProfileHoverChat from "../../components/Common/ProfileHoverChat";
import {
  hasAccess,
  renderContentWithLinks,
  toastMessage,
} from "../../utils/helper";
import { SHARE_URL } from "../../utils/constants";
import ProfileHover from "../../components/Common/ProfileHover";
import AvatarImage from "../../components/Common/AvatarImage";

const FlashCardDetailPage = () => {
  const [showModal, setShowModal] = useState({
    shareModal: null,
    editModal: null,
    deleteModal: null,
  });
  const [open, setOpen] = useState(false);
  const { positions } = useSelector((state) => state.global);
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { singleFlashcard, flashCardAddEditForm, flashCardSelectedData } =
    useSelector((state) => state?.flashCard);
  const { statUserHover } = useSelector((state) => state.global);
  const [disable, setDisable] = useState(false);
  const url = SHARE_URL.FLASHCARDS + id;
  const [show, setShow] = useState(null);
  const [hoveredItem, setHoveredItem] = useState(null);

  const handleMouseEnter = (item) => {
    setHoveredItem(item.user?.id);
  };

  const handleMouseLeave = () => {
    setHoveredItem(null);
    setShow(false);
  };

  const onClose = () => {
    setOpen(false);
  };

  const handleDelete = async () => {
    try {
      setDisable(true);

      const res = await DeleteSingleFlashcard(id);
      setShowModal({ ...showModal, deleteModal: false });
      toastMessage("delete", res?.data?.message);
      setDisable(false);
      setTimeout(() => {
        navigate("/flashcards");
      }, 1200);
    } catch (e) {
      toastMessage("error", e.message);
      setDisable(false);
    }
  };

  useEffect(() => {
    const getSingleFlashcard = async () => {
      setFlashCardSelectedData({ key: id });
      try {
        const res = await GetSingleFlashcard(id);
        dispatch(setSingleFlashcard(res.data.data));
        dispatch(setIsSingleFlashcardLoading(false));
      } catch (e) {
        notification.error({
          description: e.message,
          placement: "bottomRight",
          className: "createdNotification",
          closeIcon: <ToastCrossIcon />,
        });

        dispatch(setIsSingleFlashcardLoading(false));
      }
    };

    getSingleFlashcard();
  }, [id]);

  const returnPosition = (position_id) => {
    for (let i = 0; i < positions.length; i++) {
      if (positions[i].value == position_id) {
        return positions[i]?.label || "-";
      }
    }
  };

  return (
    <Row>
      <Col lg={8} className={"pe-0"}>
        <div className="leftEventDetailWrapper">
          <div className="eventDetailHeader innerContainer d-flex justify-content-between">
            <div className="backToEvent">
              <NavLink to="/flashcards">
                <PurpleBackIcon />
                <span className="uLineHover">Back to Flashcards</span>
              </NavLink>
            </div>
            <div className="shareButton">
              <Space size={"large"}>
                <div
                  className="icon-text-wrapper cursor-pointer blackLine"
                  onClick={() =>
                    setShowModal((prevState) => ({
                      ...prevState,
                      shareModal: true,
                    }))
                  }
                >
                  <ShareIcon />
                  <p className="uLine ">Share</p>
                </div>
                {hasAccess(singleFlashcard?.data?.user) && (
                  <div
                    className="icon-text-wrapper cursor-pointer blackLine"
                    onClick={() => {
                      // setOpen(true);
                      dispatch(
                        setFlashCardAddEditForm({
                          show: true,
                          formType: "edit",
                        }),
                      );
                      dispatch(setFlashCardSelectedData({ key: id }));
                    }}
                  >
                    <EditIcon />
                    <p className="uLine ">Edit</p>
                  </div>
                )}
                {hasAccess(singleFlashcard?.data?.user) && (
                  <div className="icon-text-wrapper deleteLabel cursor-pointer">
                    <RecycleBin />
                    <p
                      className="uLine redLine"
                      onClick={() =>
                        setShowModal((prevState) => ({
                          ...prevState,
                          deleteModal: true,
                        }))
                      }
                    >
                      Delete
                    </p>
                  </div>
                )}
              </Space>
            </div>
          </div>
          <div className="eventDetailBody">
            <div className="innerContainer">
              <h3 className="sec-heading">
                {singleFlashcard?.data?.name || "-"}
              </h3>
              <div className="eventDetailInformation">
                <div className="eventDetailItem">
                  <h5 className="head">Category</h5>
                  <p>{singleFlashcard?.data?.category?.name || "-"}</p>
                </div>
                <div className="eventDetailItem">
                  <h5 className="head">Tags</h5>
                  <p>
                    {singleFlashcard?.data?.tags
                      ?.map((item) => item.name)
                      .join(", ") || "-"}
                  </p>
                </div>
                <div className="eventDetailItem">
                  <h5 className="head">Description</h5>
                  <p>
                    {singleFlashcard?.data?.description
                      ? renderContentWithLinks(
                          singleFlashcard?.data?.description,
                        )
                      : "-"}
                  </p>
                </div>

                <div className="eventDetailItem">
                  <h5 className="head">Total Cards</h5>
                  <p>{singleFlashcard?.data?.flashcardElements?.length || 0}</p>
                </div>
                <div className="eventDetailItem">
                  <h5 className="head">Created By</h5>
                  <p>{singleFlashcard?.data?.user?.name || "-"}</p>
                </div>
                <div className="eventDetailItem">
                  <h5 className="head">Created On</h5>
                  <p>
                    {singleFlashcard?.data?.created_at
                      ? moment(singleFlashcard?.data?.created_at).format(
                          "MMM DD, yyyy",
                        )
                      : "-"}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Col>

      <Col lg={4} className={"p-0"} onMouseLeave={handleMouseLeave}>
        <div className="rightEventDetailWrapper">
          <div className="eventDetailHeader d-flex justify-content-between">
            <h4 className="sec-heading m-0 p-0">Flashcard Results</h4>
          </div>
          {singleFlashcard?.isSingleFlashcardLoading ? (
            <div className="mt-2 load black" />
          ) : singleFlashcard?.data?.length <= 0 ? (
            <span>Check back soon!</span>
          ) : (
            <div className="eventDetailBody">
              <div className="response-card">
                <div className="response-header">
                  <h6>Started ({singleFlashcard?.data?.meta?.started})</h6>
                </div>
                <div className="response-body">
                  {singleFlashcard?.data?.startedUsers?.map((item, index) => {
                    if (item?.user?.name) {
                      return (
                        <div
                          style={{ position: "relative" }}
                          className="avatar-item"
                        >
                          <Popover
                            placement="rightTop"
                            content={
                              <ProfileHover
                                user={{
                                  id: item?.user?.id,
                                  name: item?.user?.name,
                                  image: item?.user?.image,
                                  position: returnPosition(
                                    item?.user?.position_id,
                                  ),
                                }}
                              />
                            }
                            arrow={true}
                            key={index}
                          >
                            <div className="statUserHoverContainer customHover">
                              <AvatarImage
                                name={item?.user?.name}
                                src={item?.user?.image}
                                placeholder={Assets.ProfilePlaceholder}
                                size={38}
                              />
                            </div>
                          </Popover>
                          <NavLink
                            key={index}
                            to={`/team/members/${item?.user?.slug ?? item?.user?.id}`}
                          >
                            <p>{item?.user?.name}</p>
                          </NavLink>
                        </div>
                      );
                    }
                  })}
                </div>
              </div>
              <div className="response-card">
                <div className="response-header">
                  <h6>
                    Not Started ({singleFlashcard?.data?.meta?.not_started})
                  </h6>
                </div>
                <div className="response-body">
                  {singleFlashcard?.data?.notStartedUsers?.map(
                    (item, index) => {
                      if (item?.user?.name) {
                        return (
                          <div
                            style={{ position: "relative" }}
                            className="avatar-item"
                          >
                            <Popover
                              placement="rightTop"
                              content={
                                <ProfileHover
                                  user={{
                                    id: item?.user?.id,
                                    name: item?.user?.name,
                                    image: item?.user?.image,
                                    position: returnPosition(
                                      item?.user?.position_id,
                                    ),
                                  }}
                                />
                              }
                              arrow={true}
                              key={index}
                            >
                              <div className="statUserHoverContainer customHover">
                                <AvatarImage
                                  name={item?.user?.name}
                                  src={item?.user?.image}
                                  placeholder={Assets.ProfilePlaceholder}
                                  size={38}
                                />
                              </div>
                            </Popover>
                            <NavLink
                              to={`/team/members/${item?.user?.slug ?? item?.user?.id}`}
                            >
                              <p>{item?.user?.name}</p>
                            </NavLink>
                          </div>
                        );
                      }
                    },
                  )}
                </div>
              </div>
              <div className="response-card">
                <div className="response-header">
                  <h6>Completed ({singleFlashcard?.data?.meta?.completed})</h6>
                </div>
                <div className="response-body">
                  {singleFlashcard?.data?.completedUsers?.map((item, index) => {
                    if (item?.user?.name) {
                      return (
                        <div
                          style={{ position: "relative" }}
                          className="avatar-item"
                        >
                          <Popover
                            placement="rightTop"
                            content={
                              <ProfileHover
                                user={{
                                  id: item?.user?.id,
                                  name: item?.user?.name,
                                  image: item?.user?.image,
                                  position: returnPosition(
                                    item?.user?.position_id,
                                  ),
                                }}
                              />
                            }
                            arrow={true}
                            key={index}
                          >
                            <div className="statUserHoverContainer customHover">
                              <AvatarImage
                                name={item?.user?.name}
                                src={item?.user?.image}
                                placeholder={Assets.ProfilePlaceholder}
                                size={38}
                              />
                            </div>
                          </Popover>
                          <NavLink to={`/team/members/${item?.user?.id}`}>
                            <p>{item?.user?.name}</p>
                          </NavLink>
                        </div>
                      );
                    }
                  })}
                </div>
              </div>
            </div>
          )}
        </div>
        {/* Share Modal */}
        <ShareModal
          data={{
            image: singleFlashcard?.data?.image,
            name: singleFlashcard?.data?.name,
            description: singleFlashcard?.data?.description,
            start_date: moment(singleFlashcard?.data?.created_at).format(
              "MMM, DD yyyy",
            ),
            start_time: moment(singleFlashcard?.data?.created_at).format(
              "hh:mm a",
            ),
          }}
          shareLink={url}
          show={showModal.shareModal}
          onHide={() =>
            setShowModal((prevState) => ({ ...prevState, shareModal: false }))
          }
          setModalShow={() =>
            setShowModal((prevState) => ({ ...prevState, shareModal: true }))
          }
          modalTitle={"Share Flashcard Set"}
          modalSubtitle={
            <>
              Click “Copy Link” below, then{" "}
              <NavLink
                to="/inbox"
                className={"uLine blue-accent-color"}
                target="_blank"
              >
                {" "}
                Open Chat{" "}
              </NavLink>{" "}
              to easily share this flashcard with members.
            </>
          }
          size={"lg"}
          bodyComponent={{
            img: Assets.eventShareModal,
            title: "Introduction to L’Oreal Color",
            para1: "Improve your Business knowledge with this flashcard set.",
            para2: "10 Cards • Feb 12, 2023",
          }}
        />

        {/* Delete Modal */}
        <DeleteModal
          show={showModal.deleteModal}
          onHide={() => setShowModal({ ...showModal, deleteModal: false })}
          heading="Delete Flashcard"
          disable={disable}
          handleDelete={handleDelete}
          para={
            <>
              Are you sure you want to delete
              <b> {singleFlashcard?.data?.name || "this flashcard"} ?</b>
              <br />
              Important: This cannot be undone.
            </>
          }
          notificationMessage="Flashcard set"
        />
        <GenericModal
          show={flashCardAddEditForm.show}
          onHide={() => {
            dispatch(
              setFlashCardAddEditForm({
                show: false,
                formType: null,
              }),
            );
          }}
          setModalShow={(e) => {
            dispatch(
              setFlashCardAddEditForm({
                show: e,
                formType: null,
              }),
            );
          }}
          modalTitle={
            flashCardAddEditForm.formType == "edit"
              ? "Edit Flashcard Set"
              : "New Flashcard Set"
          }
          size={"lg"}
          bodyComponent={<FlashcardModalForm />}
        />
      </Col>
      {/* Edit Drawer */}
      <Drawer
        title="Edit Flashcard"
        placement="right"
        className="custom-drawer editDrawer"
        width={471}
        onClose={onClose}
        open={open}
        closable={false}
        extra={
          <Space>
            <div className="cursor-pointer" onClick={onClose}>
              <DrawerCloseIcon />
            </div>
          </Space>
        }
      >
        <div style={{ marginTop: "31px" }}>
          <FlashcardEditForm setOpen={setOpen} />
        </div>
      </Drawer>
    </Row>
  );
};

export default FlashCardDetailPage;
