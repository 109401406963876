import React, { useEffect, useState } from "react";
import { Button, Col, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { notification } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { ToastCrossIcon, ToastRedTrashIcon } from "../../../../constants/svg";
import {
  CreateFlashCard,
  GetSingleFlashcard,
  UpdateFlashCard,
} from "../../../../services/flashCards/flashCards";
import {
  setFlashCardAddEditForm,
  setIsFlashcardsLoading,
  setReloadFlashCardTable,
  setReRenderFlashCardBuilder,
} from "../../../../store/slices/flashcardSlice";
import {
  setCategoryListByModule,
  setCreateUpdateTitleModuleID,
  setTags,
} from "../../../../store/slices/globalSlice";
import {
  all_category_type,
  roles as salonRoles,
  search_module_type,
  segment_type,
  status as recordStatus,
} from "../../../../utils/constants";
import { salonUploadToS3, toastMessage } from "../../../../utils/helper";
import FirstStep from "./StepForm/FirstStep";
import SecondStep from "./StepForm/SecondStep";
import { GetAuthUserLocalStorage } from "../../../../services/localStorage/localStorage";
import ZeroStep from "../../Resource/ResourceModal/StepsForm/ZeroStep";

const FlashcardModalForm = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    isReloadFlashCardTable,
    flashCardAddEditForm,
    flashCardSelectedData,
    reRenderFlashCardBuilder,
  } = useSelector((state) => state.flashCard);
  const { currentSalonId, salonSegments } = useSelector(
    (state) => state.global,
  );

  const [step, setStep] = useState(
    GetAuthUserLocalStorage()?.roles[0]?.id == salonRoles.super_admin ? 0 : 1,
  ); // initial the step where you want to start
  const [segment, setSegment] = useState(null);
  const [selectedSalons, setSelectedSalons] = useState([]);
  const [selectedProductLines, setSelectedProductLines] = useState([]);
  const [selectedPositions, setSelectedPositions] = useState([]);
  const [zeroStepDisabled, setZeroStepDisabled] = useState(true);
  const [firstStepDisabled, setFirstStepDisabled] = useState(true);
  const [secondStepDisabled, setSecondStepDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [softwares, setSoftwares] = useState([]);
  const [technologies, setTechnologies] = useState([]);

  const {
    register,
    watch,
    handleSubmit,
    control,
    setValue,
    getValues,
    formState: { errors, isValid },
  } = useForm({
    mode: "onChange",
    criteriaMode: "firstError",
    shouldFocusError: true,
    defaultValues: {
      setName: "",
      category: "",
      tags: "",
      description: "",
      uploadFileFromLocal: "",
      uploadFileFromGallery: "",
      uploadFileFromFolder: "",
      previewImage: "",
    },
  });

  const watchingStepForm1 = watch(["setName", "category", "tags"]);
  const watchingStepForm2 = watch([
    "uploadFileFromLocal",
    "uploadFileFromGallery",
    "uploadFileFromFolder",
  ]);
  useEffect(() => {
    const isValid = watchingStepForm1.every((elem) => elem != "");
    if (isValid) {
      setFirstStepDisabled(false);
    } else {
      setFirstStepDisabled(true);
    }
  }, [watchingStepForm1]);

  // useEffect(() => {
  //   const isValid = watchingStepForm2.some((elem) => elem != "");
  //   if (isValid && !loading) {
  //     setSecondStepDisabled(false);
  //   } else if (
  //     getValues("previewImage") == "" ||
  //     getValues("previewImage") == undefined
  //   ) {
  //     setSecondStepDisabled(true);
  //   } else if (
  //     getValues("previewImage") != "" ||
  //     getValues("previewImage") != undefined
  //   ) {
  //     if (loading) setSecondStepDisabled(true);
  //     else setSecondStepDisabled(false);
  //   } else {
  //     setSecondStepDisabled(true);
  //   }
  // }, [watchingStepForm2]);

  useEffect(() => {
    dispatch(setCategoryListByModule(all_category_type?.RFQ));

    if (flashCardAddEditForm.formType == "edit") getFlashCardById();
  }, []);

  // Form Submit Function
  const onSubmit = async (data) => {
    try {
      setSecondStepDisabled(true);
      setLoading(true);
      let payload = {
        name: data?.setName,
        status: recordStatus.active,
        category_id: data?.category,
        description: data?.description,
        salon_id: currentSalonId,
        tags: data?.tags,
      };
      if (GetAuthUserLocalStorage()?.roles[0]?.id == salonRoles.super_admin) {
        let salonData =
          selectedSalons.length > 0
            ? selectedSalons
            : salonSegments.map((item) => {
                return item.id;
              });
        payload.salons = salonData;
        payload.products = selectedProductLines;
        payload.positions = selectedPositions;
        payload.segment = segment;
        payload.softwares = softwares;
        payload.technologies = technologies;
      }

      let salonS3UploadResponse;
      if (data.uploadFileFromLocal != "") {
        if (!Array.isArray(data?.uploadFileFromLocal)) {
          payload.image = data?.uploadFileFromLocal;
        } else {
          salonS3UploadResponse = await salonUploadToS3(
            data?.uploadFileFromLocal[0],
            "uploads/flashcard",
            GetAuthUserLocalStorage()?.roles[0]?.id != salonRoles.super_admin,
          );
          if (salonS3UploadResponse.status) {
            payload.image = salonS3UploadResponse?.data?.location;
          } else {
            throw new Error(salonS3UploadResponse?.message);
          }
        }
      }

      if (data.uploadFileFromGallery != "") {
        if (!Array.isArray(data?.uploadFileFromGallery)) {
          payload.image = data?.uploadFileFromGallery;
        } else {
          salonS3UploadResponse = await salonUploadToS3(
            data?.uploadFileFromGallery[0],
            "uploads/flashcard",
            true,
          );
          if (salonS3UploadResponse.status) {
            payload.image = salonS3UploadResponse?.data?.location;
          } else {
            throw new Error(salonS3UploadResponse?.message);
          }
        }
      }
      if (data.uploadFileFromFolder != "") {
        if (!Array.isArray(data?.uploadFileFromFolder)) {
          payload.image = data?.uploadFileFromFolder;
        } else {
          salonS3UploadResponse = await salonUploadToS3(
            data?.uploadFileFromFolder[0],
            "uploads/flashcard",
            true,
          );
          if (salonS3UploadResponse.status) {
            payload.image = salonS3UploadResponse?.data?.location;
          } else {
            throw new Error(salonS3UploadResponse?.message);
          }
        }
      }

      if (
        !payload.image &&
        (getValues("previewImage") == "" ||
          getValues("previewImage") == undefined)
      ) {
        payload.image = null;
      }
      let response;
      if (flashCardAddEditForm?.formType == "create") {
        response = await CreateFlashCard(payload);
      } else {
        response = await UpdateFlashCard(
          flashCardSelectedData?.key ?? flashCardSelectedData?.id,
          payload,
        );
      }

      const { data: respData, message, status } = response.data;

      dispatch(
        setFlashCardAddEditForm({
          show: false,
          formType: null,
        }),
      );
      dispatch(setReloadFlashCardTable(isReloadFlashCardTable + 1));
      dispatch(setReRenderFlashCardBuilder(reRenderFlashCardBuilder + 1));

      navigate("/flashcard/flash-card-builder/" + respData.id);
    } catch (e) {
      toastMessage("error", e.message);
    } finally {
      setSecondStepDisabled(false);
      setLoading(false);
      dispatch(setIsFlashcardsLoading(true));
    }
  };

  const nextStep = () => {
    setStep(step + 1);
  };

  const prevStep = () => {
    setStep(step - 1);
  };

  const getFlashCardById = async () => {
    try {
      let res = await GetSingleFlashcard(
        flashCardSelectedData?.key ?? flashCardSelectedData?.id,
      );
      const { data, message, status } = res.data;
      if (!status) {
        throw new Error(message);
      }
      setSegment(data?.segment || null);
      if (data?.segment == segment_type.CUSTOM) {
        let salons = [];
        let products = [];
        data?.products?.map((item) => {
          products.push(item.product);
        });
        data?.salons?.map((item) => {
          salons.push(item.id);
        });
        setSelectedSalons(salons);
        setSelectedProductLines(products);
      }
      setValue("setName", data?.name);
      setValue("category", data?.category_id);
      setValue("description", data?.description);
      setTechnologies(data.technologies.map(({ name }) => name) ?? []);
      setSoftwares(data.software.map(({ name }) => name) ?? []);
      dispatch(
        setTags(
          data?.tags.map((dt) => {
            return dt?.id;
          }),
        ),
      );
      setValue(
        "tags",
        data?.tags.map((dt) => {
          return dt?.id;
        }),
      );
      setValue("previewImage", data.image);
    } catch (e) {
      notification.error({
        message: <span>{e.message}</span>,
        placement: "bottomRight",
        className: "createdNotification",
        icon: <ToastRedTrashIcon />,
        closeIcon: <ToastCrossIcon />,
      });
    }
  };

  useEffect(() => {
    dispatch(setCreateUpdateTitleModuleID(search_module_type.flashcard));
  }, []);

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div
          className={`${step == 0 ? "modal-body" : "modal-body tabs-modal"} ${props.customModalBody}`}
        >
          <div className={step == 0 ? "" : "d-none"}>
            <ZeroStep
              setZeroStepDisabled={setZeroStepDisabled}
              customClass="noBorders"
              selectedSalons={selectedSalons}
              setSelectedSalons={setSelectedSalons}
              setSelectedProductLines={setSelectedProductLines}
              selectedProductLines={selectedProductLines}
              setSelectedPositions={setSelectedPositions}
              selectedPositions={selectedPositions}
              showPositions={false}
              segment={segment}
              setSegment={setSegment}
              softwares={softwares}
              setSoftwares={setSoftwares}
              technologies={technologies}
              setTechnologies={setTechnologies}
            />
          </div>
          <div className={step == 1 ? "" : "d-none"}>
            <FirstStep
              register={register}
              errors={errors}
              control={control}
              setValue={setValue}
              getValues={getValues}
              watch={watch}
              title="flashcard"
            />
          </div>
          <div className={step == 2 ? "" : "d-none"}>
            <SecondStep
              register={register}
              errors={errors}
              watch={watch}
              control={control}
              setValue={setValue}
              getValues={getValues}
              submitted={loading}
            />
          </div>
        </div>
        <div className="modal-footer">
          <div className="custom-footer-btns w-100">
            {step == 0 && (
              <Col lg={12} className="p-0">
                <Button
                  type="button"
                  className={"w-100"}
                  // className="custom-width-690"
                  onClick={nextStep}
                  disabled={zeroStepDisabled}
                >
                  Continue
                </Button>
              </Col>
            )}
            {step == 1 &&
            GetAuthUserLocalStorage()?.roles[0]?.id ==
              salonRoles.super_admin ? (
              <>
                <div>
                  <Button
                    type="button"
                    className={"btn-gray custom-width-332 margin-right-26"}
                    onClick={prevStep}
                  >
                    Back
                  </Button>
                </div>
                <div>
                  <Button
                    type="button"
                    className={"custom-width-332"}
                    onClick={nextStep}
                    disabled={firstStepDisabled}
                  >
                    Next
                  </Button>
                </div>
              </>
            ) : null}
            {step == 1 &&
            GetAuthUserLocalStorage()?.roles[0]?.id !=
              salonRoles.super_admin ? (
              <Col lg={12} className="p-0">
                <Button
                  type="button"
                  // className={"w-100"}
                  className="custom-width-690"
                  onClick={nextStep}
                  disabled={firstStepDisabled}
                >
                  Continue
                </Button>
              </Col>
            ) : null}
            {step == 2 && (
              <>
                <div>
                  <Button
                    type="button"
                    className={"btn-gray custom-width-332 margin-right-26"}
                    onClick={prevStep}
                  >
                    Back
                  </Button>
                </div>
                <div>
                  {loading && (
                    <Button
                      type="button"
                      className={"custom-width-332"}
                      disabled={true}
                    >
                      <div className="load black" />
                    </Button>
                  )}

                  {!loading && (
                    <Button
                      type="submit"
                      className={"custom-width-332"}
                      disabled={secondStepDisabled}
                    >
                      {flashCardAddEditForm.formType == "edit"
                        ? "Continue"
                        : "Continue"}
                    </Button>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </Form>
    </>
  );
};

export default FlashcardModalForm;
