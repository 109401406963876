import S3 from "react-aws-s3";
import { v4 as uuidv4 } from "uuid";
import {
  CheckFileUploadStorageAvailable,
  UpdateFileUploadStorageSizeAvailability,
} from "../services/global/global";
import { notification } from "antd";
import { Assets } from "../constants/images";
import {
  ToastCrossIcon,
  ToastGreenCheckIcon,
  ToastRedTrashIcon,
} from "../constants/svg";
import {
  ACTIVITY_TYPE,
  errorMessages,
  gallery_type,
  link_category_type,
  recurring_repeat_types,
  repeat_alert_type,
  roles,
  roles as salonRoles,
  s3Credential,
  salon_resources_type,
} from "./constants";
import Image from "antd/es/image";
import moment from "moment";
import { GetAuthUserLocalStorage } from "../services/localStorage/localStorage";
import { UploadGallery } from "../services/galleries/galleries";
import React from "react";
import mommentTimezone from "moment-timezone";

const authUser = GetAuthUserLocalStorage();
// window.Buffer = window.Buffer || require("buffer").Buffer;

export const UploadFileToS3 = async (file, dirName) => {
  let newFileName = Math.floor(Date.now() / 1000) + file.name;
  if (file.name.split("")[0] == ".") {
    newFileName = newFileName.slice(1, newFileName.length);
  }
  newFileName = newFileName.split(" ").join("-");

  const fileSize = file.size;

  // Check filesize
  if (fileSize > parseInt(s3Credential.fileSize, 10)) {
    return {
      success: false,
      message: errorMessages.fileSize,
    };
  }

  const config = {
    bucketName: s3Credential.bucketName,
    dirName,
    region: s3Credential.region,
    accessKeyId: s3Credential.accessKeyId,
    secretAccessKey: s3Credential.secretAccessKey,
    s3Url: s3Credential.s3EndPoint,
  };

  const ReactS3Client = new S3(config);
  let responseData = {};
  await ReactS3Client.uploadFile(file, newFileName).then((data) => {
    if (data && data.status == 204) {
      responseData = {
        success: true,
        message: errorMessages.fileSuccess,
        url: s3Credential.s3EndPoint + dirName + "/" + newFileName,
        mime_type: file.type,
      };
    } else {
      responseData = {
        success: false,
        message: errorMessages.fileError,
      };
    }
  });
  return responseData;
};

export const salonUploadToS3 = async (
  file,
  folderName,
  updateStorage = false,
  uploadToGallery = true,
  checkStorage = true,
) => {
  updateStorage =
    GetAuthUserLocalStorage()?.roles[0]?.id != salonRoles.super_admin;
  try {
    const uploadedFile = file;
    const originalFileOjb = uploadedFile.originFileObj
      ? uploadedFile.originFileObj
      : uploadedFile;
    const uploadImageSize = uploadedFile.size / 1024 + "KB";
    const uploadImageContent = uploadedFile.type;
    let s3UploadResponse;
    if (updateStorage && checkStorage) {
      const isStorageAvailableResponse = await CheckFileUploadStorageAvailable({
        size: uploadImageSize,
        content_type: uploadImageContent,
      });
      const { data, message, status } = isStorageAvailableResponse.data;
      if (status && data.allowed) {
        const config = {
          ...s3Credential,
          dirName: folderName,
        };
        const ReactS3Client = new S3(config);
        s3UploadResponse = await ReactS3Client.uploadFile(
          originalFileOjb,
          uuidv4(),
        );
        if (updateStorage) {
          await UpdateFileUploadStorageSizeAvailability(uploadImageSize);
        }
      } else {
        throw new Error(message);
      }
    } else {
      const config = {
        ...s3Credential,
        dirName: folderName,
      };
      const ReactS3Client = new S3(config);
      s3UploadResponse = await ReactS3Client.uploadFile(
        originalFileOjb,
        uuidv4(),
      );
    }
    if (uploadToGallery) {
      let name = uploadedFile.name;
      let media_type = getMediaType(uploadImageContent);
      const apiData = {
        name: name.split(".").slice(0, -1).join("."),
        type: media_type,
        salon_id: authUser?.salon_id,
        link: s3UploadResponse?.location,
        size: parseFloat(uploadedFile.size / 1024).toFixed(0) + " KB",
      };
      if (
        media_type &&
        authUser?.salon_id &&
        typeof authUser?.salon_id != "undefined"
      ) {
        UploadGallery(apiData);
      }
    }
    return {
      status: true,
      message: "Uploaded Successfully",
      data: {
        ...s3UploadResponse,
        size: uploadImageSize,
        sizeD: parseFloat(uploadedFile.size / 1024).toFixed(0) + " KB",
        contentType: uploadImageContent,
      },
    };
  } catch (e) {
    throw new Error(e.message);
    /* return {
      status: false,
      message: e.message,
      data: null,
    }; */
  }
};

export const getMediaType = (contentType) => {
  let media_type = null;
  if (contentType.includes("image")) {
    media_type = gallery_type.image;
  } else if (contentType.includes("video")) {
    media_type = gallery_type.video;
  } else if (contentType == "application/pdf") {
    media_type = gallery_type.pdf;
  } else if (contentType.includes("audio")) {
    media_type = gallery_type.audio;
  }
  return media_type;
};
export const truncateText = (text, maxLength) => {
  if (text.length <= maxLength) {
    return text; // No need to truncate
  } else {
    return text.substring(0, maxLength - 3) + "...";
  }
};

export const blobToFile = (audioBlob) => {
  const file = new File([audioBlob], "audio.mp3", { type: "audio/mp3" });
  return file;
};

export const convertTo12HourFormat = (time24) => {
  let [hours, minutes] = time24.split(":");
  minutes = minutes.substring(0, 2);
  let period = "AM";
  let formattedHours = parseInt(hours, 10);

  if (formattedHours >= 12) {
    period = "PM";
    if (formattedHours > 12) {
      formattedHours -= 12;
    }
  }

  return `${formattedHours}:${minutes} ${period}`;
};

export const toastMessage = (type, msg) => {
  if (type == "success") {
    notification.success({
      message: msg,
      placement: "bottomRight",
      className: "createdNotification",
      icon: <ToastGreenCheckIcon />,
      closeIcon: <ToastCrossIcon />,
    });
  } else if (type == "error") {
    notification.error({
      open: true,
      message: msg,
      placement: "bottomRight",
      className: "deletedNotification",
      icon: <img src={Assets.Warning} />,
      closeIcon: <ToastCrossIcon />,
    });
  } else if (type == "warning") {
    notification.error({
      message: msg,
      placement: "bottomRight",
      className: "deletedNotification",
      icon: <img src={Assets.Warning} />,
      closeIcon: <ToastCrossIcon />,
    });
  } else if (type == "delete") {
    notification.error({
      message: msg,
      placement: "bottomRight",
      className: "deletedNotification",
      icon: <ToastRedTrashIcon />,
      closeIcon: <ToastCrossIcon />,
    });
  }
};

export const getDayName = (day) => {
  let name;
  switch (day) {
    case 1:
      name = "Monday";
      break;

    case 2:
      name = "Tuesday";
      break;

    case 3:
      name = "Wednesday";
      break;

    case 4:
      name = "Thursday";
      break;

    case 5:
      name = "Friday";
      break;

    case 6:
      name = "Saturday";
      break;

    case 7:
      name = "Sunday";
      break;
  }
  return name;
};

export const getUloadTypeStringLiteral = (p) => {
  let r;

  switch (p) {
    case gallery_type.image:
      r = "Image";
      break;

    case gallery_type.video:
      r = "Video";
      break;

    case gallery_type.audio:
      r = "Audio";
      break;

    case gallery_type.folder:
      r = "Folder";
      break;

    case gallery_type.pdf:
      r = "PDF";
      break;
  }
  return r;
};

export const getRoleNameByID = (role) => {
  let name;
  switch (role) {
    case salonRoles.super_admin:
      name = "Super Admin";
      break;

    case salonRoles.admin:
      name = "Admin";
      break;

    case salonRoles.member:
      name = "Member";
      break;

    case salonRoles.owner:
      name = "Owner";
      break;
  }
  return name;
};

export const validURL = (str) => {
  let pattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i",
  ); // fragment locator
  return !!pattern.test(str);
};
export const convertUTCToLocal = (utcTimeString) => {
  const utcDate = new Date(utcTimeString);

  return utcDate.toLocaleString();
};

export const assignOnBoardingURL = (link_type, link_id) => {
  let url;
  switch (link_type) {
    case link_category_type.resources:
      url = "/resources/details/" + link_id;
      break;
    case link_category_type.documents:
      url = "/team/docs/details/" + link_id;
      break;
    case link_category_type.flashcard:
      url = "/flashcards/details/" + link_id;
      break;
    case link_category_type.surveys:
      url = "/surveys/details/" + link_id;
      break;
    case link_category_type.quizzes:
      url = "/quizzes/details/" + link_id;
      break;
    case link_category_type.events:
      url = "/events/details/" + link_id;
      break;
    default:
      url = "-";
      break;
  }
  return url;
};
export const showNumber = (number) => {
  if (number?.length > 10) {
    const last10Digits = number.slice(-10);
    return last10Digits;
  } else {
    return number;
  }
};

export function formatDate(isoDate) {
  const date = new Date(isoDate);
  const options = { year: "numeric", month: "short", day: "numeric" };
  return date.toLocaleDateString(undefined, options);
}

export const loader = (loading) => {
  if (loading) {
    return (
      <div className="loader-table" style={{ textAlign: "center" }}>
        <Image
          width={100}
          height={100}
          src={Assets.loadingGif}
          preview={false}
        />
      </div>
    );
  } else {
    return null;
  }
};

export const tableLoader = (loading) => {
  // Define an object inside the function
  const loader = {
    spinning: loading,
    fullscreen: false,
    indicator: (
      <div className="loader-table">
        <Image
          width={100}
          height={100}
          src={Assets.loadingGif}
          preview={false}
        />
      </div>
    ),
  };

  // Return the object
  return loader;
};
export const convertToUTC = (inputDate, inputTime, day = null) => {
  let datetime = new Date(inputDate + " " + inputTime);
  const utcDate = moment(datetime).format("YYYY-MM-DD");
  const utcTime = moment(datetime).format("HH:mm:ss");
  return { utcDate, utcTime, datetime };
};
export const convertTimezone = (dateString, timezone) => {
  if (!dateString) {
    return "";
  }
  const estTimezone = "America/New_York"; // Eastern Time (EST/EDT)
  const cstTimezone = "America/Chicago"; // Central Time (CST/CDT)
  const pstTimezone = "America/Los_Angeles"; // Pacific Time (PST/PDT)
  const mstTimezone = "America/Denver"; // Mountain Time (MST/MDT)
  const differentTimezone = {
    EST: estTimezone,
    CST: cstTimezone,
    PST: pstTimezone,
    MST: mstTimezone,
  };
  return mommentTimezone(dateString + "Z")
    .tz(differentTimezone[timezone])
    .format("h:mm A");
};
export const getTimefromString = (timeString) => {
  if (!timeString) {
    return "";
  }
  // Extracting hours, minutes, and seconds
  const hours = parseInt(timeString.substr(0, 2));
  let minutes = timeString.substr(3, 2);
  const seconds = timeString.substr(6, 2);

  // Convert hours to 12-hour format and determine AM or PM
  let ampm = "AM";
  let hours12 = hours;
  if (hours12 >= 12) {
    ampm = "PM";
    if (hours12 > 12) {
      hours12 -= 12;
    }
  }

  if (hours12 < 10) {
    hours12 = "0" + hours12;
  }
  // Formatting the time in AM/PM format
  return `${hours12}:${minutes} ${ampm}`;
};
export const getStringTime = (dateString) => {
  if (!dateString) {
    return "";
  }
  // Extracting hours, minutes, and seconds
  const hours = dateString.substr(11, 2);
  let minutes = dateString.substr(14, 2);
  // Convert hours to 12-hour format and determine AM or PM
  let ampm = "AM";
  let hours12 = parseInt(hours);
  if (hours12 >= 12) {
    ampm = "PM";
    if (hours12 > 12) {
      hours12 -= 12;
    }
  }
  if (hours12 < 10) {
    hours12 = "0" + hours12;
  }
  if (minutes < 10 && minutes != "00") {
    minutes = "0" + minutes;
  }
  return `${hours12}:${minutes} ${ampm}`;
};
export const convertToLocal = (
  inputDate,
  inputTime,
  inputDateTime = null,
  dateFormat = "MMM DD, yyyy",
  repeat_type = null,
) => {
  let datetime;
  if (inputDateTime) {
    datetime = new Date(inputDateTime);
  } else {
    if (!inputDate) {
      let todayDate = moment().format("YYYY-MM-DD");
      inputDate = `${todayDate}T14:25:11.000+00:00`;
    }
    let splitTime = inputTime.split(":");
    inputTime =
      splitTime[0].length == 1
        ? "0" + splitTime[0] + ":" + splitTime[1].slice(0, 2)
        : splitTime[0] + ":" + splitTime[1].slice(0, 2);
    datetime = new Date(inputDate.slice(0, 10) + "T" + inputTime + "Z");
  }
  const localDate = moment(datetime).format(dateFormat);
  const localTime = moment(datetime).format("h:mm A");
  let day = datetime.getDay();
  day = day == 0 ? 7 : day;
  if (repeat_type == repeat_alert_type.monthly) {
    day = datetime.getDate();
    day = day > 28 ? 31 : day;
  }
  return { localDate, localTime, datetime, day };
};
export const convertUTCToLocalTime = (inputTime) => {
  let utcTime = moment.utc(inputTime, "HH:mm:ss");
  let localTime = utcTime.local();

  let formattedTime = localTime.format("h:mm A");
  return formattedTime;
};

export const formatPhoneNumber = (phoneNumber) => {
  // Remove any non-digit characters from the input
  const cleaned = phoneNumber.replace(/\D/g, "");
  if (cleaned.length == 11) {
    return `+${cleaned.slice(0, 1)} ${cleaned.slice(1, 4)}-${cleaned.slice(
      4,
      7,
    )}-${cleaned.slice(7)}`;
  } else {
    // Handle invalid phone numbers
    return "Invalid phone number";
  }
};

export const formatTime = (seconds) => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;

  const formattedTime = `${String(minutes).padStart(2, "0")}:${String(
    remainingSeconds,
  ).padStart(2, "0")}`;

  return formattedTime;
};

export function formatString(input) {
  const words = input.split(" ");
  let comma;
  const formattedWords = words.map((word, index) => {
    if (word.toLowerCase() == "at") {
      comma = index;
      return "";
    } else if (word.toLowerCase() == "am" || word.toLowerCase() == "pm") {
      return word.toUpperCase();
    } else if (index == comma - 1) {
      return word.toUpperCase() + ",";
    } else {
      return word.charAt(0).toUpperCase() + word.slice(1);
    }
  });
  formattedWords[comma - 1] = formattedWords[comma - 1] + ",";

  return formattedWords.join(" ").trim();
}

export function getActivityTypeURL(value, id) {
  switch (value) {
    case ACTIVITY_TYPE.RESOURCES:
      return `/resources/details/${id}`;
    case ACTIVITY_TYPE.EVENTS:
      return `/events/details/${id}`;
    case ACTIVITY_TYPE.TASKS:
      return `/tasks/details/${id}`;
    case ACTIVITY_TYPE.GOALS:
      return `/goals/details/${id}`;
    case ACTIVITY_TYPE.DOCUMENTS:
      return `/team/docs`;
    case ACTIVITY_TYPE.SURVEYS:
      return `/surveys/details/${id}`;
    case ACTIVITY_TYPE.FLASHCARDS:
      return `/flashcards/details/${id}`;
    case ACTIVITY_TYPE.QUIZZES:
      return `/quizzes/details/${id}`;
    case ACTIVITY_TYPE.MEMBERS:
      return `/team/members/${id}`;
    case ACTIVITY_TYPE.ALERTS:
      return `/alerts/details/${id}`;
    case ACTIVITY_TYPE.REQUESTS:
      return `/surveys/details/${id}`;
    case ACTIVITY_TYPE.ONBOARDING:
      return `/team/onboarding/details/${id}`;
    case ACTIVITY_TYPE.USER_REFERRAL:
      return `/team/candidates`;
    case ACTIVITY_TYPE.SALONS:
      // return `/dashboard`;
      return `/organization/organization-detail/${id}`;
    case ACTIVITY_TYPE.USER_REWARD:
      return `/team/rewards`;
    default:
      return "/team/shoutouts";
  }
}

export function roleRights(role, owner) {
  switch (role) {
    case roles.admin:
      // Check for admin role conditions
      if (owner == true) {
        // Show all options for owner with ownerKey true
        return true;
      } else {
        // Show only share option for owner with ownerKey false
        return false;
      }

    case roles.owner:
      // Check for owner role conditions
      if (owner == true) {
        // Show all options for owner with ownerKey true
        return false;
      } else {
        // Show only share option for owner with ownerKey false
        return false;
      }
    case roles.member:
      return true;

    default:
      // For other roles, you may want to return true or false based on your logic
      return true;
  }
}
export function ShortenText(text, length) {
  return text.length > length ? text.slice(0, length) + "..." : text;
}
export function hasAccess(user, data = null) {
  // if (user && user?.id == authUser?.id || (GetUserRoleLocalStorage() == roles.super_admin)){
  let authUser = GetAuthUserLocalStorage();
  if (
    (user && user?.id == authUser?.id) ||
    user?.salon_id == authUser?.salon_id
  ) {
    return true;
  }
  return false;
}
export function renderContentWithLinks(content) {
  // Regular expression to match URLs
  let urlRegex = /(https?:\/\/[^\s]+)/g;

  // Replace URLs with anchor tags
  let contentWithAnchors = content.replace(urlRegex, function (url) {
    return '<a href="' + url + '" target="_blank">' + url + "</a>";
  });
  return <div dangerouslySetInnerHTML={{ __html: contentWithAnchors }} />;
}
export function roundStringToNearestInteger(str) {
  // Parse the string to a floating-point number
  const number = parseFloat(str);
  // Check if the conversion was successful
  if (!isNaN(number)) {
    // Round the number to the nearest integer
    const roundedNumber = Math.round(number);

    // Convert the rounded number back to a string if needed
    const roundedString = roundedNumber.toString();

    return roundedString;
  } else {
    // Handle the case where the string couldn't be converted to a number
    return "-";
  }
}
export function calPercentage(number, total) {
  let percentage = (number / total) * 100;
  if (!isFinite(percentage) || isNaN(percentage)) {
    return 0;
  }
  return percentage;
}

export function getNextDateByDay(day, inputTime, repeat_type, inputDate) {
  let date = new Date();
  let today = new Date();
  inputTime = formatTimeNew(inputTime);
  switch (repeat_type) {
    case recurring_repeat_types.bi_weekly:
    case recurring_repeat_types.weekly:
      date = new Date(date.setDate(date.getDate() - (date.getDay() - day)));
      date.setHours(inputTime.hours, inputTime.minutes);
      if (date <= today) {
        date.setDate(date.getDate() + 7);
      }
      break;
    case recurring_repeat_types.monthly:
      date.setHours(inputTime.hours, inputTime.minutes);
      if (day <= 28) {
        date.setDate(day);
        if (today > date) {
          date.setMonth(today.getMonth() + 1);
        }
      } else {
        date.setDate(
          new Date(today.getFullYear(), today.getMonth() + 1, 0).getDate(),
        );
        if (today > date) {
          date.setMonth(
            date.getMonth() + 1,
            new Date(today.getFullYear(), date.getMonth() + 2, 0).getDate(),
          );
        }
      }
      break;
    case recurring_repeat_types.annually:
      date = new Date(inputDate);
      date.setHours(inputTime.hours, inputTime.minutes);
      if (date <= today) {
        date.setFullYear(date.getFullYear() + 1);
      }
      break;
  }
  let year = date.getFullYear();
  let month = date.getMonth() + 1; // Months are zero-indexed
  month = month < 10 ? `0${month}` : month;
  let convertedDay = date.getDate();
  convertedDay = convertedDay < 10 ? `0${convertedDay}` : convertedDay;
  let hours = date.getHours();
  hours = hours < 10 ? `0${hours}` : hours;
  let minutes = date.getMinutes();
  minutes = minutes < 10 ? `0${minutes}` : minutes;
  let seconds = "00";
  return {
    date: `${year}-${month}-${convertedDay}`,
    time: `${hours}:${minutes}:${seconds}`,
    future_date: date,
  };
}

export function formatTimeNew(inputTime = "12:24 PM") {
  const timeArray = inputTime.split(" ");
  const time = timeArray[0];
  const meridiem = timeArray[1];

  let [hours, minutes] = time.split(":");
  hours = parseInt(hours, 10);

  if (meridiem == "PM" && hours != 12) {
    hours += 12;
  } else if (meridiem == "AM" && hours == 12) {
    hours = 0;
  }
  return { hours, minutes };
}
export function getTabNoByType(type) {
  let tabNo = 0;
  switch (type) {
    case salon_resources_type.audio:
      tabNo = 3;
      break;
    case salon_resources_type.video:
      tabNo = 2;
      break;
    case salon_resources_type.pdf:
      tabNo = 1;
      break;
  }
  return tabNo;
}
export function getNumberTitle(number) {
  if (typeof number != "number" || isNaN(number) || number < 1) {
    return "Invalid input";
  }

  const lastTwoDigits = number % 100;

  if (lastTwoDigits >= 11 && lastTwoDigits <= 13) {
    return number + "th";
  }

  const lastDigit = number % 10;
  const suffix =
    lastDigit == 1
      ? "st"
      : lastDigit == 2
        ? "nd"
        : lastDigit == 3
          ? "rd"
          : "th";

  return number + suffix;
}

export const checkPDFBySrc = (src) => {
  return src?.endsWith(".pdf");
};

export const getRecurringDate = ({ start, end, type }) => {
  const types = { ONETIME: 10, WEEKLY: 20, BIWEEKLY: 30, MONTHLY: 40 };

  if ([types.WEEKLY, types.BIWEEKLY].includes(type)) {
    const day = moment(start).day();
    const currentDay = moment().day();
    const toAdd = type === types.BIWEEKLY ? 1 : 0;
    const week = currentDay < day ? 0 + toAdd : 1 + toAdd;
    return moment().day(day).add(week, "week");
  }
  if (type === types.MONTHLY) {
    const date = moment(start).date();
    const currentDate = moment().date();
    return moment()
      .date(date)
      .add(date > currentDate ? 0 : 1, "month");
  }

  return moment(end);
};
