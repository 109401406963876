import React from "react";
import { Assets } from "../../constants/images";

const AvatarImage = ({
  name,
  src,
  placeholder,
  size,
  className = "",
  isSVG,
}) => {
  const getInitials = () => {
    if (name?.length > 0) {
      const transformChars = name
        .split(" ")
        .map((char) => char[0])
        .join("");
      return transformChars;
    } else {
      return (
        <img
          src={placeholder || Assets.ProfilePlaceholder}
          className="avatar-img"
        />
      );
    }
  };

  return (
    <div
      className="avatar-wrapper"
      style={{
        height: `${size || 48}px`,
        width: `${size || 48}px`,
      }}
    >
      {isSVG && <img src={src} className={`avatar-img ${className}`} />}
      {!isSVG && src && !src?.includes("/profile-placeholder") ? (
        <img src={src} className={`avatar-img ${className}`} />
      ) : (
        <div className="avatar-characters">{getInitials()}</div>
      )}
    </div>
  );
};

export default AvatarImage;
