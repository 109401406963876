import { Progress } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useLocation } from "react-router-dom";
import {
  DashboardIcon,
  DashboardIconFilled,
} from "../../constants/sidebarIcons";
import {
  AddUserIcon,
  CloudIcon,
  EventsIcon,
  EventsIconFilled,
  FlashcardIcon,
  FlashcardIconFilled,
  GoalsIcon,
  GoalsIconFilled,
  HelpIcon,
  PushAlertIcon,
  PushAlertIconFilled,
  QuizIcon,
  QuizIconFilled,
  RequestIcon,
  RequestIconFilled,
  ResourceIcon,
  ResourceIconFilled,
  SurveyIcon,
  SurveyIconFilled,
  TaskIcon,
  TaskIconFilled,
  TeamIcon,
  TeamIconFilled,
  WhiteLogo,
} from "../../constants/svg";
import { setMemberAddEditForm } from "../../store/slices/memberSlice";
import InviteStaffModal from "../Modals/InviteStaffModal";
import UserLimitModal from "../Modals/UserLimitModal";
import Helppopup from "../pages/Help/helppopup";
import TourBox from "./TourBox";
import { roles as salonRoles } from "../../utils/constants";
import { setCurrentSubscriptionDetail } from "../../store/slices/subscriptionSlice";
import { GetCurrentSubscription } from "../../services/subscription/subscription";
import {
  GetAuthUserLocalStorage,
  SetAuthUserLocalStorage,
} from "../../services/localStorage/localStorage";
import AddSalonModal from "../Modals/AddSalonModal";
import { UpdateUser } from "../../services/users/users";

function Sidebar(props) {
  const { ref1, ref2, ref3, ref4, activeStep, setActiveStep } = props;
  const [clicked, setClicked] = useState(false);
  const { currentSubscriptionDetail, callCheckSubscriptionAPI } = useSelector(
    (state) => state.subscription,
  );
  const [showLimitModal, setShowLimitModal] = useState(false);
  const location = useLocation();
  const handleClickEmptyHref = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setClicked(true);
  };
  const { salon } = useSelector((state) => state.global);
  const { me } = useSelector((state) => state?.auth);
  const dispatch = useDispatch();
  const {
    reRenderTable,
    sorting,
    loading,
    memberTableData,
    actionButtonDisable,
    memberAddEditForm,
  } = useSelector((state) => state.member);
  const [progress, setProgress] = useState(0);
  const helppopupRef = useRef(null);
  const originalNavRef = useRef(null);

  const getAvailableStoragePercentage = () => {
    const total = currentSubscriptionDetail?.meta?.total_storage || 0;
    const used = currentSubscriptionDetail?.salon?.storage_available_gbs || 0;
    let freeStoragePercentage = ((total - used) / total) * 100;
    let usedPercentage = 100 - freeStoragePercentage;
    setProgress(usedPercentage);
  };
  const authUser = GetAuthUserLocalStorage();

  //= ====== Function to skip tour ==========
  const handleSkipTour = async () => {
    let apiData = {
      show_tab_tour: 1,
    };
    setActiveStep(null);
    document.body.classList.remove("stepFocused");
    let res = await UpdateUser(authUser?.id, apiData);
    SetAuthUserLocalStorage(res?.data?.data);
  };

  // useEffect(() => {
  //   getCurrentSubscription();
  // }, [callCheckSubscriptionAPI]);

  useEffect(() => {
    if (me?.data?.show_tab_tour) {
      setActiveStep(null);
      document.body.classList.remove("stepFocused");
    } else if (me?.data && !me?.data?.show_tab_tour) {
      setActiveStep(0);
      document.body.classList.add("stepFocused");
    }
  }, [me]);

  const getCurrentSubscription = async () => {
    try {
      const response = await GetCurrentSubscription(
        GetAuthUserLocalStorage()?.salon?.id,
      );
      const { data, message, status } = response.data;
      if (!data) throw new Error();
      dispatch(setCurrentSubscriptionDetail(data));
    } catch (err) {}
  };

  const handleClickOutside = (event) => {
    if (
      helppopupRef.current &&
      !helppopupRef.current.contains(event.target) &&
      originalNavRef.current &&
      !originalNavRef.current.contains(event.target)
    ) {
      setClicked(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    getAvailableStoragePercentage();
  }, [currentSubscriptionDetail, callCheckSubscriptionAPI]);
  return (
    <>
      <aside className="sidebarWrapper">
        <div className="sidebarHead">
          <NavLink to="/dashboard" onClick={(e) => e.stopPropagation()}>
            <WhiteLogo />
            {/* <SideMenuWhite/> */}
          </NavLink>
        </div>
        <div className="sidebarBody">
          <ul
            className={`sidebarList ${activeStep != null && "sidebarOverflow"}`}
          >
            <li className={"sidebarItem"}>
              <NavLink to="/dashboard" onClick={(e) => e.stopPropagation()}>
                <div className="icon activeIcon">
                  <DashboardIconFilled />
                </div>
                <div className="icon nonActiveIcon">
                  <DashboardIcon />
                </div>
                <p className="sidebarText">
                  {GetAuthUserLocalStorage().roles[0].id ==
                    salonRoles.super_admin && "Home"}
                  {GetAuthUserLocalStorage().roles[0].id == salonRoles.admin &&
                    "Dashboard"}
                </p>
              </NavLink>
            </li>
            <li
              className={`sidebarItem ${activeStep == 3 && "StepActive"}`}
              ref={ref4}
            >
              <NavLink
                to={"/alerts"}
                onClick={(e) => e.stopPropagation()}
                className={
                  (activeStep == 3 && "active") ||
                  (location.pathname.includes("/alerts") ? "active" : "")
                }
              >
                <div className="icon activeIcon">
                  <PushAlertIconFilled />
                </div>
                <div className="icon nonActiveIcon">
                  <PushAlertIcon />
                </div>
                <p className="sidebarText">Alerts</p>
              </NavLink>
              <TourBox
                heading={"Send Notifications"}
                para={
                  "Create and send one-time, scheduled, and recurring alerts straight to staff."
                }
                backFunc={() => {
                  setActiveStep(2);
                }}
                nextFunc={() => {
                  setActiveStep(4);
                }}
                stepNumber={"4"}
                skipFunc={handleSkipTour}
                skipText={"Don’t show again"}
              />
            </li>
            <li
              className={`sidebarItem ${activeStep == 2 && "StepActive"}`}
              ref={ref3}
            >
              <NavLink
                onClick={(e) => e.stopPropagation()}
                to={"/resources"}
                className={
                  (activeStep == 2 && "active") ||
                  (location.pathname.includes("/resources") ? "active" : "")
                }
              >
                <div className="icon activeIcon">
                  <ResourceIconFilled />
                </div>
                <div className="icon nonActiveIcon">
                  <ResourceIcon />
                </div>
                <p className="sidebarText">Resources</p>
              </NavLink>
              <TourBox
                heading={"Upload Resources"}
                para={
                  "Upload and share training resources like PDF guides, video tutorials, and more."
                }
                backFunc={() => {
                  setActiveStep(1);
                }}
                nextFunc={() => {
                  setActiveStep(3);
                }}
                stepNumber={"3"}
                skipFunc={handleSkipTour}
                skipText={"Don’t show again"}
              />
            </li>

            <li className={`sidebarItem`}>
              <NavLink
                onClick={(e) => e.stopPropagation()}
                to={"/events"}
                className={location.pathname.includes("/event") ? "active" : ""}
              >
                <div className="icon activeIcon">
                  <EventsIconFilled />
                </div>
                <div className="icon nonActiveIcon">
                  <EventsIcon />
                </div>
                <p className="sidebarText">Events</p>
              </NavLink>
              <TourBox
                heading={"Add Training Resources"}
                para={
                  "Upload and manage your PDF handouts,\n" +
                  "video tutorials, audio files, and more."
                }
                backFunc={() => {
                  setActiveStep(1);
                }}
                nextFunc={() => {
                  setActiveStep(3);
                }}
                stepNumber={"3"}
                skipFunc={handleSkipTour}
                skipText={"Don’t show again"}
              />
            </li>

            {GetAuthUserLocalStorage().roles[0].id == salonRoles.admin && (
              <li className={"sidebarItem"}>
                <NavLink
                  onClick={(e) => e.stopPropagation()}
                  to={"/goals"}
                  className={
                    location.pathname.includes("/goals") ? "active" : ""
                  }
                >
                  <div className="icon activeIcon">
                    <GoalsIconFilled />
                  </div>
                  <div className="icon nonActiveIcon">
                    <GoalsIcon />
                  </div>
                  <p className="sidebarText">Goals</p>
                </NavLink>
              </li>
            )}

            <li className={"sidebarItem"}>
              <NavLink
                onClick={(e) => e.stopPropagation()}
                to={"/flashcards"}
                className={
                  location.pathname.startsWith("/flashcard") ? "active" : ""
                }
              >
                <div className="icon activeIcon">
                  <FlashcardIconFilled />
                </div>
                <div className="icon nonActiveIcon">
                  <FlashcardIcon />
                </div>
                <p className="sidebarText">Flashcards</p>
              </NavLink>
            </li>

            {GetAuthUserLocalStorage().roles[0].id ==
              salonRoles.super_admin && (
              <li
                className={`sidebarItem ${activeStep == 1 && "StepActive"}`}
                ref={ref2}
              >
                <NavLink
                  onClick={(e) => e.stopPropagation()}
                  to={"/organization"}
                  className={
                    location.pathname.includes("/organization") ? "active" : ""
                  }
                >
                  <div className="icon activeIcon">
                    <TeamIconFilled />
                  </div>
                  <div className="icon nonActiveIcon">
                    <TeamIcon />
                  </div>
                  <p className="sidebarText">Organizations</p>
                </NavLink>
              </li>
            )}

            {GetAuthUserLocalStorage().roles[0].id ==
              salonRoles.super_admin && (
              <li className={"sidebarItem"}>
                <NavLink
                  onClick={(e) => e.stopPropagation()}
                  to={"/tickets"}
                  className={
                    location.pathname.includes("/tickets") ? "active" : ""
                  }
                >
                  <div className="icon activeIcon">
                    <RequestIconFilled />
                  </div>
                  <div className="icon nonActiveIcon">
                    <RequestIcon />
                  </div>
                  <p className="sidebarText">Tickets</p>
                </NavLink>
              </li>
            )}

            {GetAuthUserLocalStorage().roles[0].id == salonRoles.admin && (
              <li className={"sidebarItem"}>
                <NavLink
                  onClick={(e) => e.stopPropagation()}
                  to={"/tasks"}
                  className={
                    location.pathname.includes("/tasks") ? "active" : ""
                  }
                >
                  <div className="icon activeIcon">
                    <TaskIconFilled />
                  </div>
                  <div className="icon nonActiveIcon">
                    <TaskIcon />
                  </div>
                  <p className="sidebarText">Tasks</p>
                </NavLink>
              </li>
            )}

            <li className={"sidebarItem"}>
              <NavLink
                onClick={(e) => e.stopPropagation()}
                to={"/surveys"}
                className={
                  location.pathname.includes("/survey") ? "active" : ""
                }
              >
                <div className="icon activeIcon">
                  <SurveyIconFilled />
                </div>
                <div className="icon nonActiveIcon">
                  <SurveyIcon />
                </div>
                <p className="sidebarText">Surveys</p>
              </NavLink>
            </li>

            <li className={"sidebarItem"}>
              <NavLink
                onClick={(e) => e.stopPropagation()}
                to={"/quizzes"}
                className={location.pathname.includes("/quiz") ? "active" : ""}
              >
                <div className="icon activeIcon">
                  <QuizIconFilled />
                </div>
                <div className="icon nonActiveIcon">
                  <QuizIcon />
                </div>
                <p className="sidebarText">Quizzes</p>
              </NavLink>
            </li>

            {GetAuthUserLocalStorage().roles[0].id == salonRoles.admin && (
              <li
                className={`sidebarItem ${activeStep == 1 && "StepActive"}`}
                ref={ref2}
              >
                <NavLink
                  onClick={(e) => e.stopPropagation()}
                  to={"/team/members"}
                  className={
                    (activeStep == 1 ? "active" : "") ||
                    (location.pathname.includes("/teams") ? "active" : "")
                  }
                >
                  <div className="icon activeIcon">
                    <TeamIconFilled />
                  </div>
                  <div className="icon nonActiveIcon">
                    <TeamIcon />
                  </div>
                  <p className="sidebarText">Team</p>
                </NavLink>
                <TourBox
                  heading={"Manage Employees"}
                  para={
                    "Access member permissions and rewards, \n" +
                    "upload contracts, policies, and more."
                  }
                  backFunc={() => {
                    setActiveStep(0);
                  }}
                  nextFunc={() => {
                    setActiveStep(2);
                  }}
                  stepNumber={"2"}
                  skipFunc={handleSkipTour}
                  skipText={"Don’t show again"}
                />
              </li>
            )}
            {GetAuthUserLocalStorage().roles[0].id == salonRoles.admin && (
              <li className={"sidebarItem"}>
                <NavLink to={"/requests"} onClick={(e) => e.stopPropagation()}>
                  <div className="icon activeIcon">
                    <RequestIconFilled />
                  </div>
                  <div className="icon nonActiveIcon">
                    <RequestIcon />
                  </div>
                  <p className="sidebarText">Requests</p>
                </NavLink>
              </li>
            )}

            <li className={"sidebarItem"}>
              <NavLink
                to={""}
                onClick={handleClickEmptyHref}
                className={clicked ? "active" : ""}
                ref={originalNavRef}
              >
                <div className="icon activeIcon">
                  <HelpIcon />
                </div>
                <div className="icon nonActiveIcon">
                  <HelpIcon />
                </div>
                <p className="sidebarText">Help</p>
              </NavLink>
            </li>
          </ul>
        </div>
        <div className="sidebarFooter">
          {GetAuthUserLocalStorage()?.roles[0]?.id !=
            salonRoles.super_admin && (
            <div className="storageBox">
              <span>
                <CloudIcon /> Storage
              </span>
              <Progress percent={progress} />
              <p>
                <span>
                  {currentSubscriptionDetail?.salon?.storage_available_gbs.toFixed(
                    2,
                  ) || 0}{" "}
                  GB of{" "}
                  {currentSubscriptionDetail?.meta?.total_storage < 999
                    ? currentSubscriptionDetail?.meta?.total_storage
                    : "Unlimited" || 0}{" "}
                  GB used
                </span>

                <span>
                  {GetAuthUserLocalStorage().roles[0].id ==
                    salonRoles.admin && (
                    <NavLink
                      to="/settings/storage"
                      onClick={(e) => e.stopPropagation()}
                    >
                      Manage
                    </NavLink>
                  )}

                  {GetAuthUserLocalStorage().roles[0].id ==
                    salonRoles.super_admin && (
                    <NavLink
                      to="/settings/profile"
                      onClick={(e) => e.stopPropagation()}
                    >
                      Manage
                    </NavLink>
                  )}
                </span>
              </p>
            </div>
          )}
          <div
            className={
              activeStep == 0
                ? "position-relative StepActive"
                : "position-relative "
            }
          >
            <Button
              className={
                activeStep == 0 ? "mb-0 invite-btn active" : "mb-0 invite-btn"
              }
              type={"submit"}
              ref={ref1}
              onClick={() => {
                if (GetAuthUserLocalStorage().roles[0].id == salonRoles.admin) {
                  if (salon.meta.members >= salon.meta.total_members) {
                    setShowLimitModal(true);
                    return;
                  }
                  dispatch(
                    setMemberAddEditForm({
                      show: true,
                      formType: "create",
                      isSalonCreate: 0,
                    }),
                  );
                } else {
                  dispatch(
                    setMemberAddEditForm({
                      show: true,
                      formType: "create",
                      isSalonCreate: 1,
                    }),
                  );
                }
              }}
            >
              <AddUserIcon />
              {GetAuthUserLocalStorage().roles[0].id ==
                salonRoles.super_admin && "Create Account"}
              {GetAuthUserLocalStorage().roles[0].id == salonRoles.admin &&
                "Invite"}
            </Button>
            <TourBox
              heading={"Invite Members"}
              para={
                "Send members an SMS invitation to join your team on Salon Symphony."
              }
              backFunc={false}
              nextFunc={() => {
                setActiveStep(1);
              }}
              stepNumber={"1"}
              skipFunc={handleSkipTour}
              skipText={"Don’t show again"}
            />
          </div>
        </div>
      </aside>
      <div
        className={clicked ? "help_popup active" : "help_popup"}
        ref={helppopupRef}
      >
        <Helppopup setClickedprop={setClicked} />
      </div>
      <UserLimitModal
        show={showLimitModal}
        onHide={() => setShowLimitModal(false)}
      />
      {(GetAuthUserLocalStorage().roles[0].id != salonRoles.super_admin ||
        !memberAddEditForm?.isSalonCreate) &&
      memberAddEditForm.show ? (
        <InviteStaffModal
          show={memberAddEditForm.show}
          onHide={() => {
            dispatch(
              setMemberAddEditForm({
                show: false,
                formType: null,
                isSalonCreate: 0,
              }),
            );
          }}
          setModalShow={(e) => {
            dispatch(
              setMemberAddEditForm({
                show: false,
                formType: null,
                isSalonCreate: 0,
              }),
            );
          }}
          size={"lg"}
        />
      ) : (
        <AddSalonModal
          show={memberAddEditForm.show}
          onHide={() => {
            dispatch(
              setMemberAddEditForm({
                show: false,
                formType: null,
                isSalonCreate: 0,
              }),
            );
          }}
          setModalShow={(e) => {
            dispatch(
              setMemberAddEditForm({
                show: false,
                formType: null,
                isSalonCreate: 0,
              }),
            );
          }}
          size={"lg"}
        />
      )}
    </>
  );
}

export default Sidebar;
