import React from "react";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Route,
  RouterProvider,
} from "react-router-dom";
// Layouts Imports
import AuthLayout from "./layouts/AuthLayout";
import SignIn from "./pages/AuthPages/SignIn";
import ForgotPassword from "./pages/AuthPages/forgot-password";
import ResetCode from "./pages/AuthPages/ResetCode";
import ResetPassword from "./pages/AuthPages/ResetPassword";
import VerifyOtpScreen from "./pages/AuthPages/VerifyOtp";
import VerifyEmail from "./pages/AuthPages/VerifyEmail";
import AccountSetup from "./pages/AuthPages/AccountSetup";
import BusinessDetail from "./pages/AuthPages/BussinessDetail";
import DashboardPage from "./pages/DashboardPage/DashboardPage";
import PushAlertPage from "./pages/PushAlertPage/PushAlertPage";
import AddLocation from "./pages/AuthPages/AddLocation";
import BioInfo from "./pages/AuthPages/BioInfo";

import EventsPage from "./pages/EventsPage/EventsPage";
import EventDetailPage from "./pages/EventDetailPage/EventDetailPage";
import GoalsPage from "./pages/GoalsPage/GoalsPage";
import GoalsDetailPage from "./pages/GoalDetailPage/GoalsDetailPage";
import TeamLayout from "./layouts/TeamLayout";
import TasksPage from "./pages/Tasks/TasksPage";
import TaskDetailPage from "./pages/TaskDetailPage/TaskDetailPage";
import PushAlertDetailPage from "./pages/PushAlertDetailPage/PushAlertDetailPage";
import SettingLayout from "./layouts/SettingLayout";
import ShoutoutPage from "./pages/TeamPages/Shoutouts";
import MembersPage from "./pages/TeamPages/Members";
import NewHireChecklist from "./pages/TeamPages/NewHireChecklist";
import HireDetailPage from "./pages/HireDetaliPage/HireDetailPage";
import CandidateRefferals from "./pages/TeamPages/CandidateRefferals";
import DocumentPage from "./pages/TeamPages/Document";
import ResourcesPage from "./pages/ResourcesPage/ResourcesPage";
import ResourceDetailPage from "./pages/ResourcesDetailPage/ResourceDetailPage";
import QuizzesPage from "./pages/QuizzesPage/QuizzesPage";
import QuizDetail from "./pages/QuizDetail/QuizDetail";
import FlashCardsPage from "./pages/FlashCardsPage/FlashCardsPage";
import FlashCardDetailPage from "./pages/FlashCardDetailPage/FlashCardDetailPage";
import SurveysPage from "./pages/SurveysPage/SurveysPage";
import SurveyDetailPage from "./pages/SurveyDetailPage/SurveyDetailPage";
import Rewards from "./pages/TeamPages/Rewards";
import GroupsPage from "./pages/TeamPages/GroupsPage";
import GroupDetailPage from "./pages/GroupDetailPage/GroupDetailPage";
import SurveysBuilder from "./pages/SurveysBuilder/SurveysBuilder";
import RequestPage from "./pages/RequestPage/RequestPage";
import MemberDetail from "./pages/MemberDetailPage/MemberDetail";
// Setting pages
import BillingPage from "./pages/SettingsPages/BillingPage";
import GalleryPage from "./pages/SettingsPages/GalleryPage";
import LocationPage from "./pages/SettingsPages/LocationPage";
import StoragePage from "./pages/SettingsPages/StoragePage";
import ProfilePage from "./pages/SettingsPages/ProfilePage";
import ReferralPage from "./pages/SettingsPages/ReferralPage";
import RecentSearch from "./components/pages/Search/RecentSearch";
import FlashCardBuilder from "./pages/FlashCardBuilder/FlashCardBuilder";
import DocumentDetail from "./pages/TeamPages/TeamDetailPages/DocumentDetail";
import LocationDetail from "./pages/SettingsPages/LocationDetail/LocationDetail";
import Activity from "./pages/Activity/Activity";
import RequestDetail from "./pages/RequestDetail/RequestDetail";
import Chat from "./pages/Chat/Chat";
import QuizBuilder from "./pages/QuizBuilder/QuizBuilder";
import TestPage from "./pages/TeamPages/TestPage";
import FormTestPage from "./pages/FormTestPage";
import GalleryDetailPage from "./pages/SettingsPages/GalleryDetailPage";
import OrganizationPage from "./pages/OrganizationPage/OrganizationPage";
import OrganizationDetailPage from "./components/pages/Organization/OrganizationDetail";
import TicketPage from "./pages/TicketPage/TicketPage";
import BlockerLayout from "./layouts/BlockerLayout";
import MainLayout from "./layouts/MainLayout";
import ErrorPage from "./pages/ErrorPage";
import CustomErrorBoundary from "./components/CustomErrorBoundary";

function RouteConfig(props) {
  let router = createBrowserRouter(
    createRoutesFromElements(
      <Route>
        <Route element={<AuthLayout />}>
          <Route
            exact
            path="/"
            element={<Navigate to="/login" replace />}
            errorElement={<CustomErrorBoundary />}
          />
          <Route
            exact
            path="/login"
            element={<SignIn />}
            errorElement={<CustomErrorBoundary />}
          />
          <Route
            exact
            path="/verify-otp"
            element={<VerifyOtpScreen />}
            errorElement={<CustomErrorBoundary />}
          />
          <Route
            exact
            path="/verify-email"
            element={<VerifyEmail />}
            errorElement={<CustomErrorBoundary />}
          />
          <Route
            exact
            path="/forgot-password"
            element={<ForgotPassword />}
            errorElement={<CustomErrorBoundary />}
          />
          <Route
            exact
            path="/reset-code"
            element={<ResetCode />}
            errorElement={<CustomErrorBoundary />}
          />
          <Route
            exact
            path="/reset-password"
            element={<ResetPassword />}
            errorElement={<CustomErrorBoundary />}
          />
          {/* <Route exact path="/account-setup" element={<AccountSetup />} /> */}
          <Route
            exact
            path="/setup"
            element={<AccountSetup />}
            errorElement={<CustomErrorBoundary />}
          />
          <Route
            exact
            path="/business-details"
            element={<BusinessDetail />}
            errorElement={<CustomErrorBoundary />}
          />
          <Route
            exact
            path="/add-locations"
            element={<AddLocation />}
            errorElement={<CustomErrorBoundary />}
          />
          <Route
            exact
            path="/add-bio"
            element={<BioInfo />}
            errorElement={<CustomErrorBoundary />}
          />
        </Route>

        <Route element={<BlockerLayout />}>
          <Route element={<MainLayout />}>
            <Route
              exact
              path="/dashboard"
              element={<DashboardPage />}
              errorElement={<CustomErrorBoundary />}
            />
            <Route
              exact
              path="/testpage"
              element={<TestPage />}
              errorElement={<CustomErrorBoundary />}
            />
            <Route
              exact
              path="/inbox"
              element={<Chat />}
              errorElement={<CustomErrorBoundary />}
            />
            <Route
              exact
              path="/requests"
              element={<RequestPage />}
              errorElement={<CustomErrorBoundary />}
            />
            <Route
              exact
              path="/requests/details/:id"
              element={<RequestDetail />}
              errorElement={<CustomErrorBoundary />}
            />
            <Route
              exact
              path="/surveys/details/:id"
              element={<SurveyDetailPage />}
              errorElement={<CustomErrorBoundary />}
            />
            <Route
              exact
              path="/surveys"
              element={<SurveysPage />}
              errorElement={<CustomErrorBoundary />}
            />
            <Route
              exact
              path="/search/:keyword"
              element={<RecentSearch />}
              errorElement={<CustomErrorBoundary />}
            />
            <Route
              exact
              path="/flashcards"
              element={<FlashCardsPage />}
              errorElement={<CustomErrorBoundary />}
            />
            <Route
              exact
              path="/flashcards/details/:id"
              element={<FlashCardDetailPage />}
              errorElement={<CustomErrorBoundary />}
            />
            <Route
              exact
              path="/quizzes/details/:id"
              element={<QuizDetail />}
              errorElement={<CustomErrorBoundary />}
            />
            <Route
              exact
              path="/quizzes"
              element={<QuizzesPage />}
              errorElement={<CustomErrorBoundary />}
            />
            <Route
              exact
              path="/resources/details/:id"
              element={<ResourceDetailPage />}
              errorElement={<CustomErrorBoundary />}
            />
            <Route
              exact
              path="/alerts"
              element={<PushAlertPage />}
              errorElement={<CustomErrorBoundary />}
            />
            <Route
              exact
              path="/alerts/details/:id"
              element={<PushAlertDetailPage />}
              errorElement={<CustomErrorBoundary />}
            />

            <Route
              exact
              path="/activity"
              element={<Activity />}
              errorElement={<CustomErrorBoundary />}
            />
            <Route
              exact
              path="/events"
              element={<EventsPage />}
              errorElement={<CustomErrorBoundary />}
            />
            <Route
              exact
              path="/events/details/:id"
              element={<EventDetailPage />}
              errorElement={<CustomErrorBoundary />}
            />
            <Route
              exact
              path="/team/docs/details/:id"
              element={<DocumentDetail />}
              errorElement={<CustomErrorBoundary />}
            />
            <Route
              exact
              path="/surveysbuilder/:id"
              element={<SurveysBuilder />}
              errorElement={<CustomErrorBoundary />}
            />
            <Route
              exact
              path="/quiz/quizbuilder/:id"
              element={<QuizBuilder />}
              errorElement={<CustomErrorBoundary />}
            />
            <Route
              exact
              path="/goals"
              element={<GoalsPage />}
              errorElement={<CustomErrorBoundary />}
            />
            <Route
              exact
              path="/goals/details/:id"
              element={<GoalsDetailPage />}
              errorElement={<CustomErrorBoundary />}
            />
            <Route
              exact
              path="/tasks"
              element={<TasksPage />}
              errorElement={<CustomErrorBoundary />}
            />
            <Route
              exact
              path="/tasks/details/:id"
              element={<TaskDetailPage />}
              errorElement={<CustomErrorBoundary />}
            />
            <Route
              exact
              path="/flashcard/flash-card-builder/:id"
              element={<FlashCardBuilder />}
              errorElement={<CustomErrorBoundary />}
            />
            <Route
              exact
              path="/organization"
              element={<OrganizationPage />}
              errorElement={<CustomErrorBoundary />}
            />
            <Route
              exact
              path="/organization/organization-detail/:id"
              element={<OrganizationDetailPage />}
              errorElement={<CustomErrorBoundary />}
            />

            <Route
              exact
              path="/tickets"
              element={<TicketPage />}
              errorElement={<CustomErrorBoundary />}
            />
            <Route
              exact
              path="/tickets/tickets-detail"
              element={<TaskDetailPage />}
              errorElement={<CustomErrorBoundary />}
            />
            {/* Team Layout path start here */}
            <Route element={<TeamLayout />}>
              <Route
                exact
                path="/team/groups"
                element={<GroupsPage />}
                errorElement={<CustomErrorBoundary />}
              />
              <Route
                exact
                path="/team/rewards"
                element={<Rewards />}
                errorElement={<CustomErrorBoundary />}
              />
              <Route
                exact
                path="/team/shoutouts"
                element={<ShoutoutPage />}
                errorElement={<CustomErrorBoundary />}
              />
              <Route
                exact
                path="/team/members"
                element={<MembersPage />}
                errorElement={<CustomErrorBoundary />}
              />
              <Route
                exact
                path="/team/onboarding"
                element={<NewHireChecklist />}
                errorElement={<CustomErrorBoundary />}
              />
              <Route
                exact
                path="/team/candidates"
                element={<CandidateRefferals />}
                errorElement={<CustomErrorBoundary />}
              />
              <Route
                exact
                path="/team/docs"
                element={<DocumentPage />}
                errorElement={<CustomErrorBoundary />}
              />
              <Route
                exact
                path="/team/groups/:id"
                element={<GroupDetailPage />}
                errorElement={<CustomErrorBoundary />}
              ></Route>

              <Route
                exact
                path="/team/members/:id"
                element={<MemberDetail />}
                errorElement={<CustomErrorBoundary />}
              />
            </Route>
            <Route
              exact
              path="/team/onboarding/details/:id"
              element={<HireDetailPage />}
              errorElement={<CustomErrorBoundary />}
            />
            <Route
              exact
              path="/resources"
              element={<ResourcesPage />}
              errorElement={<CustomErrorBoundary />}
            />
            {/* Settings Pages goes */}
            <Route element={<SettingLayout />}>
              <Route
                exact
                path="/settings/billing"
                element={<BillingPage />}
                errorElement={<CustomErrorBoundary />}
              />
              <Route
                exact
                path="/settings/profile"
                element={<ProfilePage />}
                errorElement={<CustomErrorBoundary />}
              />
              <Route
                exact
                path="/setting/referral"
                element={<ReferralPage />}
                errorElement={<CustomErrorBoundary />}
              />
              <Route
                exact
                path="/settings/locations"
                element={<LocationPage />}
                errorElement={<CustomErrorBoundary />}
              />
              <Route
                exact
                path="/settings/uploads"
                element={<GalleryPage />}
                errorElement={<CustomErrorBoundary />}
              />
              <Route
                exact
                path="/settings/storage"
                element={<StoragePage />}
                errorElement={<CustomErrorBoundary />}
              />
              <Route
                exact
                path="/settings/locations/:id"
                element={<LocationDetail />}
                errorElement={<CustomErrorBoundary />}
              ></Route>
              <Route
                exact
                path="/settings/uploads/details/:id"
                element={<GalleryDetailPage />}
                errorElement={<CustomErrorBoundary />}
              />
              <Route
                exact
                path="/form-test"
                element={<FormTestPage />}
                errorElement={<CustomErrorBoundary />}
              ></Route>
            </Route>
          </Route>
        </Route>
        <Route
          path="*"
          element={<ErrorPage />}
          errorElement={<CustomErrorBoundary />}
        />
      </Route>,
    ),
  );
  return <RouterProvider router={router} />;
}

export default RouteConfig;
