import { Dropdown, Image, Space } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import DeleteModal from "../../components/Modals/DeleteModal";
import GenericModal from "../../components/Modals/GenericModal";
import ImageUploaderModal from "../../components/Modals/ImageGalleryUpload/ImageUploaderModal";
import FlashcardModalForm from "../../components/pages/FlashCardsPage/FlashcardModal/FlashcardModalForm";
import {
  EditIcon,
  FlashcardIconFilled,
  HorizontalDots,
  PlusICons,
  RecycleBin,
  TagFilledIcon,
} from "../../constants/svg";
import {
  CreateFlashcardQuestions,
  DeleteSingleFlashcard,
  GetFlashcardQuestions,
  GetSingleFlashcard,
} from "../../services/flashCards/flashCards";
import { UpdateFileUploadStorageSizeAvailability } from "../../services/global/global";
import { GetAuthUserLocalStorage } from "../../services/localStorage/localStorage";
import {
  setFlashCardAddEditForm,
  setFlashCardSelectedData,
  setReRenderFlashCardBuilder,
} from "../../store/slices/flashcardSlice";
import { salonUploadToS3, toastMessage } from "../../utils/helper";
import { roles as salonRoles } from "../../utils/constants";
import LoadingScreen from "../../components/LoadingScreen/LoadingScreen";
import { Assets } from "../../constants/images";
import { useLocation, useBlocker } from "react-router";
import { setBlockerModal, setNavigateTo } from "../../store/slices/globalSlice";
import { Image as ShimmerImage, Shimmer } from "react-shimmer";
import LeavePageModal from "../../components/Modals/LeavePageModal";

const FlashCardBuilder = (props) => {
  const defaultNewQuestion = {
    frontText: "",
    backText: "",
    frontImage: "",
    backImage: "",
  };

  const navigate = useNavigate();
  const { id } = useParams();
  const {
    reRenderFlashCardBuilder,
    flashCardAddEditForm,
    flashCardSelectedData,
  } = useSelector((state) => state.flashCard);

  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState({
    shareModal: null,
    editModal: null,
    deleteModal: null,
  });
  const [uploadingS3, setUploadingS3] = useState(false);
  const [showUploadImage, setShowUploadImage] = useState(null);
  const [disableFlashcardDeleteBtn, setDisableFlashcardDeleteBtn] =
    useState(false);
  const [
    selectedFrontBackImageUploadIndex,
    setSelectedFrontBackImageUploadIndex,
  ] = useState(null);
  const [btnPublishSetDisable, setBtnPublishSetDisable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showImageUploadModal, setShowImageUploadModel] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [leavePageModal, setLeavePageModal] = useState(false);
  const [nextNavigate, setNextNavigate] = useState(false);
  const [published, setPublished] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [imageUploaderDoneBtn, setImageUploaderDoneBtn] = useState(true);
  const { blockerModal } = useSelector((state) => state.global);
  const { location, state } = useLocation();

  useEffect(() => {
    dispatch(setBlockerModal(true));
    onInitGetFlashcardQuestion();
    getSingleFlashcardByID();

    return () => {
      dispatch(setBlockerModal(false));
    };
  }, []);

  const actionMenu = [
    {
      key: uuidv4(),
      label: (
        <>
          <div
            onClick={() => {
              dispatch(
                setFlashCardAddEditForm({
                  show: true,
                  formType: "edit",
                }),
              );
            }}
          >
            <EditIcon />
            Edit Details
          </div>
        </>
      ),
    },
    {
      key: uuidv4(),
      label: (
        <div
          className={"deleteLabel"}
          onClick={() => {
            setShowModal({ ...showModal, deleteModal: true });
          }}
        >
          <RecycleBin />
          Delete Survey
        </div>
      ),
    },
  ];

  const handleValidate = () => {
    let isValidate = true;

    for (let elem of questions) {
      if (elem.frontText == "") {
        isValidate = false;
        break;
      }

      if (elem.backText == "") {
        isValidate = false;
        break;
      }

      // if (!elem.frontImage) {
      //   isValidate = false;
      //   break;
      // }
      //
      // if (!elem.backImage) {
      //   isValidate = false;
      //   break;
      // }
    }

    if (questions?.length == 0) {
      isValidate = false;
    }

    return isValidate;
  };

  const handleSubmit = async () => {
    try {
      const payload = {
        flashcard_id: parseInt(id),
        flashcards: questions.map((elem) => {
          return {
            front_prompt: elem.frontText,
            front_image: elem.frontImage,
            back_prompt: elem.backText,
            back_image: elem.backImage,
          };
        }),
      };

      setBtnPublishSetDisable(true);
      const response = await CreateFlashcardQuestions(payload);
      const { data, message, status } = response.data;
      if (!status) {
        toastMessage("error", message);
        return;
      }
      dispatch(setReRenderFlashCardBuilder(reRenderFlashCardBuilder + 1));
      // setPublished(true);

      // dispatch(setNavigateTo("/flashcards"))
      dispatch(setBlockerModal(false));
      setTimeout(() => {
        navigate("/flashcards");
      }, 0);

      /*      setTimeout(() => {
            }, 0) */
      toastMessage("success", message);
    } catch (err) {
      toastMessage("error", err.message);
    } finally {
      setBtnPublishSetDisable(false);
    }
  };

  const addQuestion = () => {
    setQuestions([...questions, defaultNewQuestion]);
  };

  const handleDeleteQuestion = (i) => {
    const filteredQuestions = questions.filter((elem, index) => index != i);
    setQuestions(filteredQuestions);
  };

  const handleChangeFlashcardQuestion = async (i, t, v) => {
    setFlashCardQuestionInputValues(i, t, v);
  };

  const uploadQuestionImageOnS3 = async (file) => {
    try {
      setUploadingS3(true);
      let folder = `${
        GetAuthUserLocalStorage()?.salon?.directory_name || "uploads"
      }/flashcardBuilder`;
      let salonS3UploadResponse = await salonUploadToS3(file, folder);
      if (salonS3UploadResponse.status) {
        if (GetAuthUserLocalStorage()?.roles[0]?.id != salonRoles.super_admin) {
          await UpdateFileUploadStorageSizeAvailability(
            salonS3UploadResponse.data.size,
          );
        }
        return salonS3UploadResponse.data.location;
      } else {
        throw new Error(salonS3UploadResponse.message);
      }
    } catch (err) {
      toastMessage("error", err.message);
    } finally {
      setUploadingS3(false);
    }
  };

  const handleChangeLocalUpload = async (v) => {
    const { index: i, type: t } = selectedFrontBackImageUploadIndex;

    let image = "";
    if (v) {
      const fileURL = await uploadQuestionImageOnS3(v);
      image = fileURL;
    }
    setFlashCardQuestionInputValues(i, t, image);
    setImageUploaderDoneBtn(false);
  };

  const setFlashCardQuestionInputValues = (i, t, v) => {
    const updatedQuestion = questions.map((elem, index) => {
      if (i == index) {
        const copyElem = { ...elem };
        if (t == "backImage") {
          copyElem.backImage = v;
        } else if (t == "frontImage") {
          copyElem.frontImage = v;
        } else if (t == "frontText") {
          copyElem.frontText = v;
        } else if (t == "backText") {
          copyElem.backText = v;
        }
        return copyElem;
      } else {
        return elem;
      }
    });
    setQuestions(updatedQuestion);
  };

  const handleChangeGalleryUpload = (v) => {
    const { index: i, type: t } = selectedFrontBackImageUploadIndex;
    setFlashCardQuestionInputValues(i, t, v);
    setImageUploaderDoneBtn(false);
  };

  const handleChangeFolderUpload = (v) => {
    const { index: i, type: t } = selectedFrontBackImageUploadIndex;
    setFlashCardQuestionInputValues(i, t, v);
    setImageUploaderDoneBtn(false);
  };

  const handleOpenFrontBackImageUpload = (i, t) => {
    setSelectedFrontBackImageUploadIndex({
      index: i,
      type: t,
    });
    if (t == "frontImage") {
      setShowUploadImage(questions.find((elem, ind) => ind == i).frontImage);
    } else if (t == "backImage") {
      setShowUploadImage(questions.find((elem, ind) => ind == i).backImage);
    }

    setShowImageUploadModel(true);
  };

  const handleOpenFrontBackImageDelete = (i, t) => {
    let updatedQuestions;
    if (t == "frontImage") {
      updatedQuestions = questions.map((elem, index) => {
        // If you want to set 'back_image' to null for a specific index, you can add a condition here
        if (index == i) {
          return {
            ...elem,
            frontImage: "",
          };
        } else {
          return elem;
        }
      });
    } else if (t == "backImage") {
      updatedQuestions = questions.map((elem, index) => {
        // If you want to set 'back_image' to null for a specific index, you can add a condition here
        if (index == i) {
          return {
            ...elem,
            backImage: "",
          };
        } else {
          return elem;
        }
      });
    }
    setShowUploadImage(null);
    setQuestions(updatedQuestions);
  };

  const onHideImageUploadModa = () => {
    if (uploadingS3) {
      toastMessage("warning", "Please wait image uploading is in progress");
      return;
    }
    setSelectedFrontBackImageUploadIndex(null);
    setShowImageUploadModel(false);
    setShowUploadImage(null);
    setImageUploaderDoneBtn(false);
  };

  const handleClickBtnSubmit = () => {
    if (uploadingS3) {
      toastMessage("warning", "Please wait image uploading is in progress");
      return;
    }
    setSelectedFrontBackImageUploadIndex(null);
    setShowImageUploadModel(false);
    setShowUploadImage(null);
    setImageUploaderDoneBtn(false);
  };

  const handelImageUploaderDoneBtn = (val) => {
    setImageUploaderDoneBtn(val);
  };

  const getSingleFlashcardByID = async () => {
    const response = await GetSingleFlashcard(id);
    const { data, message, status } = response.data;
    if (!status) {
      toastMessage("error", message);
      return;
    }
    dispatch(setFlashCardSelectedData(data));
  };

  const onInitGetFlashcardQuestion = async () => {
    try {
      setLoading(true);
      const response = await GetFlashcardQuestions(id);
      const { data, message, status } = response.data;
      if (!status) {
        toastMessage("error", status);
        return;
      }
      if (data.length) {
        const flashcardQuestion = data.map((elem) => {
          return {
            frontText: elem.front_prompt,
            frontImage: elem.front_image,
            backText: elem.back_prompt,
            backImage: elem.back_image,
          };
        });
        setQuestions(flashcardQuestion);
      } else {
        setQuestions([defaultNewQuestion]);
      }
    } catch (err) {
      toastMessage("error", err.message);
    } finally {
      setLoading(false);
    }
  };

  const onDeleteFlashCard = async () => {
    try {
      setDisableFlashcardDeleteBtn(true);
      const resp = await DeleteSingleFlashcard(id);
      const { data, message, status } = resp.data;
      if (!status) {
        toastMessage("error", message);
        return;
      }
      setShowModal({ ...showModal, deleteModal: false });
      toastMessage("success", "Flashcard Deleted Successfully");
      navigate("/flashcards");
    } catch (e) {
      toastMessage("error", e.message);
    } finally {
      setDisableFlashcardDeleteBtn(false);
    }
  };

  /* unstable_useBlocker({
    message: "Are you sure?",
    when: ({ currentLocation, nextLocation }) =>
        leaveModal &&
        currentLocation.pathname != nextLocation.pathname,
  }); */

  if (loading) {
    return <LoadingScreen child={true} />;
  }

  return (
    <>
      <div className="innerContainer">
        <div className="main-flashcard-builder">
          <Row>
            <Col lg={3} style={{ paddingTop: "0px" }}>
              <div className="sidebar_Bg">
                <div className="surveysbuilder_image">
                  <ShimmerImage
                    src={
                      flashCardSelectedData?.image || Assets.GeneralPlaceholder
                    }
                    fallback={<Shimmer width={800} height={600} />}
                    alt="SurveysBuilder"
                    className="img-fluid"
                  />
                </div>
                <ul>
                  <li className="light-grey">
                    {flashCardSelectedData?.category?.name || "-"}
                  </li>
                  <li>
                    <Space size="middle">
                      <Dropdown
                        menu={{
                          items: actionMenu,
                        }}
                        placement="bottomRight"
                        trigger={["click"]}
                        overlayClassName={"defaultDropdown"}
                      >
                        <div className="icon cursor-pointer">
                          <HorizontalDots />
                        </div>
                      </Dropdown>
                    </Space>
                  </li>
                </ul>
                <div className="surveysbuilder_content">
                  <h3>{flashCardSelectedData?.name}</h3>
                  <p>{flashCardSelectedData?.description || "-"}</p>
                </div>
                {flashCardSelectedData?.tags?.length > 0 && (
                  <div className="tag-div">
                    <TagFilledIcon />
                    <p>
                      {flashCardSelectedData?.tags
                        ?.map((item) => item.name)
                        .join(", ")}
                    </p>
                  </div>
                )}

                {!handleValidate() && (
                  <Button
                    className="publish_survey"
                    type="submit"
                    disabled={!handleValidate()}
                  >
                    Publish Set
                  </Button>
                )}

                {handleValidate() && (
                  <Button
                    className="publish_survey"
                    type="submit"
                    onClick={handleSubmit}
                    disabled={btnPublishSetDisable}
                  >
                    {!btnPublishSetDisable && "Publish Set"}
                    {btnPublishSetDisable && <div className="load black" />}
                  </Button>
                )}
              </div>
            </Col>
            <Col lg={9}>
              <div className="headingbar_bg">
                <ul className="bar_count">
                  {questions.map((question, index) => (
                    <li key={index}>&nbsp;</li>
                  ))}
                </ul>
                <h2 className="sec-heading">Flashcard Builder</h2>
                <p>
                  Add questions one by one, then click Publish Set when
                  finished.
                </p>
                <p className="question_count">
                  Total Cards <span> ({questions.length})</span>
                </p>
              </div>
              <div className="add_question_box">
                {questions.map((elem, index) => (
                  <FlashCardFrontBackQuestion
                    index={index}
                    flashcardQuestion={elem}
                    handleDeleteQuestion={handleDeleteQuestion}
                    handleChangeFlashcardQuestion={
                      handleChangeFlashcardQuestion
                    }
                    handleOpenFrontBackImageUpload={
                      handleOpenFrontBackImageUpload
                    }
                  />
                ))}
                <div
                  className="add_question cursor-pointer"
                  onClick={addQuestion}
                >
                  <PlusICons />
                  Add Question
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <GenericModal
          show={showImageUploadModal}
          onHide={() => {
            onHideImageUploadModa();
          }}
          setModalShow={() => {
            onHideImageUploadModa();
          }}
          modalTitle={"Image Uploader"}
          size={"lg"}
          bodyComponent={
            <ImageUploaderModal
              handleChangeLocalUpload={handleChangeLocalUpload}
              handleChangeGalleryUpload={handleChangeGalleryUpload}
              handleChangeFolderUpload={handleChangeFolderUpload}
              handleClickBtnSubmit={handleClickBtnSubmit}
              handleOpenFrontBackImageDelete={handleOpenFrontBackImageDelete}
              selectedFrontBackImageUploadIndex={
                selectedFrontBackImageUploadIndex
              }
              showUploadImage={showUploadImage}
              imageUploaderDoneBtn={imageUploaderDoneBtn}
              handelImageUploaderDoneBtn={handelImageUploaderDoneBtn}
            />
          }
        />
      </div>

      <GenericModal
        show={flashCardAddEditForm.show}
        onHide={() => {
          dispatch(
            setFlashCardAddEditForm({
              show: false,
              formType: null,
            }),
          );
        }}
        setModalShow={(e) => {
          dispatch(
            setFlashCardAddEditForm({
              show: e,
              formType: null,
            }),
          );
        }}
        modalTitle={
          flashCardAddEditForm.formType == "edit"
            ? "Edit Flashcard Set"
            : "New Flashcard Set"
        }
        size={"lg"}
        bodyComponent={<FlashcardModalForm />}
      />

      <DeleteModal
        disable={disableFlashcardDeleteBtn}
        show={showModal.deleteModal}
        onHide={() => setShowModal({ ...showModal, deleteModal: false })}
        heading="Delete Flashcard"
        handleDelete={onDeleteFlashCard}
        para={
          <>
            Are you sure you want to delete this?
            <br />
            <b>Important:</b> This flashcard cannot be recovered.
          </>
        }
        notificationMessage="Flashcard set"
      />

      {/* <LeavePageModal
        show={leavePageModal}
        onHide={() => setLeavePageModal(false)}
        // setModalShow={checkAndNavigate}
        // navigateNext={checkAndNavigate}
        blocker={blocker}
        resetForm={props?.resetForm}
      /> */}
    </>
  );
};

const FlashCardFrontBackQuestion = ({
  index,
  handleDeleteQuestion,
  handleChangeFlashcardQuestion,
  flashcardQuestion,
  handleOpenFrontBackImageUpload,
}) => {
  const { frontText, backText, frontImage, backImage } = flashcardQuestion;
  return (
    <div className="headingbar_bg bg_extra_space d-block active">
      <div className="custom-pr">
        <div
          className="three-dots deleteRed"
          onClick={() => {
            handleDeleteQuestion(index);
          }}
        >
          Delete
        </div>
      </div>
      <div className="serial_number">
        <FlashcardIconFilled /> {index + 1}
      </div>
      <div className="question_box">
        <div className="flash_builder_input">
          <h3>Front:</h3>
          <Form.Control
            minLength={1}
            maxLength={200}
            type="text"
            placeholder="Enter term or prompt..."
            className="box-shadow-none"
            value={frontText}
            onChange={(e) =>
              handleChangeFlashcardQuestion(index, "frontText", e.target.value)
            }
          />
          <div
            className="testing"
            onClick={() => {
              handleOpenFrontBackImageUpload(index, "frontImage");
            }}
          >
            <div className="imageUploadBox">
              {frontImage && (
                <Image
                  src={frontImage}
                  width={"30px"}
                  height={"30px"}
                  style={{ borderRadius: "4px" }}
                  preview={false}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="extra_para">
        Front Card: Typically a prompt, like "Which tones compliment warm
        tones?"
      </div>
      <div className="question_box">
        <div className="flash_builder_input">
          <h3>Back:</h3>
          <Form.Control
            type="text"
            placeholder="Enter term or prompt..."
            className="box-shadow-none"
            minLength={1}
            maxLength={200}
            value={backText}
            onChange={(e) =>
              handleChangeFlashcardQuestion(index, "backText", e.target.value)
            }
          />
          <div
            className="testing"
            onClick={() => {
              handleOpenFrontBackImageUpload(index, "backImage");
            }}
          >
            <div className="imageUploadBox">
              {backImage && (
                <Image
                  src={backImage}
                  width={"30px"}
                  height={"30px"}
                  style={{ borderRadius: "4px" }}
                  preview={false}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="extra_para">
        Back Card: Typically the answer, like "Red, Orange, Yellow, Mahogany"
      </div>
    </div>
  );
};
export default FlashCardBuilder;
