import { notification } from "antd";
import { Button, Col, FloatingLabel, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import CustomDropdown from "../../../components/Common/CustomDropdown";
import DatepickerComponent from "../../../components/Common/Datepicker";
import TreeSelectComponent from "../../../components/Common/TreeSelect";
import { ToastAccentCheck, ToastCrossIcon } from "../../../constants/svg";
import { Location } from "./FlashcardPageData";

const FlashcardEditForm = (props) => {
  const defaultValues = {
    Title: "Default Title",
    Message: "Default Message",
    Link: "Default Alert Link",
    // Recipients: staffData[0].value,
    // "Start Date": "2023-15-02",
    // "End Date": "03-01-2023",
    // Location: Location[0].value,
  };

  const { register, handleSubmit, setValue, formState, reset } = useForm({
    defaultValues,
  });

  const onSubmit = (data) => {
    props.setOpen(false);

    notification.info({
      message: (
        <span>
          <strong>Flashcard set</strong> updated!
        </span>
      ),
      placement: "bottomRight",
      className: "updatedNotification",
      icon: <ToastAccentCheck />,
      closeIcon: <ToastCrossIcon />,
    });
  };

  // useEffect(() => {
  //   register("Recipients");
  //   register("Start Date");
  //   register("End Date");
  //   register("Location");
  // }, [register]);
  const updateFormValue = (fieldName, value) => {
    setValue(fieldName, value, { shouldDirty: true });
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <FloatingLabel
        controlId="EventName"
        label="Title"
        className="mb-20 position-relative"
      >
        <Form.Control
          type="text"
          placeholder="Title"
          autoComplete="off"
          role={"presentation"}
          {...register("Title")}
        />
      </FloatingLabel>

      <FloatingLabel controlId="Bio" label="Message" className="mb-20">
        <Form.Control
          as="textarea"
          placeholder="Leave a comment here"
          style={{ height: "150px" }}
          {...register("Message")}
        />
      </FloatingLabel>

      <FloatingLabel
        controlId="AlertLink"
        label="Alert Link"
        className="mb-20 position-relative"
      >
        <Form.Control
          type="text"
          placeholder="Alert Link"
          autoComplete="off"
          role={"presentation"}
          {...register("Link")}
        />
      </FloatingLabel>
      <div className="mb-20">
        <TreeSelectComponent defaultValue={true} labelName={"Assign Staff"} />
      </div>

      <DatepickerComponent
        defaultDate={"03-01-2023"}
        pickerName={"Start Date"}
        setData={updateFormValue}
        name="StartDate"
      />

      <DatepickerComponent
        defaultDate={"03-01-2023"}
        pickerName={"End Date"}
        setData={updateFormValue}
        name="EndDate"
      />

      <CustomDropdown
        placeholder={"Location"}
        menuOption={Location}
        show={null}
        defaultValue={Location[0].value}
        setData={updateFormValue}
        name="Location"
      />

      <div className="editButton">
        <Col lg={12}>
          <Button
            type="submit"
            className={"w-100"}
            disabled={!formState.isDirty}
          >
            Save Changes
          </Button>
        </Col>
      </div>
    </Form>
  );
};

export default FlashcardEditForm;
