import EmojiPicker from "emoji-picker-react";
import React, { useEffect, useRef, useState } from "react";
import { Col, FloatingLabel, Form, Row } from "react-bootstrap";
import { Controller } from "react-hook-form";
import { SmileIcon } from "../../../../../constants/svg";
import { maxBounds } from "../../../../../constants/contants";
import TagSlider from "../../../../Common/TagSlider";
import { Assets } from "../../../../../constants/images";
import CustomSelect from "./../../../../FormFields/CustomSelect";
import { setCreateUpdateModuleTitle } from "../../../../../store/slices/globalSlice";
import { useDispatch, useSelector } from "react-redux";

const FirstStep = ({
  title,
  register,
  errors,
  control,
  setValue,
  watch,
  getValues,
}) => {
  let dispatch = useDispatch();
  const selectedCategory = watch("category");
  const emojiPickerRef = useRef(null);
  const [showPicker, setShowPicker] = useState(false);
  const showPickerShowHide = useRef(false);
  const { categories } = useSelector((state) => state.global);

  const containerRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  const handleClickOutside = (event) => {
    if (
      emojiPickerRef.current &&
      !emojiPickerRef.current.contains(event.target) &&
      showPicker
    ) {
      setShowPicker(false);
    }
  };

  useEffect(() => {
    window.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [showPicker]);

  function handleEmojiClick(emojiData) {
    setValue("setName", getValues("setName") + emojiData.emoji);
  }

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  useEffect(() => {
    window.addEventListener("mouseup", handleMouseUp);
    return () => {
      window.removeEventListener("mouseup", handleMouseUp);
    };
  }, []);

  const handleMouseDown = (event) => {
    setIsDragging(true);
    setStartX(event.clientX - containerRef.current.offsetLeft);
    setScrollLeft(containerRef.current.scrollLeft);
  };
  const handleMouseMove = (event) => {
    if (!isDragging) return;
    const x = event.clientX - containerRef.current.offsetLeft;
    const walk = (x - startX) * 1.5; // Adjust the scroll speed as needed
    containerRef.current.scrollLeft = scrollLeft - walk;
  };

  return (
    <>
      <Form>
        <Row>
          <Col lg={8}>
            <FloatingLabel
              controlId="setName"
              label="Set Name"
              className="mb-20 custom-textarea"
            >
              <Form.Control
                type="text"
                placeholder="ex. Company Culture 101"
                className={errors.setName ? "hasError" : ""}
                minLength={1}
                maxLength={100}
                autoComplete="off"
                {...register("setName", {
                  onChange: (e) => {
                    dispatch(setCreateUpdateModuleTitle(e.target.value));
                  },
                  required: {
                    value: "Set name is required",
                  },
                  minLength: {
                    value: 1,
                  },
                  maxLength: {
                    value: 100,
                  },
                })}
              />
              <div ref={emojiPickerRef}>
                <div
                  onClick={() => {
                    setShowPicker(!showPicker);
                    showPickerShowHide.current = !showPicker;
                  }}
                  className={"smileyBox"}
                >
                  <img src={Assets.Smile} className={"smileImage"} />
                </div>
                {showPicker && <EmojiPicker onEmojiClick={handleEmojiClick} />}
              </div>
            </FloatingLabel>
          </Col>
          <Col lg={4}>
            <Controller
              control={control}
              name="category"
              defaultValue={""}
              rules={{
                required: "Category is required",
              }}
              render={({ field: { onChange, value } }) => (
                <FloatingLabel
                  controlId="category"
                  label={value == "" ? "Category" : ""}
                  className="mb-20"
                >
                  <CustomSelect
                    value={value}
                    label="Category"
                    options={categories.filter((elem) => elem.value != 0)}
                    className={value == "" ? "hasError" : ""}
                    onChange={onChange}
                    placeholder="Category"
                  />
                </FloatingLabel>
              )}
            />
          </Col>
          <Col lg={12} className={`mb-20 ${selectedCategory ? "" : "d-none"}`}>
            <Controller
              control={control}
              name="tags"
              rules={{
                required: "Tag are required",
                validate: (tags) => tags.length <= 2 || "Select up to 2 tags",
              }}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <TagSlider
                  value={value}
                  handleChange={onChange}
                  categoryId={selectedCategory}
                  title={title}
                />
              )}
            />
          </Col>
          <Col lg={12}>
            <FloatingLabel
              controlId="description"
              label="Description (Optional)"
              className="mb-20 custom-textarea"
            >
              <Form.Control
                as="textarea"
                placeholder="Improve your coloring knowledge with this flashcard set."
                style={{ height: "200px" }}
                className={`form-control ${errors.description ? "hasError" : ""}`}
                minLength={0}
                maxLength={999}
                {...register("description", {
                  required: {
                    value: false,
                  },
                  minLength: {
                    value: 0,
                  },
                  maxLength: {
                    value: 999,
                  },
                })}
              />
            </FloatingLabel>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default FirstStep;
